import { Injectable } from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import {concatMap, filter, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {
    AllCategoriesLoaded,
    AllCategoriesRequested,
    CategoriesActionTypes,
    CategoryLoaded,
    CategoryRequested,
} from './categories.actions';
import {selectAllCategoriesLoaded, selectCategoryById} from './categories.selectors';
import { CategoriesService } from './categories.service';
import {of} from "rxjs";

@Injectable()
export class CategoriesEffects {

    categoryRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<CategoryRequested>(CategoriesActionTypes.CategoryRequested),
            map(request => request.payload.categoryId),
            mergeMap(id => of(id)
                .pipe(
                    withLatestFrom(this.store.select(selectCategoryById(id))),
                    filter(([id, request]) => !request),
                    mergeMap(([id, request]) => this.categoriesService.getCategory(id)),
                    concatMap((response) => {
                        let actions = [];
                        actions.push(new CategoryLoaded({category: response}));
                        return actions;
                    })
                )
            )
        ));

    loadAllCategories$ = createEffect(() =>this.actions$
        .pipe(
            ofType<AllCategoriesRequested>(CategoriesActionTypes.AllCategoriesRequested),
            withLatestFrom(this.store.select(selectAllCategoriesLoaded)),
            filter(([action, allCatLoaded]) => !allCatLoaded),
            mergeMap(() => this.categoriesService.loadCategories()),
            map((categories) => new AllCategoriesLoaded({ categories })),
        ));

    constructor(private actions$: Actions, private categoriesService: CategoriesService,
                private store: Store<any>) {

    }
}
