
import {createEntityAdapter, EntityAdapter, EntityState, Update} from '@ngrx/entity';


export interface IAsyncRequestItem {
    request_id: string;
    id: string;
    result: any;
}

export function selectAsyncRequestItemsId(request: Partial<IAsyncRequestItem>): string {
    return request.request_id + '_' + request.id;
}

export const asyncRequestItemsAdapter: EntityAdapter<IAsyncRequestItem> =
    createEntityAdapter<IAsyncRequestItem>({
        selectId: selectAsyncRequestItemsId
    });

export interface AsyncRequestItemsState extends EntityState<IAsyncRequestItem> {}

export class AsyncRequestsItemsHelper {
    static updateItem = (item_id: string, result: any): Update<IAsyncRequestItem> => {
        return { id: item_id, changes: { result }};
    };

    static createItem = (request_id: string, item_id: string): IAsyncRequestItem => {
        return {
            request_id,
            id: item_id,
            result: null
        };
    }
}
