import { RouterHelper } from '@shared/helpers';

export enum HomeRoutesList {
    HOME_PAGE = '',
    ACCESSIBILITY_STATEMENT = 'accessibility_statement'
}

export class HomeRoutes extends RouterHelper {
    static routesClass = HomeRoutes;
    static MAIN_URL = '';
    static CMS_SITE = 'home';
    static routes = HomeRoutesList;
}
