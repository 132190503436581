import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

export interface IGeoElem {
    url: string;
    file: any;
}

export function selectGeoId(elem: Partial<IGeoElem>): string {
    return elem.url;
}

export const adapterGeo: EntityAdapter<IGeoElem> = createEntityAdapter<IGeoElem>({
    selectId: selectGeoId
});

