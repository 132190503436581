export enum treeElements {
    NODES = 'node',
    WEIGHTS = 'weight',
    CHILDREN = 'children'
}

export enum StatisticsType {
    Min = 'Min',
    Max = 'Max',
    Avg = 'Average',
    Median = 'Median',
    Mode = 'Mode',
    STD = 'Standard deviation',
    RANGE = 'Extremes',
    TOTAL = "Sum",
    NONE = 'No operation'
}

export enum SortTypes {
    ASC = 'Sorting Ascendant',
    DESC = 'Sorting Descendant',
    NONE = 'No sorting'
}

export enum SortCriteria {
    Performance = 'performance',
    Alphabetic = 'alphabetical',
    None = 'none'
}

export enum Direction {
    ASC = 1,
    DESC = -1
}

export enum TrendTypes {
    UP = 'up',
    DOWN = 'down',
    UPWARDS = 'upwards',
    DOWNWARDS = 'downwards',
    CONSTANT = 'constant',
    NA = 'not available',
}

export enum ElementOperation {
    ADD = 'add',
    REMOVE = 'remove',
    REPLACE = 'replace'
}

export enum EnvironmentType {
    EU = 'EU28',
    INT = 'International'
}

export enum RescaledModelDefault {
    min = 10,
    max = 100
}

export enum GroupCodes {
    POP = 'POP',
    POP2 = 'POP2',
    GDP = 'GDP',
    EMP = 'EMP',
    SEL = 'SEL', // Selection criteria
}

export enum SelectionCriteria {
    ECOC = 'ECOC',
    UNESCO = 'UNESCO',
    FESTIVAL = 'FESTIVAL',
}

export enum PopulationGroups {
    XXL = 'XXL',
    XL = 'XL',
    L = 'L',
    S_M = 'S-M',
}

export enum ComponentLevels {
    INDEX = 1,
    SUB_INDEX = 2,
    DIMENSION = 3,
    INDICATOR = 4,
}

export enum ScaleReferenceTypes {
    CITY = 'City',
    NUTS_3 = 'NUTS3',
    NONE = 'None'
}

export enum UnitTypes {
    COUNTRY = 'Country',
    URBAN_AUDIT = 'UrbanAudit',
    NUTS = 'Nuts',
}

export enum ItemTypesCOINAPI {
    INDICE = 'indice',
    UNIT = 'unit',
    CATEGORY = 'category',
    CATEGORIES = 'categories',
    CATEGORY_GROUP = 'category_group',
    DEVELOPER = 'developer',
    COUNTRY = 'country',
    CITY = 'city',
    REGION = 'region',
    SCOREBOARD = 'scoreboard',
    INDICES_AND_SCOREBOARDS = 'indices_and_scoreboards',
    INDICES_AND_SCOREBOARDS_BY_DEVELOPER = 'indices_and_scoreboards_by_developer',
}

export enum IApiLinkRels {
    SELF = 'self',
}

export enum APIStatisticsTypes {
    AVG = 'avg',
    AVGEU = 'avgeu',
    MEDIAN = 'median',
    MEDIANEU = 'medianeu',
    RANGE = 'range',
    RANGEEU = 'rangeeu',
    MAX = 'max',
    MAXEU = 'maxeu',
    MIN = 'min',
    MINEU = 'mineu'
}

export enum CategoryGroupIdsByName {
    COIN = 1,
    EC_PRIORITIES = 2,
    SDG = 3,
}

export enum ChartDownloadTypes {
    image = 'PNG',
    pdf = 'PDF',
    data_xlsx = 'XLSX',
    data_csv = 'Data in CSV',
    data_json = 'Data in JSON',
}

export enum LinkTypes {
    EXTERNAL,
    INTERNAL,
    CALLBACK_TRIGGER,
    GENERATE_PAGE_PDF
}

export enum LocalStorageKeys {
    userLang= 'userLang'
}
