/**
 * @deprecated this file will become deprecated, replaced by geo.structure.ts
 */

export enum GeoBaseUrls {
    local = 'assets/geo/',
    gisco = 'https://gisco-services.ec.europa.eu/distribution/v2/',
    api = 'http://demo.api.coin-dev.eu/api/v1/en/city-coords?',
}

export enum GeoLocalFiles {
    countries = 'countries.json',
    c3Cities = 'c3CityCoords.json',
    c3mCities = 'c3mCityCoords.json',
    c3mCitiesApi = 'index_code=c3m',
    geocountries = 'CNTR_RG_20M_2020_4326.json',
    geonuts0 = 'NUTS_RG_10M_2016_4326_LEVL_0.json',
    geonuts1 = 'NUTS_RG_10M_2016_4326_LEVL_1.json',
    geonuts2 = 'NUTS_RG_10M_2016_4326_LEVL_2.json',
    geonuts3 = 'NUTS_RG_10M_2016_4326_LEVL_3.json'
}

export enum GeoGiscoUnitTypes {
    countries = 'countries',
    nuts = 'nuts'
}

export enum GeoGiscoFileTypes {
    geojson = 'geojson',
    topojson = 'topojson',
    pbf = 'pbf',
    shapefile = 'shp',
    svg = 'svg'
}

export enum GeoGiscoScales {
    S1 = '01M',
    S3 = '03M',
    S10 = '10M',
    S20 = '20M',
    S60 = '60M'
}

export enum GeoGiscoProjections {
    epsg3035 = '3035',
    epsg3857 = '3857',
    epsg4326 = '4326'
}

export const GeoGiscoFileExtensions = new Map([
    [
        GeoGiscoFileTypes.topojson,
        'json'
    ],
    [
        GeoGiscoFileTypes.geojson,
        'json'
    ],
    [
        GeoGiscoFileTypes.pbf,
        'pbf'
    ],
    [
        GeoGiscoFileTypes.shapefile,
        'shp'
    ],
    [
        GeoGiscoFileTypes.svg,
        'svg'
    ]
]);

export const geoGisCountriesFiles = (fileType: GeoGiscoFileTypes, scale: GeoGiscoScales, version: string, projection: GeoGiscoProjections): string => {
    const extension = geoGisExtension(fileType);

    return `${GeoBaseUrls.gisco}countries/${fileType}/CNTR_RG_${scale}_${version}_${projection}.${extension}`;
};

export const geoGisExtension = (fileType: GeoGiscoFileTypes): string => {
    const zippedExtensions = [
        GeoGiscoFileTypes.shapefile,
        GeoGiscoFileTypes.svg
    ];

    return GeoGiscoFileExtensions.get(fileType) + (zippedExtensions.includes(fileType) ? '.zip' : '');
};

export interface IOuterRegion {
    region: string;
    country: string;
    nuts: string;
    limits: Rectangle
}

/**
 * Special regions are those who, due to the smaller scale, needs to be put on boxes on the map
 * Limits: [[top left corner], [bottom right corner]]
 */
export const OuterRegions: IOuterRegion[] = [
    {
        region: 'Madeira',
        country: 'PT',
        nuts: 'PT20',
        limits: [[-18, 33.5], [-14.41, 29.6]]
    },
    {
        region: 'Açores',
        country: 'PT',
        nuts: 'PT20',
        limits: [[-32, 40], [-24, 35]]
    },
    {
        region: 'Canarias',
        country: 'ES',
        nuts: 'ES70',
        limits: [[-19, 30], [-13.3, 27.4]]
    },
    {
        region: 'Guyane',
        country: 'FR',
        nuts: 'FRY3',
        limits: [[-55, 6.6], [-50, 1.5]]
    },
    {
        region: 'Martinique',
        country: 'FR',
        nuts: 'FRY2',
        limits: [[-61.5, 15.03], [-60.66, 14.26]]
    },
    {
        region: 'Guadeloupe & Saint-Martin',
        country: 'FR',
        nuts: 'FRY1',
        limits: [[-63.32, 18.19], [-60.87, 15.74]]
    },
    {
        region: 'Mayotte',
        country: 'FR',
        nuts: 'FRY5',
        limits: [[44.8, -12.5], [45.4, -13.1]]
    },
    {
        region: 'Réunion',
        country: 'FR',
        nuts: 'FRY4',
        limits: [[54.74, -20.6], [56.3, -21.65]]
    }/*,
    {
        region: 'Malta',
        country: 'MT',
        nuts: 'MT',
        limits: [[13.9, 36.2], [14.75, 35.6]]
    }*/
];

//export const CountriesWithOuterRegions = new Set(['ES', 'FR', 'MT', 'PT']);
export const CountriesWithOuterRegions = new Set(['ES', 'FR', 'PT']);

export type Coordinate = [number, number];

/**
 * Rectangle
 * Defined by the top left corner (first coordinate)
 * and the bottom right corner (second coordinate)
 */
export type Rectangle = [Coordinate, Coordinate];

export type Polygon = Coordinate[];
