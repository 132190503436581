import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { CmsPageRequested } from '@features/general/cms/state/cms.actions';
import { selectCmsPageIsLoaded } from '@features/general/cms/state/cms.selectors';
import { Observable, of } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HomeGuard implements CanActivate {
    private cmsPage = 'coin-home';
    constructor(private store: Store<AppState>) {}

    getFromStoreOrAPI(): Observable<any> {
        try {
            return this.store.pipe(
                select(selectCmsPageIsLoaded(this.cmsPage, [])),
                map((loaded: boolean) => {
                    if (!loaded) {
                        this.store.dispatch(new CmsPageRequested({ page: this.cmsPage, params: [] }));
                    }
                    return loaded;
                }),
                filter((loaded: any) => !!loaded),
                take(1)
            );
        } catch (e) {
            return of(false);
        }
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.getFromStoreOrAPI();
    }

}
