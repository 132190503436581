import { Action } from '@ngrx/store';
import { ILevel } from '@shared/interfaces';

export enum LevelsActionTypes {
    LevelsByUrlRequested = '[Level] Levels By Url Requested',
    LevelsLoaded = '[Level] Levels Loaded',
    LevelsByEditionRequested = '[Level] Levels By Edition Requested',
}

export class LevelsByUrlRequested implements Action {
    readonly type = LevelsActionTypes.LevelsByUrlRequested;

    constructor(public payload: { url: string }) {}
}

export class LevelsLoaded implements Action {
    readonly type = LevelsActionTypes.LevelsLoaded;

    constructor(public payload: {url?: string, levels: ILevel[] }) {}
}

export class LevelsByEditionRequested implements Action {
    readonly type = LevelsActionTypes.LevelsByEditionRequested;

    constructor(public payload: { indice: string, edition: number }) {}
}

export type LevelsActions =
    LevelsByUrlRequested
    | LevelsLoaded
    | LevelsByEditionRequested;
