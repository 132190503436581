import { Action } from '@ngrx/store';
import { CoinStatus } from './composite-indicators.reducer';

export enum CompositeIndicatorsActionTypes {
    CoinEditionAllDataRequested = '[COIN Home Page] All Data Requested for one edition',
    CoinIndiceRequested = '[COIN] Indice Requested',
    CoinEditionRequested = '[COIN] Edition Requested',
    CoinLastEditionRequested = '[COIN] Last Edition requested',
    CoinLastEditionUpdated = '[COIN] Last Edition updated',
    CountryRequested = '[COIN] Country Data Requested',
    DependenciesRequested = '[COIN] Dependencies Requested',
    CoinLastEditionLoaded = '[COIN] Coin LastEdition Loaded',
    CoinEditionLoaded = '[COIN] Coin Edition Loaded',
    CoinEditionStructureRequested = '[COIN] Structure requested for one edition',
    CoinEditionStructureByUrlRequested = '[COIN] Structure requested by url for one edition',
    CoinEditionDataLoading = '[COIN] All Data for an edition loading',
    CoinEditionAllDataLoaded = '[COIN] All Data is loaded for an edition',
    CoinCategoriesWResourcesRequested = '[COIN] All categories with resources requested',
    CoinMainStructureByUnitRequested = '[COIN] Request main structure for all the indices relating to one unit',
    CoinMainStructureRequested = '[COIN] Request main structure for all indices',
    CoinLastEditionMainStructureRequested = '[COIN] Request main structure for the last edition of an index',
    CoinLastEditionMainDataRequested = '[COIN] Request main data for the last edition of an index',
    CoinLastEditionUnitsRequested = '[COIN] Request all units for the last edition of an index',
    CoinStatusReset = '[COIN] Reset status',
}

export class CoinCategoriesWResourcesRequested implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinCategoriesWResourcesRequested;
}

export class CoinEditionDataLoading implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinEditionDataLoading;
    constructor(public payload: { indice: string, edition: number, url: string }) {}
}

export class CoinEditionAllDataLoaded implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinEditionAllDataLoaded;
    constructor(public payload: { indice: string, edition: number }) {}
}

export class CoinEditionStructureByUrlRequested implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinEditionStructureByUrlRequested;
    constructor(public payload: { url: string }) {}
}

export class CoinMainStructureByUnitRequested implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinMainStructureByUnitRequested;
    constructor(public payload: { code: string }) {}
}

export class CoinEditionStructureRequested implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinEditionStructureRequested;
    constructor(public payload: { indice: string, edition: number, type?: string }) {}
}

export class CoinEditionLoaded implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinEditionLoaded;
    constructor(public payload: { indice: string, edition: number }) {}
}

export class CoinLastEditionLoaded implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinLastEditionLoaded;
    constructor(public payload: { indice: string, edition: number }) {}
}

export class CoinLastEditionUpdated implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinLastEditionUpdated;
    constructor(public payload: { indice: string, edition: number, allData?: CoinStatus, dataYear?: number }) {}
}

export class CoinLastEditionRequested implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinLastEditionRequested;
    constructor(public payload: { indice: string, allData?: CoinStatus, dataYear?: number}) {}
}

export class CoinEditionRequested implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinEditionRequested;
    constructor(public payload: { indice: string, edition: number, isLastEdition?: boolean, allData?: CoinStatus, dataYear?: number }) {}
}

export class CoinMainStructureRequested implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinMainStructureRequested;
}

/**
 * CoinAllDataRequested
 * Action - Used to request all the data for a specified edition
 */
export class CoinEditionAllDataRequested implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinEditionAllDataRequested;

    constructor(public payload: { indice: string, edition: number, dataYear?: number }) {}

}

export class CountryRequested implements Action {
    readonly type = CompositeIndicatorsActionTypes.CountryRequested;

    constructor(public payload: { indiceCode: string, countryCode: string }) {}
}

export class CoinLastEditionMainStructureRequested implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinLastEditionMainStructureRequested;

    constructor(public payload: { code: string }) {}
}

export class CoinLastEditionMainDataRequested implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinLastEditionMainDataRequested;

    constructor(public payload: { code: string }) {}
}

export class CoinLastEditionUnitsRequested implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinLastEditionUnitsRequested;

    constructor(public payload: { code: string }) {}
}

export class CoinStatusReset implements Action {
    readonly type = CompositeIndicatorsActionTypes.CoinStatusReset;

    constructor(public payload: { data: any }) {}
}

export type CompositeIndicatorsActions =
    CoinEditionAllDataRequested
    | CountryRequested
    | CoinEditionRequested
    | CoinEditionLoaded
    | CoinLastEditionRequested
    | CoinLastEditionLoaded
    | CoinLastEditionUpdated
    | CoinEditionStructureRequested
    | CoinEditionStructureByUrlRequested
    | CoinEditionAllDataLoaded
    | CoinEditionDataLoading
    | CoinCategoriesWResourcesRequested
    | CoinMainStructureByUnitRequested
    | CoinMainStructureRequested
    | CoinLastEditionMainStructureRequested
    | CoinLastEditionMainDataRequested
    | CoinLastEditionUnitsRequested
    | CoinStatusReset;
