export class ObjectHelper {
    static selectProperties = (elem: { [_: string]: any }, properties_to_select: string[]): { [_: string]: any } => {
        const answer = {};
        for (let property in elem) {
            if (elem.hasOwnProperty(property) && properties_to_select.includes(property)){
                    answer[property] = elem[property];
            }
        }

        return answer;
    };

    static excludeProperties = (elem: { [_: string]: any }, properties_to_exclude: string[]): { [_: string]: any } => {
        const answer = {};
        for (let property in elem) {
            if (elem.hasOwnProperty(property) && !properties_to_exclude.includes(property)){
                answer[property] = elem[property];
            }
        }

        return answer;
    };

    static replaceObjectValuesWithOtherObject(obj, otherObject) {
        for (let prop in otherObject) {
            if (!otherObject.hasOwnProperty(prop)) {
                continue;
            }
            obj[prop] = otherObject[prop];
        }
        return obj;
    }

    static objectFlip(obj) {
        const ret = {};
        Object.keys(obj).forEach(key => {
            ret[obj[key]] = key;
        });
        return ret;
    }

    static getPropByValue(obj, value) {
        return ObjectHelper.objectFlip(obj)[value];
    }

    /**
     * Takes a base object and updates it with new partial data, while keeping all the properties of the base
     * Obs: It doesn't takes into account the case where a property is an array of Objects
     * @param base
     * @param update
     * @constructor
     */
    static UpdateObjectWithPartialValues = <T>(base: T, update: Partial<T>): T => {
        base = base || {} as T;
        const updated: T = { ...base, ...(update || {}) };

        Object.keys(base).forEach((key) => {
            if (base[key] && update[key] && (base[key] instanceof Object) && !Array.isArray(base[key])) {
                updated[key] = ObjectHelper.UpdateObjectWithPartialValues(base[key], update[key]);
            }
        });

        return updated;
    }

}
