import { Update } from '@ngrx/entity/src/models';
import { Action } from '@ngrx/store';
import { ICategoryGroup } from '@shared/interfaces';

export enum CategoryGroupsActionTypes {
    CategoryGroupRequested = '[Category Group] Category group requested',
    CategoryGroupLoaded = '[Category Group API] Category group loaded',
    AllCategoryGroupsRequested = '[Category Group] All Category groups requested',
    AllCategoryGroupsLoaded = '[Category Group API] All Category groups loaded',
    CategoryGroupUpdate = '[Category Group API] Category group Update',
}

export class CategoryGroupRequested implements Action {
    readonly type = CategoryGroupsActionTypes.CategoryGroupRequested;

    constructor(public payload: { id: number}) {}
}

export class CategoryGroupLoaded implements Action {
    readonly type = CategoryGroupsActionTypes.CategoryGroupLoaded;

    constructor(public payload: { group: ICategoryGroup }) {}
}

export class AllCategoryGroupsRequested implements Action {
    readonly type = CategoryGroupsActionTypes.AllCategoryGroupsRequested;
}

export class AllCategoryGroupsLoaded implements Action {
    readonly type = CategoryGroupsActionTypes.AllCategoryGroupsLoaded;

    constructor(public payload: { groups: ICategoryGroup[]}) {}
}

export class CategoryGroupUpdate implements Action {
    readonly type = CategoryGroupsActionTypes.CategoryGroupUpdate;

    constructor(public payload: { group: Update<ICategoryGroup> }) {}
}

export type CategoryGroupsActions =
    CategoryGroupRequested
    | CategoryGroupLoaded
    | AllCategoryGroupsRequested
    | AllCategoryGroupsLoaded
    | CategoryGroupUpdate;
