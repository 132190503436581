import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import {
    AllEditionsRequested,
    EditionByUrlRequested,
    EditionRequested,
    EditionsActionTypes,
    EditionsLoaded, LastEditionRequested,
} from './editions.actions';

import {
    selectEdition,
} from './editions.selectors';
import { EditionsService } from './editions.service';

@Injectable()
export class EditionsEffects {
    loadEditionsByUrl$ = createEffect(() =>this.actions$
        .pipe(
            ofType<EditionByUrlRequested>(EditionsActionTypes.EditionByUrlRequested),
            map((request) => request.payload.url),
            mergeMap((url) => this.editionsService.getEditionByUrl(url)),
            map( (editions) => new EditionsLoaded( { editions })),
        ));

    editionRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<EditionRequested>(EditionsActionTypes.EditionRequested),
            map((request) => request.payload),
            mergeMap((request) => of(request)
                .pipe(
                    withLatestFrom(this.store.select(selectEdition(request.indice, request.edition))),
                    filter(([action, edition]) => !edition),
                    map(() => request),
                    mergeMap((edition) => this.editionsService.getEditionByCode(edition.indice, edition.edition)),
                    map((editions) => new EditionsLoaded({ editions })),
                )),
        ));

    constructor(private actions$: Actions, private editionsService: EditionsService,
                private store: Store<any>) {}
}
