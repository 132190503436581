import { Injectable } from '@angular/core';
import { IComponent } from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';

export enum ComponentsRequestsTypes {
    lastEdition = 'last-edition',
    edition = 'edition'
}

export const ComponentsUrls = new Map([
    [
        ComponentsRequestsTypes.lastEdition,
        (params) => `indices/${params.indice}/last-edition/components`
    ],
    [
        ComponentsRequestsTypes.edition,
        (params) => `${params.indice}/${params.edition}/data`
    ]
]);

@Injectable()
export class ComponentsService extends SharedService {

    getComponentsByUrl(url: string): Observable<IComponent[]> {
        return this.getByUrl<IComponent[]>(url);
    }

    getComponentsByEdition(indice: string, edition: number): Observable<IComponent[]> {
        const url = this.componentsUrl(indice, edition);
        return this.getComponentsByUrl(url);
    }

    private componentsUrl(indice: string, edition: number): string {
        return this.getApiUrl() + ComponentsUrls.get(ComponentsRequestsTypes.edition)({indice, edition});
    }

    componentsUrlByType(type: ComponentsRequestsTypes, params: {[param: string]: any}): string {
        return this.getApiUrl() + ComponentsUrls.get(type)(params);
    }
}
