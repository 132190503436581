import { Action } from '@ngrx/store';
import { IScale } from '@shared/interfaces';

export enum ScalesActionTypes {
    ScalesByUrlRequested = '[Scales] Scales By Url Requested',
    ScalesLoaded = '[Scales] Scales Loaded',
    ScalesByEditionRequested = '[Scales] Scales By Edition Requested',
}

export class ScalesByUrlRequested implements Action {
    readonly type = ScalesActionTypes.ScalesByUrlRequested;

    constructor(public payload: { url: string }) {}
}

export class ScalesLoaded implements Action {
    readonly type = ScalesActionTypes.ScalesLoaded;

    constructor(public payload: {url?: string, scales: IScale[] }) {}
}

export class ScalesByEditionRequested implements Action {
    readonly type = ScalesActionTypes.ScalesByEditionRequested;

    constructor(public payload: { indice: string, edition: number }) {}
}

export type ScalesActions =
    ScalesByUrlRequested
    | ScalesLoaded
    | ScalesByEditionRequested;
