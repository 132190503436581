import { Injectable } from '@angular/core';
import {Actions, createEffect} from '@ngrx/effects';
import { Effect } from 'signal';
import { Store } from '@ngrx/store';
import { ResourcesService } from './resources.service';

@Injectable()
export class ResourcesEffects {
    /*
    @createEffect(() =>)
    loadResourceForMultiple$ = this.actions$.ofType<ResourcesRequestedForMultipleElements>(ResourcesActionTypes.ResourcesRequestedForMultipleElements)
        .pipe(
            map(action => action.payload),
            mergeMap(ids => ids),
            mergeMap(id => this.resourcesService.getResourcesByUrl(id.url)),
//            map(res => { console.log(res); return res; }),
            map(resources => new ResourcesLoaded({ resources }))
            // if we want to only emit one time, after all requests finish
            // mergeMap(ids => forkJoin(ids.map(id => this.resourcesService.getResourcesByUrl(id.url)))),
            // map(res => new ResourcesLoaded({ resources: res.reduce((arr, elem) => { arr.push(...elem); return arr; }, []) }));
        );

    @createEffect(() =>)
    loadResourceForOne$ = this.actions$.ofType<ResourcesRequestedForOneElement>(ResourcesActionTypes.ResourcesRequestedForOneElement)
        .pipe(
            map(action => action.payload),
            mergeMap(request => of(request).pipe(
                withLatestFrom(this.store.select(selectResourcesById(request.resourceId, request.resourceType))),
                filter(([q, res]) => res.length === 0),
                mergeMap(() => this.resourcesService.getResourcesByUrl(request.url)),
                map(res => new ResourcesLoaded({ resources: res }))
            ))
        );
*/
    constructor(private actions$: Actions, private resourcesService: ResourcesService,
                private store: Store<any>) {

    }

}
