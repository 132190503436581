import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IDataType } from '@shared/interfaces';
import { DataTypesActions, DataTypesActionTypes } from './data-types.actions';

export interface DataTypesState extends EntityState<IDataType> {
    allTypesLoaded: boolean;
}

export const adapter: EntityAdapter<IDataType> =
    createEntityAdapter<IDataType>({});

export const initialDataTypesState: DataTypesState = adapter.getInitialState({
    allTypesLoaded: false,
});

export function dataTypesReducer(state = initialDataTypesState , action: DataTypesActions): DataTypesState {

    switch (action.type) {
    case DataTypesActionTypes.DataTypesLoaded:
        // adapter.removeAll(state);
        return adapter.setAll(action.payload.dataTypes, { ...state, allTypesLoaded: true });
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
