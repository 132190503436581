import { Injectable } from '@angular/core';
import {IDeveloper} from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';

@Injectable()
export class DevelopersService extends SharedService {
    private url = this.getApiUrl() + 'entities';

    loadDevelopers(): Observable<IDeveloper[]> {
        return this.getByUrl<IDeveloper[]>(this.url);
    }

    getDeveloper(id: number): Observable<IDeveloper> {
        return this.getByUrl<IDeveloper>(this.url + '/' + id);
    }

}
