import { IClassRank, IRank, IRankYear, ISeriesElem, IUnitPosition } from '@shared/interfaces/chart.interfaces';
import { SortTypes, StatisticsType } from '@shared/enums/app.enums';
import { ComponentTree } from '@shared/models/component-tree.model';
import { IComponent, IData, IDualNode, IGroupClass, IParam, IUnit, IUnitGroup, IWeight } from '../interfaces';
import { Statistics } from '@shared/helpers/statistics.class';


export const chunkArray = (array: any[], size: number): any[] => {
    const chunkedArray = []
    for (let i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
}

/**
 * Convert data into a series element
 * @param data: IData[]
 * @param component: IComponent
 * @return ISeriesElem
 */
export const convertDataComponentAsSeries = (data: IData, component: IComponent, valueAttr= 'value'): ISeriesElem => {
    return {
        id: component.id,
        name: component.name,
        value: data ? data[valueAttr] : null,
        order: component.order,
    };
};

/**
 * Convert an array of data into a series element array
 * @param data: IData[]
 * @param components: IComponent[]
 * @return ISeriesElem[]
 */
export const convertDataComponentsAsSeries = (data: IData[], components: IComponent[], valueAttr = 'value'): ISeriesElem[] => {
    return components.map((component) => {
        const param = createParam('variable_id', [component.variable_id]);
        const dataElem = filterDataByParam(data, param)[0];
        return convertDataComponentAsSeries(dataElem, component, valueAttr);
    });
};

/**
 *  Filter an array by a specified parameter
 * @param data: T[] - array to filter
 * @param param: IParam
 * @return T[]
 */
export const filterDataByParam = <T>(data: T[], param: IParam): T[] => {

    return data.filter((d) => param.ids.includes(d[param.attr]));
};

/**
 * Filter an array by multiple parameters
 * @type T: type of the input and return data
 * @param data: T[] - array to filter
 * @param params: IParam[]
 * @return T[]
 */
export const filterDataByMultipleParams = <T>(data: T[], params: IParam[]): T[] => {
    return params.reduce((fdata, param) => filterDataByParam<T>(fdata, param), data);
};

/**
 * Filter an array by multiple attributes
 * @type {T}: type of the input and return data
 * @param {T[]} data Array to filter
 * @param {string[]} attrs Array with all the parameters to filter
 * @param {Array<Array<string|number>|string|number>} ids An array containing the list of values to filter
 *          the values should be in the same order as the attrs
 * @return T[] Filtered array
 *
 * @example filterArrayByMultipleAttrs<IData>(data, ['variable_id', 'unit_id'], [[23, 25], 31])
 */
export const filterArrayByMultipleAttrs = <T>(data: T[], attrs: string[], ids: Array<Array<string|number>|string|number>): T[] => {
    try {
        if (!data) { return []; }
        const params = createParams(attrs, ids);
        return filterDataByMultipleParams<T>(data, params);
    } catch (e) {
        return [];
    }
};

/**
 * Sort an array of data by a specified direction and sorting direction
 * @param {IData[]} data The array of elements to filter
 * @param {number} direction The natural direction of the data
 *                            1 - bigger is better
 *                            -1 - smaller is better
 * @param {SortType} sortType The order of the sorting
 *                           - SortType.ASC - Ascendant order
 *                           - SortType.DESC - Descendant order
 *                           - Default: ASC
 * @param {string} valueAttr The attribute to sort
 *                           - Default: 'value'
 * @returns {IData[]} sorted array of data
 *
 */
export const sortDataByValue = (data: IData[], direction = 1, sortType: SortTypes = SortTypes.ASC, valueAttr = 'value'): IData[] => {
    const flag = sortType === SortTypes.ASC ? direction : -direction;
    return data.sort((a, b) => a[valueAttr] < b[valueAttr] ? -flag : flag);
};

export const sortArrayByAttr = <T>(array: T[], attr: string, sortType: SortTypes = SortTypes.ASC, direction = 1): T[] => {
    const compare = sortType === SortTypes.ASC ? direction : -direction;
    return array.sort((a, b) => a[attr] < b[attr] ? compare : -compare);
};

export const getPositionFromData = (data: IData[], unitId: number, direction = 1, valueAttr = 'value'): number => {
    if (!unitId || !data.length) { return null; }

    const initial = {
        currentPos: 0,
        unitPos: null,
        previous: null,
    };
    const sortedData = sortDataByValue(data, direction, SortTypes.DESC, valueAttr);

    const res = sortedData.reduce((current, elem) => {
        if (elem[valueAttr] !== current.previous) {
            current.currentPos++;
            current.previous = elem[valueAttr];
        }
        if (elem.unit_id === unitId) {
            current.unitPos = current.currentPos;
        }
        return current;
    } , initial);
    return res.unitPos;
};

/**
 * @deprecated replaced by the same method in the ArrayHelper class
 * @param ordinal
 */
export const getOrdinalSufix = (ordinal: number): string => {
    if (!ordinal) { return null; }
    const comparator = (ordinal > 20) ? (ordinal % 10) : ordinal;
    switch (comparator) {
    case 1:
        return 'st';
    case 2:
        return 'nd';
    case 3:
        return 'rd';
    default:
        return 'th';
    }
};

export const createUnitPositionsFromData = (data: IData[], components: IComponent[], unit: IUnit, valueAttr = 'value'): IUnitPosition[] => {
    if (!unit) { return []; }
    return components.map((comp) => {
        const variableParam = createParam('variable_id', comp.variable_id);
        const unitParam = createParam('unit_id', unit.id);
        const filteredData = filterDataByParam<IData>(data, variableParam);
        const unitData = filterDataByParam<IData>(filteredData, unitParam)[0];
        const position = getPositionFromData(filteredData, unit.id, comp.direction, valueAttr);
        return {
            code: unit.code,
            name: unit.name_en,
            position,
            reference: comp.name,
            score: unitData ? unitData[valueAttr] : null,
            sufix: getOrdinalSufix(position),
        };
    });
};

export const getUniqueValuesFromArray = <T, U>(data: T[], attr: string): U[] => {
    const unique = data.reduce((keys, elem) => {
        keys[elem[attr]] = elem[attr] as U;
        return keys;
    }, []);

    return Object.values(unique);
};

export const getSortedAttrFromArray = <T, U>(data: T[], attr: string, sortDirection: SortTypes = SortTypes.ASC): U[] => {
    const direction = (sortDirection === SortTypes.ASC ? 1 : -1);

    return data.map((d) => d[attr]).sort((a, b) => a < b ? -direction : direction);
};

export const getUniqueSortedAttrFromArray = <T, U>(data: T[], attr: string, sortDirection: SortTypes = SortTypes.ASC): U[] => {
    const direction = (sortDirection === SortTypes.ASC ? 1 : -1);
    const values = getUniqueValuesFromArray<T, U>(data, attr);

    return values.sort((a, b) => a < b ? -direction : direction);
};

export const getSortedExtremesByAttr = <T, U>(data: T[], attr: string, sortDirection: SortTypes = SortTypes.ASC): [U, U] => {
    try {
        const sortedData = getSortedAttrFromArray<T, U>(data, attr, sortDirection);
        return [sortedData[0], sortedData.pop()];
    } catch (e) {
        return undefined;
    }
};

export const getAttrWithNullsFromArray = <T, U>(data: T[], baseData: any[], baseAttr: string, valueAttr: string) => {
    return baseData.map((d) => {
        const param = createParam(baseAttr, d);
        const value = filterDataByParam(data, param)[0];
        return value ? value[valueAttr] : null;
    });
};

export const createRankElem = (position: number, total: number): IRank => {
    const sufix = getOrdinalSufix(position);
    if (!position) {
        return {
            position: null,
            sufix: null,
            total: null,
        };
    } else {
        return {
            position,
            sufix,
            total,
        };
    }
};

export const createRankYearElem = (position: number, total: number, year: number, score: number, active = false): IRankYear => {
    return { ...createRankElem(position, total), year, active, score };
};

export const getRanksByYears = (data: IData[], years: number[], unitId: number, activeYear: number): IRankYear[] => {
    const unitParam = createParam('unit_id', unitId);
    const unitData = filterDataByParam(data, unitParam);
    return years.map((year) => {
        const yearParam = createParam('year', year);
        const score = filterDataByParam(unitData, yearParam)[0];
        if (!score) {
            return null;
        }
        const rankData = filterDataByParam(data, yearParam);
        const position = getPositionFromData(rankData, unitId);
        return createRankYearElem(position, rankData.length, year, score.value, activeYear === year);
    });
};

export const groupDataByAttr = (data: IData[], attr: string) => {
    try {
        const groupedData = data.reduce((res, elem) => {
            res[elem[attr]] ? res[elem[attr]].push(elem) : res[elem[attr]] = [elem];
            return res;
        }, []);
        return groupedData.filter((d) => d).map((d) => ({
            id: d[0][attr],
            [attr]: d[0][attr],
            value: d.reduce((sum, elem) => sum + elem.value, 0) / d.length,
        }));
    } catch (err) {
        return [];
    }
};

export const createParam = (attr: string, ids: Array<string | number> | (string | number)): IParam => {
    const arr = ids instanceof Array ? [...ids] : [ids];
    return {
        attr,
        ids: arr,
    };
};

export const createParams = (attrs: string[], ids: Array<Array<string | number> | (string | number)>) => {
    return attrs.map((attr, i) => createParam(attr, ids[i]));
};

export const combineWithGaps = <T, B>(data: T[], base: B[], dataAttr: string, baseAttr: string): Array<{base: B, data: T}> => {
    return base.map((b) => {
        const fdata = data.find((d) => d[dataAttr] === b[baseAttr]);
        return {
            base: b,
            data: fdata ? fdata : null,
        };
    });
};

export const elemSorter = <T>(a: T, b: T, attr: string, direction = 1, sortDirection: SortTypes = SortTypes.ASC): number => {
    const sdirection = (sortDirection === SortTypes.ASC ? direction : -direction);
    let comparison;
    if ((!a || a[attr] == null) && (!b || b[attr] == null)) {
        comparison = 0;
    } else if (!a || a[attr] == null) {
        comparison = -1;
    } else if (!b || b[attr] == null) {
        comparison = 1;
    } else {
        comparison = a[attr] < b[attr] ? -sdirection : sdirection;
    }
    return comparison;
};

export const getIndexByValue = (values: number[], search: number): number => {
    return values.indexOf(search);
};

/**
 * Search a value in a reference array and returns the related value on a second array
 * @param values - the array with the value to return
 * @param reference - the array with the search element
 * @param search
 * @returns number
 */
export const getArrayValueByReference = (values: number[], reference: number[], search: number): number => {
    const idx = getIndexByValue(reference, search);
    return idx < 0 ? null : values[idx];
};

export const categoriseArray = <T>(data: T[], categoryAttr: string) => {
    const categories = Array.from(new Set(data.map((d) => d[categoryAttr])));
    return categories.map((id) => ({
        id,
        data: filterArrayByMultipleAttrs(data, [categoryAttr], [id]),
    }));
};

export const categoriseArrayWNulls = <T>(data: T[], categoryAttr: string, base: any[], baseAttr: string, valueAttr: string) => {
    const categories = Array.from(new Set<number>(data.map((d) => d[categoryAttr])));
    return categories.map((id) => {
        const fdata = filterArrayByMultipleAttrs(data, [categoryAttr], [id]);
        return {
            id,
            data: getAttrWithNullsFromArray<T, number>(fdata, base, baseAttr, valueAttr),
        };
    });
};

export const getUniqueArray = <T>(data: T[], attr: string) => {
    return Array.from(new Set(data.map((d: T) => d[attr])));
};

export const getUnitN = (data: IData[], direction = 1, size: number, unitId: number): IData[] => {
    const sorted = sortDataByValue(data.filter((d) => d.value), direction, SortTypes.DESC);
    const n = sorted.length;

    if (n < size || !size) { return sorted; }

    const lower_range = Math.floor(0.5 * size);
    const upper_range = Math.round(0.5 * size);

    const city_index = sorted.map((d) => d.unit_id).indexOf(unitId);

    let min_idx, max_idx;
    if (city_index - lower_range <= 0) {
        min_idx = 0;
        max_idx = size;
    } else if (city_index + upper_range >= n) {
        min_idx = n - size;
        max_idx = n;
    } else {
        min_idx = city_index - lower_range;
        max_idx = city_index + upper_range;
    }

    return sorted.slice(min_idx, max_idx);
};

export const getTopN = (data: IData[], direction = 1, size: number): IData[] => {
    const sorted = sortDataByValue(data.filter((d) => d.value), direction, SortTypes.DESC);
    if (sorted.length < size || !size) { return sorted; }
    const refValue = sorted[size - 1].value;
    return sorted.filter((d) => d.value >= refValue);
};

export const createPositionsArray = (data: IData[], direction: number): number[] => {
    if (!data.length) { return []; }
    const sorted = sortDataByValue(data, direction, SortTypes.DESC);
    let currentPosition = 1;
    let currentValue = sorted[0].value;
    return sorted.map((d) => {
        currentPosition += d.value === currentValue ? 0 : 1;
        currentValue = d.value;
        return currentPosition;
    });
};

export const compose = (...fns) => (x) => fns.reduce((y, f) => f(y), x);

export const sortByAttr = <T>(array: T[], attr: string, order: SortTypes): T[] => {
    const compare = order === SortTypes.ASC ? -1 : 1;
    return array.sort((a, b) => a[attr] < b[attr] ? -compare : compare);
};

/**
 * @deprecated use the updated version from ArrayHelper.MultipleSorter
 * @param a
 * @param b
 * @param attrs
 * @param orders
 */
const multipleSorter = <T>(a: T, b: T, attrs: string[], orders: SortTypes[]): number => {
    let attr: string;
    let order: SortTypes;
    let compare;

    if (!attrs.length) {
        return 0;
    } else {
        attr = attrs.shift();
        order = orders.shift();
        compare = order === SortTypes.ASC ? 1 : -1;
        if (a[attr] === b[attr]) {
            return multipleSorter<T>(a, b, [...attrs], [...orders]);
        } else {
            return a[attr] < b[attr] ? -compare : compare;
        }
    }
};

/**
 * @deprecated use the updated version from ArrayHelper.SortByMultipleAttrs
 * @param array
 * @param attrs
 * @param orders
 */
export const sortByMultipleAttrs = <T>(array: T[], attrs: string[], orders: SortTypes[]): T[] => {
    return array.sort((a, b) => multipleSorter<T>(a, b, [...attrs], [...orders]));
};

export const getTopRank = (data: IData[], size: number, component: IComponent, units: IUnit[], unitId: number = null): IUnitPosition[] => {
    if (!data.length || !component || !units.length) { return []; }
//    const topData = getTopN(data, component.direction, size);

    let unit_ids;
    let filtered_data;

    const positionsData = getTopN(data, component.direction, size);

    const positions = createPositionsArray(positionsData, component.direction);

    const unitPositions = positionsData.map((d, i) => {
        const unit = units.find((u) => u.id === d.unit_id);
        return {
            unit_id: d.unit_id,
            position: {
                code: unit.code,
                name: unit.name_en,
                position: positions[i],
                reference: component.name,
                score: d.value,
                sufix: getOrdinalSufix(positions[i]),
                active: !!unitId && (unit.id === unitId),
            },
        };
    });

    if (!unitId) {
        unit_ids = getTopN(data, component.direction, size)
            .map((d) => d.unit_id);
    } else {
        unit_ids = getUnitN(data, component.direction, size, unitId)
            .map((d) => d.unit_id);
    }

    filtered_data = unitPositions.filter((up) => unit_ids.includes(up.unit_id))
        .map((up) => up.position);

    return sortByMultipleAttrs<IUnitPosition>(filtered_data, ['position', 'name'], [SortTypes.ASC, SortTypes.ASC]);

//    return unitPositions;
};

export const getClassRank = (data: IData[], size: number, component: IComponent, units: IUnit[], gclass: IGroupClass, unitId: number = null): IClassRank => {
    const positions = getTopRank(data, size, component, units, unitId);
    return {
        id: gclass.id,
        code: gclass.code,
        name: gclass.name,
        order: gclass.order,
        rank: positions,
    };
};

export const getClassRanks = (data: IData[], size: number, component: IComponent, units: IUnit[], classes: IGroupClass[], ugroups: IUnitGroup[], unitId: number = null): IClassRank[] => {
    if (!classes || !classes.length) {
        return [{
            name: 'All countries',
            order: 1,
            rank: getTopRank(data, size, component, units, unitId),
        }];
    }
    const ranks = classes.map((c) => {
        const ids = filterArrayByMultipleAttrs<IUnitGroup>(ugroups, ['class_id'], [c.id]).map((u) => u.unit_id);
        const fdata = filterArrayByMultipleAttrs<IData>(data, ['unit_id'], [ids]);
        return getClassRank(fdata, size, component, units, c, unitId);
    });
    return sortArrayByAttr(ranks, 'order', SortTypes.ASC, -1);
};

export const getAllChildrenFromElement = <T>(data: T[], reference: T, refAttr: string, childrenAttr: string): T[] => {
    const res = [];
    res.push(reference);
    const children = data.filter((d) => d[childrenAttr] === reference[refAttr]);
    if (!children.length) { return res; }
    children.map((c) => {
        res.push(...getAllChildrenFromElement<T>(data, c, refAttr, childrenAttr));
    });
    return res;
};

/**
 * Creates a tree from an array of elements
 * @param data
 * @param reference
 * @param refAttr
 * @param childrenAttr
 * @param root
 */
export const getChildrenTreeFromElement = (components: IComponent[], root: number, weights: IWeight[], refAttr: string, childrenAttr: string, weightKeyAttr: string): ComponentTree => {
    const tree = new ComponentTree(components, root, refAttr, childrenAttr, weights, weightKeyAttr, 'order');
    return tree;
};

/**
 * Create a node tree combining parents and children array from different types
 * @param parents - Array of parents - type P
 * @param children - Array of children - type C
 * @param id - id of the parent
 * @param parentAttr - parent id attr on children array
 * @return IDualNode<P, C>
 */
export const combineParentChildrenNodeTree = <P, C>(parents: P[], children: C[], id: string, parentAttr): Array<IDualNode<P, C>> => {
    return parents.map((p) => {
        return {
            node: p,
            children: children.filter((c) => c[parentAttr] === p[id]),
        };
    });
};

export const calcFromArray = <T>(data: T[], attr: string, type: StatisticsType): number | number[] => {
    let unique;
    switch (type) {
    case StatisticsType.Min:
        unique = getUniqueSortedAttrFromArray<T, number>(data, attr);
        return unique[0];
    case StatisticsType.Max:
        unique = getUniqueSortedAttrFromArray<T, number>(data, attr, SortTypes.DESC);
        return unique[0];
    case StatisticsType.RANGE:
        unique = getUniqueSortedAttrFromArray<T, number>(data, attr);
        return unique.length > 1 ? [unique[0], unique[unique.length - 1]] : null;
    case StatisticsType.TOTAL:
        return data.filter((d) => !!d[attr]).reduce((sum, elem) => sum + elem[attr], 0);
    case StatisticsType.Avg:
        const fdata = data.filter((d) => d[attr] != null);
        return fdata.length ? (fdata.reduce((sum, elem) => (sum + elem[attr]), 0) / fdata.length) : 0;
    default:
        return null;
    }
};

export const categorizeArray = <T>(data: T[], valueAttr: string, categoryAttr: string, type: StatisticsType, sortDirection: SortTypes = SortTypes.ASC) => {
    const categories = getUniqueSortedAttrFromArray<T, any>(data, categoryAttr, sortDirection);

    return categories.map((cat) => {
        const fdata = data.filter((d) => d[categoryAttr] === cat);
        const value = calcFromArray<T>(fdata, valueAttr, type);
        return {
            id: cat,
            data: fdata,
            value,
        };
    });
};

export const cartesianProduct = (a?, b?, ...c) => {
    const f = (a, b) => [].concat(...a.map((d) => b.map((e) => [].concat(d, e))));
    const cartesian = (a, b?, ...c) => (b ? cartesian(f(a, b), ...c) : a);

    return cartesian(a, b, ...c);
};

export const multiCategorizeArray = <T>(data: T[], valueAttr: string, categoryAttrs: string[], type: StatisticsType, sortDirection: SortTypes = SortTypes.ASC) => {

    const categoriesArray = categoryAttrs.map((catAttr) => getUniqueSortedAttrFromArray<T, any>(data, catAttr, sortDirection));
    const categories = cartesianProduct(...categoriesArray);

    return categories.map((cat) => {
        const cats = categoryAttrs.reduce((res, elem, i) => ({ ...res, [elem]: cat[i] }), {});
        const fdata = filterArrayByMultipleAttrs<T>(data, categoryAttrs, cat);

        const value = calcFromArray<T>(fdata, valueAttr, type);
        return {
            data: fdata,
            ...cats,
            value,
        };
    });
};

export const aggregateData = (unit_id: number, data: IData[], categoryAttrs: string[], type: StatisticsType, sortDirection: SortTypes = SortTypes.ASC): IData[] => {
    const gdata = multiCategorizeArray<IData>(data, 'value', categoryAttrs, type, sortDirection);

    return gdata.map((d) => ({
        ...d.data[0],
        ...d,
        unit_id,
    }));
};

export const getAverageByGroups = (unit_id: number, groups: IUnitGroup[][], data: IData[]) => {

    const gdata = groups.reduce((result, g) => {
        const unit_ids = g.map((d) => d.unit_id);
        const fdata = filterArrayByMultipleAttrs<IData>(data, ['unit_id'], [unit_ids]);
        return [...result, ...aggregateData(unit_id, fdata, ['variable_group_id', 'variable_id', 'year'], StatisticsType.Avg)];
    }, []);

    return aggregateData(unit_id, gdata as IData[], ['variable_group_id', 'variable_id', 'year'], StatisticsType.Avg);
};

export const getObjectSubset = <T>(element: T, attrs: string[]): Partial<T> => {
    return attrs.reduce((res, attr) => { res[attr] = element[attr]; return res; }, {});
};

export const getArrayObjectSubset = <T>(elements: T[], attrs: string[]): Array<Partial<T>> => {
    return elements.map((element) => getObjectSubset<T>(element, attrs));
};

export const intersection = (array1: Array<string | number>, array2: Array<string | number>): Array<string|number> => {
    return array1.filter((value) => array2.includes(value));
};

export const getAllParents = <T>(data: T[], idAttr: string, parentAttr: string, parent_id: number): T[] => {
    let element: T;

    if (!parent_id) {
        return [];
    }

    try {
        element = data.find((d) => d[idAttr] === parent_id);
        return [
            element,
            ...getAllParents<T>(data, idAttr, parentAttr, element[parentAttr]),
        ];
    } catch (e) {
        return [];
    }
};

export const groupDataWithStatistics = (data: IData[], attrs: string[], statistics: StatisticsType[]) => {
    const unique_ids = attrs.map((attr) => getUniqueArray<IData>(data, attr));
    const alls = cartesianProduct(...unique_ids);
    const mappedData = alls.map((d) => {
        return attrs.reduce((res, elem, i) => {
            res[elem] = d[i] || d;
            res.data = [...res.data.filter((dd) => dd[elem] === res[elem])];
            return res;
            } , { data });
    });

    mappedData.map((m) => {
        statistics.map((stat) => m[stat] = (Statistics.statByType(stat))(m.data, 'value'));
    });

    return mappedData;
};
