import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { CmsChartsService } from '@features/general/charts/state/cms-charts.service';
import { selectCmsChartGeoLayerId } from '@features/general/charts/state/geo/cms-chart-geo.entity';
import {
    CmsChartsGeoActionTypes,
    CmsChartsGeoSetLayersUrl,
    CmsChartsGeoSetLayersUrls
} from '@features/general/charts/state/geo/cms-charts-geo.actions';
import {
    selectCmsChartsGetChartLayersWithAtLeastOneParam,
    selectCmsChartsGetChartNoParamsLayers
} from '@features/general/charts/state/geo/cms-charts-geo.selectors';
import {
    CmsCHartsUpdateHighlights, CmsChartsUpdateHighlightsUrls
} from '@features/general/charts/state/highlights/cms-charts-highlights.actions';
import {
    CmsChartsActionTypes,
    CmsChartsActiveChartsRequested,
    CmsChartsAddCopyParam,
    CmsChartsAddCopyParams,
    CmsChartsAddNewCopiedSeriesAndParams,
    CmsChartsClearParams,
    CmsChartsCopyChart,
    CmsChartsCopyChartRequested,
    CmsChartsDownloadDataInCSVRequested,
    CmsChartsDownloadDataInJSONRequested,
    CmsChartsDownloadDataInXLSXRequested,
    CmsChartsFullActionTypes,
    CmsChartsLoaded, CmsChartsMultiDataLoaded,
    CmsChartsParamsActionTypes,
    CmsChartsParamsSetParamActiveValues,
    CmsChartsPDFWithChartAsPNGDownloadRequested,
    CmsChartsPDFWithChartAsSVGDownloadRequested,
    CmsChartsRemoveCopyParam,
    CmsChartsRemoveCopyParams,
    CmsChartsRemoveParamsAndSeriesByIds,
    CmsChartsRemoveUrlsFromRequested,
    CmsChartsRequestData,
    CmsChartsResetCopiedParam,
    CmsChartsResetState,
    CmsChartsSeriesActionTypes,
    CmsChartsSeriesAndCategoriesSetUrls,
    CmsChartsSeriesAndCategoriesUpdates,
    CmsChartsSeriesCopyWithNewParam,
    CmsChartsSeriesRemoveCopied,
    CmsChartsSetMultipleParamRequest,
    CmsChartsSetPageParams,
    CmsChartsSetParams,
    CmsChartsSetParamsFromLists,
    CmsChartsSetTooltip,
    CmsChartsSetUrlsAsRequested,
    CmsChartsSetValuesParams,
    CmsChartsTooltipActionTypes,
    CmsChartsTooltipDataLoaded,
    CmsChartsUpdateFilters,
    CmsChartsUpdateFiltersUrls,
} from '@features/general/charts/state/main/actions';
import { selectCmsChartId } from '@features/general/charts/state/main/entities/cms-chart.entity';
import {
    cmsChartsEffectsAddSeriesWhenSetCopiedParams,
    cmsChartsEffectscmsChartsRemoveCopyParams,
    cmsChartsEffectsRequestIndepFiltersAndHighlightsAfterSetPageParameters,
    selectCmsChartCopiedParamsWithSameOrigin,
    selectCmsChartCopyAllElements,
    selectCmsChartsEffectsDataUrlsRemoveFromRequested,
    selectCmsChartsEffectsDataUrlsRequested,
    selectCmsChartsEffectsFilterWithDeps,
    selectCmsChartsEffectsFilterWithoutDeps,
    selectCMsChartsEffectsSetFiltersAndHighlightsStatesOnListLoading,
    selectCmsChartsEffectsSetParamsFromLists,
    selectCmsChartsEffectsSetParamsFromValues,
    selectCmsChartsEffectsSetSeriesUrls,
    selectCmsChartsGetChartConvertedParamsAsObject,
    selectCmsChartsGetChartIndependentFilters,
    selectCmsChartsParamsGetMultipleParams,
    selectCmsChartsRouterParams,
    selectCmsChartsSeriesElemsWithSomeParams,
    selectCmsChartsUrlsNotLoaded,
    selectCsmChartsActiveCharts
} from '@features/general/charts/state/main/selectors';
import CmsChartParamsHelper from '@features/general/charts/state/params/cms-chart-params-helper';
import {
    selectCmsChartsTooltipDataUrl, selectCmsChartsTooltipIsDataLoaded
} from '@features/general/charts/state/tooltip/cms-chart.tooltip.selectors';
import { CmsChartsSetParamsStrategy } from '@features/general/charts/structure';
import { CmsChartsHelper } from '@features/general/charts/structure/cms-charts.helper';
import { CmsActionTypes, CmsPagesLoaded } from '@features/general/cms/state/cms.actions';
import { selectCmsActiveCharts, selectCmsActivePage } from '@features/general/cms/state/cms.selectors';
import {
    ListsActionTypes,
    ListsLoaded,
    ListsLoadedAtRequestTime,
    ListsRequestedByUrls
} from '@features/general/lists/state/lists.actions';
import { selectListById } from '@features/general/lists/state/lists.selectors';
import { ListsScopes } from '@features/general/lists/structure';
import { QueueAdd } from '@features/general/queue/state/queue.actions';
import { GeoService } from '@features/geo/geo.service';
import { GeoDataRequestedByUrl } from '@features/geo/state/geo.actions';
import { ArrayHelper } from '@shared/helpers';
import { of } from 'rxjs';
import { concatMap, filter, map, mergeMap, switchMap, toArray, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class CmsChartsEffects {

    /**
     * When a new cms page is loaded, resets the charts and requests the new ones
     */
    cmsNewPageLoaded = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsPagesLoaded>(CmsActionTypes.CmsPagesLoaded),
            concatMap(() => [
                new CmsChartsResetState(),
                new CmsChartsActiveChartsRequested()
            ])
        ));

    /**
     * Request active charts (loaded from cms)
     * Loads the charts from the cms page
     */
// TODO uncomment
    cmsChartsLoadingNewCharts = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsActiveChartsRequested>(CmsChartsActionTypes.CmsChartsActiveChartsRequested),
            withLatestFrom(this.store.select(selectCsmChartsActiveCharts)),
            filter(([, charts]) => !charts.length),
            withLatestFrom(this.store.select(selectCmsActivePage)),
            filter(([, page]) => !!page),
            map(([, page]) => page.frontend_page),
            withLatestFrom(this.store.select(selectCmsActiveCharts)),
            filter(([, charts]) => !!charts.length),
            map(([frontend_page, charts]) => {
                charts = charts.map((chart) => ({
                    ...chart,
                    page: frontend_page,
                    loadingState: CmsChartsHelper.getInitialLoadingState(chart)
                }))
                return new CmsChartsLoaded({ charts });
            })
        ));

    /**
     * Request active charts (loaded from cms)
     * If not on the cms, wait for cms loaded (put request in the queue
     */
    cmsChartsAddRequestToQueueWhenPageNotAvailable = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsActiveChartsRequested>(CmsChartsActionTypes.CmsChartsActiveChartsRequested),
            withLatestFrom(this.store.select(selectCmsActivePage)),
            filter(([, page]) => !page),
            map(([request, ]) => new QueueAdd({
                items: [{
                    action: request,
                    relation: CmsActionTypes.CmsPagesLoaded
                }]
            }))
        ));

    /**
     * Request layers without parameters (for maps)
     */
    cmsChartsGetMapLayers = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsLoaded>(CmsChartsActionTypes.CmsChartsLoaded),
            map((request) => request.payload.charts),
            mergeMap((charts) => charts),
            mergeMap((chart) => of(chart)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsGetChartNoParamsLayers(selectCmsChartId(chart)))),
                )),
            filter(([, layers]) => !!layers.length),
            map(([, layers]) => this.geoService.updateLayersUrls(layers)),
            map((layers) => layers.map((layer) => ({
                layer_id: selectCmsChartGeoLayerId(layer),
                urls: [{ url: layer.url, keys: layer.keys, order: layer.order }]
            }))),
            map((layers) => new CmsChartsGeoSetLayersUrls(layers))
        ));

    // PARAMS EFFECTS

    /**
     * 1
     * initial page params setup
     * after the charts finishing loading
     */
    cmsChartsSetInitialPageParamOnChartsLoading = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsLoaded>(CmsChartsActionTypes.CmsChartsLoaded),
            map((request) => request.payload.charts),
            withLatestFrom(this.store.select(selectCmsChartsRouterParams)),
            map(([charts, router_params]) => {
                return charts.reduce((params, chart) => {
                    const page_params = CmsChartParamsHelper.GetPageParamsFromChart(chart);
                    const curr_params = Object.keys(page_params).map((key) => ({
                        chart_id: selectCmsChartId(chart),
                        name: key,
                        value: router_params[key] || null
                    }));
                    return [...params, ...curr_params];
                }, []);
            }),
            map((params) => new CmsChartsSetPageParams(params))
        ));

    // todo: need to refactor to consider the load strategy
    /**
     * 2.a
     * After setting the mock page parameters for one chart
     * If an independent filter doesn't have url (instead have a set of values fixed by the chart) then it'll set the first value
     */
    cmsChartsSetPageParametersIndependentAfterPageParams = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsSetPageParams>(CmsChartsParamsActionTypes.CmsChartsSetPageParams),
            map((request) => request.payload),
            mergeMap((request) => request),
   //         map((d) => { console.log(d); return d; }),
            filter((request) => request?.name === 'mock'),
            mergeMap((request) => of(request)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsGetChartIndependentFilters(request.chart_id))),
                    map(([, filters]) => (filters || []).filter((f) => !!f.values && !!f.values.length)),
                    filter((filters) => !!filters.length),
                    map((filters) => {
                        return filters
                            .filter((f) => !!f.values && !!f.values.length)
                            .map((f) => ({
                                chart_id: request.chart_id,
                                name: f.param,
                                value: f.values[0].key
                            }));
                    }),
  //                  map((d) => { console.log(d); return d; }),
                    map((payload) => new CmsChartsSetParams(payload))
                )
            )
        ));

    /**
     * 2.b
     * After setting the page parameters for one chart
     * 1 - Update filters with new lists urls (if related list is not loaded)
     * Load independent params which lists are already loaded (from the cms page)
     * When there are no page params
     * If an independent filter doesn't have url (instead have a set of values fixed by the chart)
     * then it'll set the value according to the strategy
     * Set filters and highlights status as loading
     */
    cmsChartsRequestIndepFiltersAndHighlightsAfterSetPageParameters = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsSetPageParams>(CmsChartsFullActionTypes.CmsChartsSetPageParams),
            map((request) => request.payload),
            map((params) => ArrayHelper.unique(ArrayHelper.pluck(params, 'chart_id'))),
            mergeMap((chart_ids: string[]) => of(chart_ids)
                .pipe(
                    withLatestFrom(this.store.select(cmsChartsEffectsRequestIndepFiltersAndHighlightsAfterSetPageParameters(chart_ids))),
                    map(([, payloads]) => payloads),
                )
            ),
 //           map((d) => { console.log(d); return d; }),
            mergeMap((payload) => {
                const filters = payload.filters?.length > 0 ? [new CmsChartsUpdateFiltersUrls(payload.filters)] : [];
                const highlights = payload.highlights?.length > 0 ? [new CmsChartsUpdateHighlightsUrls(payload.highlights)] : [];
                const setParams = payload.setParams?.length > 0 ? [new CmsChartsSetValuesParams(payload.setParams)] : [];
                return [...filters, ...highlights, ...setParams];
            })
        ));

    /**
     * 3
     * After setting filters and highlights urls,
     * Send a request to the lists to provide data for those urls
     */
    cmsChartsUpdateFilterUrls = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsUpdateFiltersUrls>(CmsChartsFullActionTypes.CmsChartsUpdateFiltersUrls, CmsChartsFullActionTypes.CmsChartsUpdateHighlightsUrls),
            map((request) => request.payload.map((d) => d.changes).filter((changes) => changes.list_id !== null)),
            filter((changes) => changes.length > 0),
  //          map(d => { console.log(d); return d; }),
            map((payload) => ArrayHelper.uniques(payload, ['list_id']).filter((elem: any) => !!elem.list_id)),
            filter((uniques) => uniques?.length > 0),
            map((uniques) => uniques.map((d) => ({ type: ListsScopes.page, slug: null /*d.slug as string*/, url: d.list_id }))),
            map((urls) => new ListsRequestedByUrls(urls)),
        ));

    /**
     * 4.a
     * When the list is available,
     * setup the corresponding parameters
     */
    cmsListsLoadChartsUpdate = createEffect(() => this.actions$
        .pipe(
            ofType<ListsLoaded>(ListsActionTypes.ListsLoaded),
 //           map((d) => { console.log(d); return d; }),
            map((action) => action.payload.lists),
            mergeMap((lists) => of(lists)
                .pipe(
                    withLatestFrom(this.store.select(selectCMsChartsEffectsSetFiltersAndHighlightsStatesOnListLoading(lists, 'id'))),
                )
            ),
    //        map(d => {console.log(d); return d;}),
            switchMap(([lists, statusUpdates]) => {
                const actions: any[] = [new CmsChartsSetParamsFromLists({ lists })];
                if (statusUpdates.filters.length > 0) {
                    actions.push(new CmsChartsUpdateFilters(statusUpdates.filters));
                }
                if (statusUpdates.highlights.length > 0) {
                    actions.push(new CmsCHartsUpdateHighlights(statusUpdates.highlights));
                }
                return actions;
            }),
        ));

    /**
     * 4.b
     * If the lists are available when requested
     * setup the corresponding params
     */
    cmsListsLoadedAtRequestTime = createEffect(() =>this.actions$
        .pipe(
            ofType<ListsLoadedAtRequestTime>(ListsActionTypes.ListsLoadedAtRequestTime),
            map((action) => action.payload),
 //           map(d => {console.log(d); return d;}),
            mergeMap((list: any) => of(list)
                .pipe(
                    withLatestFrom(this.store.select(selectListById(list.url))),
                    map(([, lists]) => lists),
                    withLatestFrom(this.store.select(selectCMsChartsEffectsSetFiltersAndHighlightsStatesOnListLoading([list], 'url'))),
                )
            ),
 //           map(d => {console.log(d); return d;}),
            switchMap(([list, statusUpdates]) => {
                const actions: any[] = [new CmsChartsSetParamsFromLists({ lists: [list] })];
                if (statusUpdates.filters.length > 0) {
                    actions.push(new CmsChartsUpdateFilters(statusUpdates.filters));
                }
                if (statusUpdates.highlights.length > 0) {
                    actions.push(new CmsCHartsUpdateHighlights(statusUpdates.highlights));
                }
                return actions;
            })
        ));

    /**
     * 5.a
     * When a list is updated, sets the params according to the strategy and default values
     */
    cmsChartsSetParamsFromLists = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsSetParamsFromLists>(CmsChartsFullActionTypes.CmsChartsSetParamsFromLists),
            map((request) => request.payload.lists),
//            map(d => {console.log(d); return d;}),
            mergeMap((lists) => of(lists)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsEffectsSetParamsFromLists(lists))),
 //                   map(d=>{console.log(d); return d;}),
                    filter(([, params]) => params.setParams?.length > 0 || params.setParamsWithMultipleValues?.length > 0),
                 //   map(([, params]) => params.map((p) => ({ chart_id: p.chart_id, name: p.name, value: p.value }))),
                )
            ),
            mergeMap(([, payloads]) => {
                let actions = [];
                if (payloads.setParams?.length > 0) {
                    actions = [new CmsChartsSetParams(payloads.setParams)];
                }
                if (payloads.setParamsWithMultipleValues?.length > 0) {
                    actions = [
                        ...actions,
                        ...payloads.setParamsWithMultipleValues.map((action) => new CmsChartsParamsSetParamActiveValues(action))
                    ];
                }
                return actions;
            })
            // map((params) => new CmsChartsSetParams(params)),
        ));

    /**
     * 5.a«b
     * When a param is updated from a filters list (no lists requested)
     * the dependents needs to be cleared out
     */
    cmsChartsSetParamsFromValues = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsSetValuesParams>(CmsChartsFullActionTypes.CmsChartsSetValuesParams),
            map((request) => request.payload),
            mergeMap((lists) => of(lists)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsEffectsSetParamsFromValues(lists))),
                    map(([, payloads]) => payloads),
                )
            ),
    //        map(d => {console.log(d); return d;}),
            map((payloads) => new CmsChartsSetParams(payloads.setParams))
        ));

    /**
     * 6
     * After a param is set with a new value,
     * Its dependants need to be updated
     */
    cmsChartsSetDependantChartParameters = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsSetParams>(CmsChartsFullActionTypes.CmsChartsSetParams),
            map((request) => ArrayHelper.GroupBy(request.payload, 'chart_id', 'names', ['name'])),
            mergeMap((params: Array<{ chart_id: string, names: string[] }>) => of(params)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsEffectsFilterWithDeps(params))),
                )),
 //           map(d=>{console.log(d); return d;}),
            map(([, payloads]) => {
                const actions = [];
                if (payloads.clearParams.length > 0) {
                    payloads.clearParams.map((param) => actions.push(new CmsChartsParamsSetParamActiveValues(param)));
        //            actions.push(new CmsChartsClearParams(payloads.clearParams));
                }
                if (payloads.updateUrls.length > 0) {
                    actions.push(new CmsChartsUpdateFiltersUrls(payloads.updateUrls));
                }
                return actions;
            }),
            filter((actions) => actions.length > 0),
            mergeMap((actions) => actions)
        ));

    // SERIES EFFECTS

    /**
     * 7
     * When setting new values for params, the series and categories urls must be updated
     * only update series and categories if params dont have deps (otherwise it considers that chart didn't finished loading)
     */
    cmsChartsSetChartSeriesAndCategoriesUrl = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsSetParams>(CmsChartsFullActionTypes.CmsChartsSetParams, CmsChartsParamsActionTypes.CmsChartsSetPageParams),
            map((request) => request.payload),
            mergeMap((payload) => of(payload)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsEffectsFilterWithoutDeps(payload))),
                    map(([, filteredParams]) => filteredParams),
                )
            ),
            filter((params) => params.length > 0),
            mergeMap((filteredParams) => of(filteredParams)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsEffectsSetSeriesUrls(ArrayHelper.GroupBy(filteredParams, 'chart_id', 'names', ['name']) as any))),
                    map(([, urls]) => urls),
                    filter((urls) => urls.series.length > 0 || urls.categories.length > 0),
                    map((urls) => ({
                        series: urls.series.map((s) => ({ id: s.series_id, changes: { url: s.url } })),
                        categories: urls.categories.map((c) => ({ id: c.categories_id, changes: { url: c.url } })),
                    })),
                    map((payload) => new CmsChartsSeriesAndCategoriesSetUrls(payload))
                )
            ),
        ));

    // MIXED EFFECTS

    /**
     * 8
     * Requests data when series and categories urls changes
     */
    cmsChartsRequestDataOnUrlChange = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsSeriesAndCategoriesSetUrls>(CmsChartsFullActionTypes.CmsChartsSeriesAndCategoriesSetUrls),
            map((request) => {
                const series_urls = request.payload.series.map((s) => s.changes.url);
                const categories_urls = request.payload.categories.map((s) => s.changes.url);
                return [...series_urls, ...categories_urls];
            }),
            map((urls) => urls.filter((url) => !!url)),
            filter((urls) => urls.length > 0),
            map((urls) => new CmsChartsRequestData({ urls }))
        ));

    /**
     * 9
     * Requesting new data for the charts
     * - request urls
     * - set loading states (series and categories) to loading
     */
    cmsChartsRequestDataForCharts = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsRequestData>(CmsChartsActionTypes.CmsChartsRequestData),
            map((request) => request.payload.urls),
            mergeMap((requested_urls) => of(requested_urls)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsEffectsDataUrlsRequested(requested_urls))),
                    map(([, payloads]) => payloads)
                )
            ),
 //           map(d => {console.log(d); return d; }),
            mergeMap((payloads) => {
                let actions = [];
                if (payloads.urls.length > 0) {
                    actions = [
                        new CmsChartsSeriesAndCategoriesUpdates(payloads.updates),
                        new CmsChartsSetUrlsAsRequested({ urls: payloads.urls })
                    ];
                } else {
                    actions = [
                        new CmsChartsSeriesAndCategoriesUpdates(payloads.updates),
                    ];
                }
                return actions;
            })
        ));

    /**
     * 10
     * Requesting new data for the chart
     */
    cmsChartsLoadDataForOneChart = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsSetUrlsAsRequested>(CmsChartsActionTypes.CmsChartsSetUrlsAsRequested),
            map((request) => request.payload.urls),
            concatMap((urls) => of(urls)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsUrlsNotLoaded(urls))),
 //                   map(d => { console.log(d, urls); return d; }),
                    filter(([, furls]) => !!furls.length),
                    mergeMap(([, furls]) => furls),
                    mergeMap((url) => this.chartsService.getChartDataByUrl(url)),
 //                   map(d => { console.log(d); return d; }),
                    map((data) => {
                        if (! Array.isArray(data.data) && !! data.data) {
                            data = { ...data, data: Object.entries(data.data) };
                        }
                        return data;
                    }),
                    toArray(),
                    map((data: any) => new CmsChartsMultiDataLoaded({ data })),
                )
            ),
        ));

    /**
     * 11
     * Removing loaded urls from requested
     * - remove urls from requested
     * - set loading states (series and categories) to loaded
     */
    cmsChartsRemoveUrlsAsRequestedMulti = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsMultiDataLoaded>(CmsChartsActionTypes.CmsChartsMultiDataLoaded, CmsChartsActionTypes.CmsChartsDataLoaded),
            map((request) => Array.isArray(request.payload.data) ? request.payload.data : [request.payload.data]),
//            map(d => {console.log(d); return d; }),
            mergeMap((data_loaded: any) => of(data_loaded)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsEffectsDataUrlsRemoveFromRequested(data_loaded))),
                    map(([, payloads]) => payloads)
                )
            ),
            //           map(d => {console.log(d); return d; }),
            mergeMap((payloads: any) => {
                return [
                    new CmsChartsSeriesAndCategoriesUpdates(payloads.updates),
                    new CmsChartsRemoveUrlsFromRequested({ urls: payloads.urls })
                ];
            })
        ));

    CmsChartsPDFWithChartAsSVGDownloadRequested = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsPDFWithChartAsSVGDownloadRequested>(CmsChartsActionTypes.CmsChartsPDFWithChartAsSVGDownloadRequested),
            mergeMap((request) => of(request)
                .pipe(
                    concatMap(() => {
                        this.chartsService.handlePDFWithChartAsSVGDownload(request.payload.chart, request.payload.element);
                        return [];
                    }),
                )
            )
        ));

    CmsChartsPDFWithChartAsPNGDownloadRequested = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsPDFWithChartAsPNGDownloadRequested>(CmsChartsActionTypes.CmsChartsPDFWithChartAsPNGDownloadRequested),
            mergeMap((request) => of(request)
                .pipe(
                    concatMap(() => {
                        this.chartsService.handlePDFWithChartAsPNGDownload(request.payload.chart, request.payload.base64);
                        return [];
                    }),
                )
            )
        ));

    CmsChartsDownloadDataInXLSXRequested = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsDownloadDataInXLSXRequested>(CmsChartsActionTypes.CmsChartsDownloadDataInXLSXRequested),
            mergeMap((request) => of(request)
                .pipe(
                    concatMap(() => {
                        this.chartsService.handleDataInXLSXDownload(request.payload.chart);
                        return [];
                    }),
                )
            )
        ));

    CmsChartsDownloadDataInCSVRequested = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsDownloadDataInCSVRequested>(CmsChartsActionTypes.CmsChartsDownloadDataInCSVRequested),
            mergeMap((request) => of(request)
                .pipe(
                    concatMap(() => {
                        this.chartsService.handleDataInCSVDownload(request.payload.chart);
                        return [];
                    }),
                )
            )
        ));

    CmsChartsDownloadDataInJSONRequested = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsDownloadDataInJSONRequested>(CmsChartsActionTypes.CmsChartsDownloadDataInJSONRequested),
            mergeMap((request) => of(request)
                .pipe(
                    concatMap(() => {
                        this.chartsService.handleDataInJSONDownload(request.payload.chart);
                        return [];
                    }),
                )
            )
        ));

    cmsChartsCloneRequested = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsCopyChartRequested>(CmsChartsActionTypes.CmsChartsCopyChartRequested),
            map((action) => action.payload.id),
            mergeMap((id) => of(id)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartCopyAllElements(id))),
                    map(([, chart]) => new CmsChartsCopyChart(chart))
                ))
        ));

    cmsChartsTooltipGetData = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsSetTooltip>(CmsChartsTooltipActionTypes.CmsChartsSetTooltip),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectCmsChartsTooltipIsDataLoaded)),
            filter(([, loaded]) => !loaded),
            withLatestFrom(this.store.select(selectCmsChartsTooltipDataUrl)),
            switchMap(([, url]) => this.chartsService.getChartDataByUrl(url)),
            map((data) => new CmsChartsTooltipDataLoaded(data))
        ));

    /**
     * The Set Multiple Params Request uses a toggle strategy
     */
    cmsChartsSetMultipleParamRequest = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsSetMultipleParamRequest>(CmsChartsParamsActionTypes.CmsChartsSetMultipleParamRequest),
            map((action) => action.payload),
            map((requests) => CmsChartParamsHelper.ConvertMultipleRequestToSetActiveValues(requests, CmsChartsSetParamsStrategy.toggle)),
 //           map((d) => { console.log(d); return d; }),
            map((values) => values.map((value) => new CmsChartsParamsSetParamActiveValues(value)))
        ));

    /**
     * Defined the copy strategies when multiple values are selected
     */
    cmsChartsParamsSetParamActiveValues = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsParamsSetParamActiveValues>(CmsChartsParamsActionTypes.CmsChartsParamsSetParamActiveValues),
            map((action) => action.payload),
  //          map((d) => { console.log(d); return d; }),
            mergeMap((payload) => of(payload)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsParamsGetMultipleParams(payload.chart_id, payload.name, payload.values, payload.strategy || CmsChartsSetParamsStrategy.default))),
                    map(([, payloads]) => {
                        const actions = [];
                        if (payloads.remove.length > 0) {
                            actions.push(new CmsChartsRemoveCopyParams(payloads.remove as any));
                        }
                        if (payloads.add.length > 0) {
                            actions.push(new CmsChartsAddCopyParams(payloads.add as any));
                        }
                        if (payloads.setParams.length > 0) {
                            actions.push(new CmsChartsSetParams(payloads.setParams));
                        }

                        return actions;
                    }),
                    filter((actions) => !!actions.length),
                    mergeMap((actions) => actions)
                ))
        ));

    cmsChartsRemoveSeriesWhenRemoveCopyParam = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsRemoveCopyParam>(CmsChartsParamsActionTypes.CmsChartsRemoveCopyParam),
            mergeMap((param) => of(param)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsSeriesElemsWithSomeParams(param.payload.chart_id, [param.payload.name]))),
                    filter(([, series]) => !!series.length),
                    map(([, series]) => series),
                    mergeMap((series) => series),
                    map((series) => new CmsChartsSeriesRemoveCopied({ chart_id: series.chart_id, name: series.name }))
                ))
        ));

    cmsChartsAddSeriesWhenSetCopiedParam = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsAddCopyParam>(CmsChartsParamsActionTypes.CmsChartsAddCopyParam),
            map((action) => action.payload),
            mergeMap((action) => of(action)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsSeriesElemsWithSomeParams(action.param.chart_id, [action.param.origin]))),
                    filter(([, series]) => !!series.length),
                    map(([, series]) => series),
                    map((series) => new CmsChartsSeriesCopyWithNewParam({ series, param: action.param, param_name: action.name, param_value: action.value })),
                )),
        ));

    cmsChartsAddSeriesWhenSetCopiedParams = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsAddCopyParams>(CmsChartsParamsActionTypes.CmsChartsAddCopyParams),
            map((action) => action.payload),
            map((payload) => payload.map((p) => ({ chart_id: p.param.chart_id, params: [p.param.origin], param: p.param, newName: p.name, newValue: p.value }))),
            mergeMap((payload) => of(payload)
                .pipe(
                    withLatestFrom(this.store.select(cmsChartsEffectsAddSeriesWhenSetCopiedParams(payload))),
 //                   map(d => { console.log(d); return d; }),
                    filter(([, seriesAndParams]) => !!seriesAndParams.series.length || !!seriesAndParams.params.length),
                    map(([, seriesAndParams]) => seriesAndParams),
                    map((seriesAndParams) => new CmsChartsAddNewCopiedSeriesAndParams({ series: seriesAndParams.series, params: seriesAndParams.params })),
                )),
        ));

    cmsChartsSetCopiedParamValue = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsSeriesCopyWithNewParam>(CmsChartsSeriesActionTypes.CmsChartsSeriesCopyWithNewParam),
            map((action) => action.payload),
//            map((d) => { console.log(d); return d; }),
            map((action) => new CmsChartsSetParams([{ chart_id: action.param.chart_id, name: action.param_name, value: action.param_value }]))
        ));

    cmsChartsSetCopiedParamValues = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsAddNewCopiedSeriesAndParams>(CmsChartsSeriesActionTypes.CmsChartsAddNewCopiedSeriesAndParams),
            map((action) => action.payload.params),
            map((params) => params.map((d) => ({ chart_id: d.chart_id, name: d.name, value: d.value }))),
//            map((d) => { console.log(d); return d; }),
            map((payload) => new CmsChartsSetParams(payload))
        ));

    cmsChartsResetCopiedParam = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsResetCopiedParam>(CmsChartsParamsActionTypes.CmsChartsResetCopiedParam),
            mergeMap((action) => of(action)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartCopiedParamsWithSameOrigin(action.payload.chart_id, action.payload.origin))),
                    filter(([, params]) => !!params.length),
                    map(([, params]) => params),
                    mergeMap((params) => params),
                    map((param) => new CmsChartsRemoveCopyParam({ chart_id: param.chart_id, name: param.name }))
                ))
        ));

    cmsChartsRemoveCopyParams = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsRemoveCopyParams>(CmsChartsParamsActionTypes.CmsChartsRemoveCopyParams),
            map((action) => action.payload.map((p) => ({ chart_id: p.chart_id, params: p.name }))),
            mergeMap((params) => of(params)
                .pipe(
                    withLatestFrom(this.store.select(cmsChartsEffectscmsChartsRemoveCopyParams(params))),
                    filter(([, payload]) => payload.paramsIds.length > 0 || payload.seriesIds.length > 0),
                    map(([, payload]) => new CmsChartsRemoveParamsAndSeriesByIds(payload))
                ))
        ));
/*
    @createEffect(() =>)
    cmsChartsResetCopiedParams = this.actions$
        .pipe(
            ofType<CmsChartsResetCopiedParams>(CmsChartsParamsActionTypes.CmsChartsResetCopiedParams),
            map((action) => action.payload),
            mergeMap((params) => params),
            map((param) => new CmsChartsResetCopiedParam(param))
        );*/

    // Geo effects

    cmsChartsGeoSetUrl = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsGeoSetLayersUrl>(CmsChartsGeoActionTypes.CmsChartsGeoSetLayersUrl),
            map((action) => action.payload.filter((layers) => layers.url !== null)),
            filter((layers) => !!layers.length),
            map((layers) => layers.map((layer) => layer.url)),
            mergeMap((urls) => urls),
            map((url) => new GeoDataRequestedByUrl({ url }))
        ));

    cmsChartsGeoSetUrls = createEffect(() =>this.actions$
        .pipe(
            ofType<CmsChartsGeoSetLayersUrls>(CmsChartsGeoActionTypes.CmsChartsGeoSetLayersUrls),
            map((action) => action.payload.filter((layers) => layers.urls && (layers.urls.length > 0))),
            filter((layers) => !!layers.length),
            map((layers) => layers.reduce((urls, layer) => [...urls, ...layer.urls.map((d) => d.url)], [])),
            map((urls) => ArrayHelper.unique(urls)),
            mergeMap((urls) => urls),
            map((url) => new GeoDataRequestedByUrl({ url }))
        ));

    // TODO uncomment
    cmsChartsGeoCheckParams = createEffect(() =>
        this.actions$
        .pipe(
            ofType<CmsChartsSetParams>(CmsChartsParamsActionTypes.CmsChartsSetParams),
            map((action) => CmsChartParamsHelper.ConvertSetParamsToParamsByChart(action.payload)),
            mergeMap((charts) => charts),
            mergeMap((chart) => of(chart)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsGetChartLayersWithAtLeastOneParam(chart.chart_id, chart.params.map((param) => param.name)))),
                    filter(([, layers]) => layers && !!layers.length),
                )
            ),
            mergeMap(([, layers]) => layers),
            mergeMap((layer) => of(layer)
                .pipe(
                    withLatestFrom(this.store.select(selectCmsChartsGetChartConvertedParamsAsObject(layer.chart_id))),
                    mergeMap(([, params]) => of (params)
                        .pipe(
                            map(() => this.geoService.getLayerTypeUrl(layer.source, params, layer && layer.rules || null)),
                            filter((url) => !!url),
                            mergeMap((url) => this.geoService.getGeoByUrl(url)),
                            filter((answer: any) => answer && answer.data && (answer.data.code === 200)),
//                            map((answer) => answer.data.data[0] || answer.data.data),
                            mergeMap((answer) => answer.data.data),
                            map((data) => this.geoService.getGeoLayerByUnitType(data, params, layer && layer.rules || null)),
                            filter((geo) => !!geo.url),
                            toArray(),
                            map((geo) =>
                                new CmsChartsGeoSetLayersUrls([{ layer_id: selectCmsChartGeoLayerId(layer), urls: geo }])
                            )
                        )
                    ),
                )
            ),
        ));

    constructor(private actions$: Actions, private store: Store<any>, private chartsService: CmsChartsService, private geoService: GeoService) {}
}
