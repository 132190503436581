import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromUnits from './unit-editions.reducers';

import { CoinState } from '../index';

export const selectCoinState = createFeatureSelector<CoinState>('composite');

/**
 * Get the UnitEditions State
 */
export const selectUnitEditionsState = createSelector(
    selectCoinState,
    (state) => state.unit_editions,
);

/**
 * Get the Unit Editions state - loaded variable
 */
export const selectUnitEditionsStateLoaded = createSelector(
    selectUnitEditionsState,
    (state) => state.loaded,
);

/**
 * Get all unit-edition relations
 */
export const selectAllUnitEditions = createSelector(
    selectUnitEditionsState,
    fromUnits.selectAll,
);

/**
 * Get all unit-edition relations for one edition
 * @param indice
 * @param edition
 */
export const selectUnitEditionsByEdition = (indice: string, edition: number) => createSelector(
    selectAllUnitEditions,
    (editions) => editions.filter((g) => g.indice === indice && g.edition === edition),
);

export const selectUnitEditionsCodeIsLoaded = (code: string) => createSelector(
    selectUnitEditionsStateLoaded,
    (loaded) => !!loaded.find((ref) => ref.includes(code)),
);
