import { Action } from '@ngrx/store';
import {
    CmsChartsLoadingState,
    ICmsChart, ICmsChartsCategories,
    ICmsChartsData,
    ICmsChartSeries,
    ICmsClonedChartInfo,
    ICmsParams
} from '@features/general/charts/structure';
import {
    CmsChartsCategoriesActionTypes
} from "@features/general/charts/state/categories/cms-charts-categories.actions";
import {Observable} from "rxjs";

export enum CmsChartsActionTypes {
    CmsChartsLoaded = '[CmsCharts] CmsChartsLoaded Charts loaded',
    CmsChartsActiveChartsRequested = '[CmsCharts] CmsChartsActiveChartsRequested Request active charts',
    CmsChartsCopyChartRequested = '[CmsCharts] Request a copy of an existing chart by id',
    CmsChartsCopyChart = '[CmsCharts] Copy existing chart',
    CmsChartsUpdateChart = '[CmsCharts] Update chart',
    CmsChartsUpdateChartRequested = '[CmsCharts] Update chart requested',
    CmsChartsRequestChartAllListsUpdates = '[CmsCharts] CmsChartsRequestChartAllListsUpdates Request list updates for one chart',
    CmsChartsRequestChartRelatedListsUpdate = '[CmsCharts] CmsChartsRequestChartRelatedListsUpdate Request updates for related lists in the chart',
    CmsChartsRequestData = '[CmsCharts] CmsChartsRequestData Request data for one chart',
    CmsChartsDataLoaded = '[CmsCharts] CmsChartsDataLoaded Data loaded',
    CmsChartsMultiDataLoaded = '[CmsCharts] CmsChartsMultiDataLoaded Data loaded for multiple charts',
    CmsChartsResetState = '[CmsCharts] CmsChartsResetState Reset charts state',
    CmsChartsSetParamOnLoadList = '[CmsCharts] CmsChartsSetParamOnLoadList Set param when list is loaded',
    CmsChartsSetUrlsAsRequested = '[CmsCharts] CmsChartsSetUrlsAsRequested Set urls as requested',
    CmsChartsRemoveUrlsFromRequested = '[CmsCharts] CmsChartsRemoveUrlsFromRequested Remove urls from requested',
    CmsChartsPDFWithChartAsSVGDownloadRequested = '[CmsCharts] Download PDF with chart as SVG requested',
    CmsChartsPDFWithChartAsPNGDownloadRequested = '[CmsCharts] Download PDF with chart as PNG requested',
    CmsChartsDownloadDataInXLSXRequested = '[CmsCharts] Download data in XLSX requested',
    CmsChartsDownloadDataInCSVRequested = '[CmsCharts] Download data in CSV requested',
    CmsChartsDownloadDataInJSONRequested = '[CmsCharts] Download data in JSON requested',
    CmsChartsChangeTitle = '[CmsCharts] Change title',
    CmsChartsSetLoadingState = '[CmsCharts] CmsChartsSetLoadingState set loading state',
    CmsChartsSetLoadingStates = '[CmsCharts] CmsChartsSetLoadingStates set loading states for multiple charts',
    CmsChartsSeriesAndCategoriesSetUrls = '[CmsCharts] CmsChartsSeriesAndCategoriesSetUrls set categories and series urls',
    CmsChartsSeriesAndCategoriesUpdates = '[CmsCharts] CmsChartsSeriesAndCategoriesUpdates updates series and categories',
    CmsChartsRemoveParamsAndSeries = '[CmsCharts] Remove params and series by chart id and name',
    CmsChartsRemoveParamsAndSeriesByIds = '[CmsCharts] Remove params and series by ids'
}

export class CmsChartsLoaded implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsLoaded;
    constructor(public payload: {charts: ICmsChart[]}) {}
}

export class CmsChartsActiveChartsRequested implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsActiveChartsRequested;
}

export class CmsChartsCopyChartRequested implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsCopyChartRequested;
    constructor(public payload: {id: string}) {}
}

export class CmsChartsCopyChart implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsCopyChart;
    constructor(public payload: ICmsClonedChartInfo) {}
}

export class CmsChartsUpdateChart implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsUpdateChart;
    constructor(public payload: {id: string, changes: Partial<ICmsChart>}) {}
}

export class CmsChartsUpdateChartRequested implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsUpdateChartRequested;
    constructor(public payload: {id: string, params: Partial<ICmsChart>}) {}
}

export class CmsChartsRequestChartAllListsUpdates implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsRequestChartAllListsUpdates;
    constructor(public payload: {chart_id: string}) {}
}

export class CmsChartsRequestChartRelatedListsUpdate implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsRequestChartRelatedListsUpdate;
    constructor(public payload: {chart_id: string, params: ICmsParams}) {}
}

export class CmsChartsRequestData implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsRequestData;
    constructor(public payload: {urls: string[]}) {}
}

export class CmsChartsDataLoaded implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsDataLoaded;
    constructor(public payload: {data: ICmsChartsData}) {}
}

export class CmsChartsMultiDataLoaded implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsMultiDataLoaded;
    constructor(public payload: {data: ICmsChartsData[]}) {}
}

export class CmsChartsResetState implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsResetState;
}

export class CmsChartsSetParamOnLoadList implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsSetParamOnLoadList;
    constructor(public payload: { chart_id: string, param: string, list_id: string}) {}
}

export class CmsChartsSetUrlsAsRequested implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsSetUrlsAsRequested;
    constructor(public payload: { urls: string[]}) {}
}

export class CmsChartsRemoveUrlsFromRequested implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsRemoveUrlsFromRequested;
    constructor(public payload: { urls: string[]}) {}
}

export class CmsChartsPDFWithChartAsSVGDownloadRequested implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsPDFWithChartAsSVGDownloadRequested;
    constructor(public payload: { chart: ICmsChart, element: HTMLElement}) {}
}

export class CmsChartsPDFWithChartAsPNGDownloadRequested implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsPDFWithChartAsPNGDownloadRequested;
    constructor(public payload: { chart: ICmsChart, base64: string}) {}
}

export class CmsChartsDownloadDataInXLSXRequested implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsDownloadDataInXLSXRequested;
    constructor(public payload: { chart: ICmsChart}) {}
}

export class CmsChartsDownloadDataInCSVRequested implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsDownloadDataInCSVRequested;
    constructor(public payload: { chart: ICmsChart}) {}
}

export class CmsChartsDownloadDataInJSONRequested implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsDownloadDataInJSONRequested;
    constructor(public payload: { chart: ICmsChart}) {}
}

export class CmsChartsChangeTitle implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsChangeTitle;
    constructor(public payload: { title: string }) {}
}

export class CmsChartsSetLoadingState implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsSetLoadingState;
    constructor(public payload: { chart_id: string, state: CmsChartsLoadingState }) {}
}

export class CmsChartsSetLoadingStates implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsSetLoadingStates;
    constructor(public payload: { chart_id: string, state: CmsChartsLoadingState }[]) {}
}

export class CmsChartsSeriesAndCategoriesSetUrls implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsSeriesAndCategoriesSetUrls;
    constructor (public payload: {
        series: { id: string, changes: { url: string } }[],
        categories: { id: string, changes: { url: string } }[]
    }) {}
}

export class CmsChartsSeriesAndCategoriesUpdates implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsSeriesAndCategoriesUpdates;
    constructor (public payload: {
        series: { id: string, changes: Partial<ICmsChartSeries> }[],
        categories: { id: string, changes: Partial<ICmsChartsCategories> }[]
    }) {}
}

export class CmsChartsRemoveParamsAndSeries implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsRemoveParamsAndSeries;
    constructor (public payload: {
        params: {chart_id: string, name: string}[],
        series: {chart_id: string, name: string}[]
    }){}
}

export class CmsChartsRemoveParamsAndSeriesByIds implements Action {
    readonly type = CmsChartsActionTypes.CmsChartsRemoveParamsAndSeriesByIds;
    constructor (public payload: { paramsIds: string[], seriesIds: string[]
    }){}
}

export type CmsChartsActions = CmsChartsLoaded
    | CmsChartsActiveChartsRequested
    | CmsChartsCopyChartRequested
    | CmsChartsCopyChart
    | CmsChartsUpdateChart
    | CmsChartsUpdateChartRequested
    | CmsChartsRequestChartAllListsUpdates
    | CmsChartsRequestChartRelatedListsUpdate
    | CmsChartsRequestData
    | CmsChartsDataLoaded
    | CmsChartsMultiDataLoaded
    | CmsChartsResetState
    | CmsChartsSetParamOnLoadList
    | CmsChartsSetUrlsAsRequested
    | CmsChartsRemoveUrlsFromRequested
    | CmsChartsPDFWithChartAsSVGDownloadRequested
    | CmsChartsPDFWithChartAsPNGDownloadRequested
    | CmsChartsDownloadDataInXLSXRequested
    | CmsChartsDownloadDataInCSVRequested
    | CmsChartsDownloadDataInJSONRequested
    | CmsChartsChangeTitle
    | CmsChartsSetLoadingState
    | CmsChartsSetLoadingStates
    | CmsChartsSeriesAndCategoriesSetUrls
    | CmsChartsSeriesAndCategoriesUpdates
    | CmsChartsRemoveParamsAndSeries
    | CmsChartsRemoveParamsAndSeriesByIds;
