import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import {Store} from '@ngrx/store';
import {filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {
    ARRequestComplete,
    ARSetItem, ARSetStatus, AsyncRequestsActionTypes
} from '@features/general/async_requests/async-requests.actions';
import {
    selectARRequestAllItemsComplete, selectARRequestStatusIsComplete
} from '@features/general/async_requests/async-requests.selectors';
import {AsyncRequestStatus} from '@features/general/async_requests/async-requests.entity';

@Injectable()
export class AsyncRequestsEffects {


    /**
     * Set status as complete if:
     *  1 - all item results are complete and
     *  2 - request status is still registered as incomplete
     */
    changeItem = createEffect(() =>this.actions$
        .pipe(
            ofType<ARSetItem>(AsyncRequestsActionTypes.ARSetItem),
            filter((action) => action.payload.result !== null),
            map((action) => action.payload.request_id),
            switchMap((id) => of(id)
                .pipe(
                    withLatestFrom(this.store.select(selectARRequestAllItemsComplete(id))),
                    filter(([, isComplete]) => isComplete),
                    withLatestFrom(this.store.select(selectARRequestStatusIsComplete(id))),
                    filter(([, isComplete]) => !isComplete),
                    map(() => new ARSetStatus({ request_id: id, status: AsyncRequestStatus.complete }))
                ))
        ));

    requestIsComplete = createEffect(() =>this.actions$
        .pipe(
            ofType<ARSetStatus>(AsyncRequestsActionTypes.ARSetStatus),
            filter((action) => action.payload.status === AsyncRequestStatus.complete),
            map((action) => new ARRequestComplete({ id: action.payload.request_id }))
        ));


    constructor(private actions$: Actions, private store: Store<any>) {}
}
