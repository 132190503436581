import {IQueueItem} from '@shared/interfaces';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {
    CoinQueueActions,
    CoinQueueActionTypes
} from "@features/composite-indicator/state/coin-queue/coin-queue.actions";

export interface CoinQueueState extends EntityState<IQueueItem>{}

export const adapter: EntityAdapter<IQueueItem> = createEntityAdapter<IQueueItem>();

const initialCoinQueueState: CoinQueueState = adapter.getInitialState();

export function coinQueueReducer(state = initialCoinQueueState , action: CoinQueueActions): CoinQueueState {

    switch (action.type) {
        case CoinQueueActionTypes.CoinAddToQueue:
            return adapter.addMany(action.payload, state);
        case CoinQueueActionTypes.CoinRemoveFromQueue:
            return adapter.removeMany(action.payload.ids, state);
        default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
