import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IVariable } from '@shared/interfaces';
import { VariablesActions, VariablesActionTypes } from './variables.actions';

export interface VariablesState extends EntityState<IVariable> {}

export const adapter: EntityAdapter<IVariable> =
    createEntityAdapter<IVariable>({});

export const initialVariablesState: VariablesState = adapter.getInitialState({});

export function variablesReducer(state = initialVariablesState , action: VariablesActions): VariablesState {

    switch (action.type) {
    case VariablesActionTypes.VariablesLoaded:
        // adapter.removeAll(state);

        return adapter.setAll(action.payload.variables, state);
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
