import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { CompositeService } from '../../composite.service';
import {
    UnitEditionsActionTypes, UnitEditionsByUnitRequested,
    UnitEditionsByUrlRequested, UnitEditionsLoaded
} from "@features/composite-indicator/state/unit-editions/unit-editions.actions";
import {selectUnitEditionsCodeIsLoaded} from "@features/composite-indicator/state/unit-editions/unit-editions.selectors";

@Injectable()
export class UnitEditionsEffects {

    loadUEditionsByUrl$ = createEffect(() =>this.actions$
        .pipe(
            ofType<UnitEditionsByUrlRequested>(UnitEditionsActionTypes.UnitEditionsByUrlRequested),
            map(request => request.payload.url),
            mergeMap(url => of(url)
                .pipe(
                    withLatestFrom(this.store.select(selectUnitEditionsCodeIsLoaded(url))),
                    filter(([action, loaded]) => !loaded),
                    mergeMap(() => this.compositeService.getAllEditionUnitsByUrl(url)),
                    map( data => new UnitEditionsLoaded( { data, loaded: url }))
                ))
        ));

    loadUEditionsByUnit$ = createEffect(() =>this.actions$
        .pipe(
            ofType<UnitEditionsByUnitRequested>(UnitEditionsActionTypes.UnitEditionsByUnitRequested),
            map(request => request.payload.code),
            mergeMap(code => of(code)
                .pipe(
                    withLatestFrom(this.store.select(selectUnitEditionsCodeIsLoaded(code))),
                    filter(([action, loaded]) => !loaded),
                    mergeMap(() => this.compositeService.getAllEditionUnitsByUnit(code)),
                    map( data => new UnitEditionsLoaded( { data, loaded: code }))
                ))
        ));

    constructor(private actions$: Actions, private compositeService: CompositeService,
                private store: Store<any>) {}
}
