import {createSelector} from "@ngrx/store";

import {selectGeneralState} from "@features/general/general.selectors";
import * as fromCmsCharts from '@features/general/charts/state/cms-charts.reducer';

export const selectCmsChartsHighlightsState = createSelector(
    selectGeneralState,
    state => state.charts.highlights
);

export const selectAllCmsChartsHighlights = createSelector(
    selectCmsChartsHighlightsState,
    fromCmsCharts.selectAllChartsHighlights
);

export const selectAllCmsChartsHighlightsEntities = createSelector(
    selectCmsChartsHighlightsState,
    fromCmsCharts.selectEntitiesChartsHighlights
);

export const selectCmsChartsGetChartHighlights = (chart_id: string) => createSelector(
    selectAllCmsChartsHighlights,
    (highlights) => highlights.filter((highlight) => highlight.chart_id === chart_id)
);

export const selectCmsChartsGetChartsHighlights = (chart_ids: string[]) => createSelector(
    selectAllCmsChartsHighlights,
    (highlights) => highlights.filter((highlight) => chart_ids.includes(highlight.chart_id))
);

export const selectCmsChartsHighlightsByUrls = (urls: string[]) => createSelector(
    selectAllCmsChartsHighlights,
    (highlights) => highlights.filter((h) => urls.includes(h.list_id))
);
