import {listAdapter} from '@features/general/lists/state/list.entity';
import {ListsActions, ListsActionTypes} from '@features/general/lists/state/lists.actions';
import {EntityState} from '@ngrx/entity';
import {IList, ListLoadingStates} from '@features/general/lists/structure';

export interface ListsState extends EntityState<IList>{
    current_page: string;
    current_feature: string;
    requested: {[_: string]: any};
}

export const initialListReducerState: ListsState = listAdapter.getInitialState({
    current_page: null,
    current_feature: null,
    requested: {}
});

const addRequested = (state: {[_: string]: any}, elems: string[]): string[] => {
    const newState = JSON.parse(JSON.stringify(state));
    for (const elem of elems) {
        newState[elem] = elem;
    }
    return newState;
};

const removeRequested = (state: {[_: string]: any}, elems: string[]): string[] => {
    const newState = JSON.parse(JSON.stringify(state));
    for (const elem of elems) {
        delete newState[elem];
    }
    return newState;
};

export function listsReducer(state = initialListReducerState, action: ListsActions): ListsState {
    let lists: IList[];

    switch (action.type) {
        case ListsActionTypes.ListsLoaded:
            lists = action.payload.lists.map((list) => ({ ...list, time_stamp: Date.now(), status: !!list.values.length && ListLoadingStates.loaded || ListLoadingStates.nodata }));
            return listAdapter.addMany(lists, state);
        case ListsActionTypes.ListsReset:
            return listAdapter.removeAll(state);
        case ListsActionTypes.ListsRemove:
            return listAdapter.removeMany(action.payload.keys, state);
        case ListsActionTypes.ListsSetFeatureScope:
            return { ...state, current_feature: action.payload.feature, current_page: action.payload.page };
        case ListsActionTypes.ListsSetPageScope:
            return { ...state, current_page: action.payload.page };
        case ListsActionTypes.ListsSetUrlAsRequested:
            return { ...state, requested: addRequested(state.requested, [action.payload.url])};
        case ListsActionTypes.ListsRemoveUrlsFromRequested:
            return { ...state, requested: removeRequested(state.requested, action.payload.urls) };
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds
} = listAdapter.getSelectors();
