import {Action} from '@ngrx/store';
import {ListsCmsSlugs} from '@features/general/lists/structure';
import {CmsChartsLoadingState} from "@features/general/charts/structure";
import {ICmsChartFilterElem} from "@features/general/charts/state/filters/cms-chart-filters.entity";

export enum CmsChartsFiltersActionTypes {
    CmsChartsSetFilterUrl = '[CmsChartsFilters][CmsChartsSetFilterUrl] Set a filter list url',
    CmsChartsSetFiltersUrls = '[CmsChartsFilters][CmsChartsSetFiltersUrls] Set the lists urls for multiple filters',
    CmsChartsUpdateFilters = '[CmsChartsFilters][CmsCHartsUpdateFilters] Updates multiple filters',
    CmsChartsUpdateFiltersUrls = '[CmsChartsFilters][CmsChartsUpdateFiltersUrls] Updates multiple filters including urls'
}

export class CmsChartsSetFilterUrl implements Action {
    readonly type = CmsChartsFiltersActionTypes.CmsChartsSetFilterUrl;
    constructor(public payload: {chart_id: string, param: string, url: string, status?: CmsChartsLoadingState }) {}
}

export class CmsChartsSetFiltersUrls implements Action {
    readonly type = CmsChartsFiltersActionTypes.CmsChartsSetFiltersUrls;
    constructor(public payload: {chart_id: string, param: string, slug: ListsCmsSlugs, url: string, status?: CmsChartsLoadingState }[]) {}
}

export class CmsChartsUpdateFilters implements Action {
    readonly type = CmsChartsFiltersActionTypes.CmsChartsUpdateFilters;
    constructor(public payload: {id: string, changes: Partial<ICmsChartFilterElem>}[]){}
}

export class CmsChartsUpdateFiltersUrls implements Action {
    readonly type = CmsChartsFiltersActionTypes.CmsChartsUpdateFiltersUrls;
    constructor(public payload: {id: string, changes: Partial<ICmsChartFilterElem>}[]){}
}

export type CmsChartsFiltersActions = CmsChartsSetFilterUrl
    | CmsChartsSetFiltersUrls
    | CmsChartsUpdateFilters
    | CmsChartsUpdateFiltersUrls;
