import { BreadcrumbsStateActions, BreadcrumbsStateActionTypes } from '@shared/breadcrumbs/breadcrumbs.actions';

export interface BreadcrumbsState {
    customLastTitle: string;
}

const initialState: BreadcrumbsState = {
    customLastTitle: null,
};

export function breadcrumbsStateReducer(state: BreadcrumbsState = initialState, action: BreadcrumbsStateActions): BreadcrumbsState {
    switch (action.type) {
    case BreadcrumbsStateActionTypes.SET_CUSTOM_LAST_TITLE:
        return { ...state, customLastTitle: action.payload.title };
    case BreadcrumbsStateActionTypes.RESET_CUSTOM_LAST_TITLE:
        return { ...state, customLastTitle: null };
    default:
        return state;
    }

}
