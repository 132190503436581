import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ICountry } from '@shared/interfaces';
import * as fromUnits from './units.reducers';

import {ArrayHelper, RouterHelper} from '@shared/helpers';
import { CoinState } from '../index';

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectUnitsState = createSelector(
    selectCoinState,
    (state) => state.units,
);

export const selectUnitsUrlsLoaded = createSelector(
    selectUnitsState,
    (state) => state.urlsLoaded,
);

export const selectAllUnits = createSelector(
    selectUnitsState,
    fromUnits.selectAll,
);

export const selectAllUnitsFlattened = createSelector(
    selectUnitsState,
    fromUnits.selectEntities,
);

export const selectAllUnitsFlattenedByCode = createSelector(
    selectAllUnits,
    (units) => ArrayHelper.flatten(units, 'code'),
);


export const selectUnitAllTypesLoaded = createSelector(
    selectUnitsState,
    (state) => state.typesLoaded,
);

export const selectUnitsUrlIsLoaded = (url: string) => createSelector(
    selectUnitsUrlsLoaded,
    (urls) => urls.includes(url),
);

export const selectAllUnitsWithIndices = createSelector(
    selectAllUnits,
    (units) => units.filter((u) => u.hasIndices),
);

export const selectUnitIsLoaded = (code: string) => createSelector(
    selectAllUnitsFlattenedByCode,
    (units) => !!units[code] || false,
);

export const selectUnitByCode = (code: string) => createSelector(
    selectAllUnitsFlattenedByCode,
    (units) => units[code] || null,
);

export const selectUnitById = (id: number) => createSelector(
    selectAllUnitsFlattened,
    (units) => units[id] || null,
);

// export const selectCountryByContinent = (continentCode: string) => createSelector(
//     selectAllUnits,
//     (units) => (units as ICountry[]).filter((unit) => !!unit.continent_code && unit.continent_code === continentCode),
// );
//
// export const selectUnitByName = (name: string) => createSelector(
//     selectAllUnits,
//     (units) => {
//         const normalized_name = RouterHelper.slugify(name);
//         return units.find((u) => RouterHelper.slugify(name) === normalized_name);
//     },
// );
