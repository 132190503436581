import { RouterHelper } from '@shared/helpers';

export enum C3RoutesList {
    ABOUT = 'about',
    COUNTRIES_AND_CITIES = 'countries-and-cities',
    CITIES = 'cities',
    CREATE_YOUR_OWN_MONITOR = 'create-your-monitor',
    SCENARIOS = 'scenarios',
    GET_INVOLVED = 'get-involved',
    DOCS_AND_DATA = 'docs-and-data',
    PERFORMANCE_MAP = 'performance-map',
    ADD_YOUR_CITY = 'add-your-city',
    MY_CITY = 'my-city',
    POLICIES = 'policies',
    MAINTENANCE = 'maintenance',
}

export class C3Routes extends RouterHelper {
    static routesClass = C3Routes;
    static MAIN_URL = 'cultural-creative-cities-monitor';
    static CMS_SITE = 'c3';
    static routes = C3RoutesList;
}
