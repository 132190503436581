import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import {
    AllUnitsByTypeRequested, UnitByCodeRequested,
    UnitsActionTypes, UnitsByUrlLoaded,
    UnitsByUrlRequested,
    UnitsLoaded, UnitsUpdated,
} from './units.actions';
import { UnitsService } from './units.service';
import {
    selectUnitAllTypesLoaded,
    selectUnitIsLoaded, selectUnitsUrlIsLoaded
} from "@features/composite-indicator/state/units/units.selectors";

@Injectable()
export class UnitsEffects {

    loadComponentsByUrl$ = createEffect(() =>this.actions$
        .pipe(
            ofType<UnitsByUrlRequested>(UnitsActionTypes.UnitsByUrlRequested),
            map((request) => request.payload.url),
            mergeMap(url => of(url)
                .pipe(
                    withLatestFrom(this.store.select(selectUnitsUrlIsLoaded(url))),
                    filter(([action, loaded]) => !loaded),
                    mergeMap(() => this.unitsService.getUnitsByUrl(url)),
                    map( (units) => new UnitsByUrlLoaded( { units, url })),
                ))
        ));

    loadUnitsByType$ = createEffect(() =>this.actions$
        .pipe(
            ofType<AllUnitsByTypeRequested>(UnitsActionTypes.AllUnitsByTypeRequested),
            map(request => request.payload.type),
            withLatestFrom(this.store.select(selectUnitAllTypesLoaded)),
            filter(([type, typesLoaded]) => !typesLoaded.includes(type)),
            mergeMap(([type, filter]) => of(type)
                .pipe(
                    mergeMap(type => this.unitsService.getUnitsByType(type)),
                    map((answer) => new UnitsLoaded({ units: answer.data, url: answer.url, type }))
                )
            )
        ));

    loadUnitsWithIndicesByType$ = createEffect(() =>this.actions$
        .pipe(
            ofType<AllUnitsByTypeRequested>(UnitsActionTypes.AllUnitsWithIndicesByTypeRequested),
            map(request => request.payload.type),
            withLatestFrom(this.store.select(selectUnitAllTypesLoaded)),
            filter(([type, typesLoaded]) => !typesLoaded.includes(type)),
            mergeMap(([type, filter]) => of(type)
                .pipe(
                    mergeMap(type => this.unitsService.getUnitsWithIndicesByType(type)),
                    map((answer) => new UnitsUpdated({ units: answer.data.map(u => ({...u, hasIndices: true})), type }))
                )
            )
        ));

    unitRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<UnitByCodeRequested>(UnitsActionTypes.UnitByCodeRequested),
            map(request => request.payload.code),
            mergeMap((code) => of(code)
                .pipe(
                    withLatestFrom(this.store.select(selectUnitIsLoaded(code))),
                    filter(([code, loaded]) => !loaded),
                    mergeMap(() => this.unitsService.getUnitByCode(code)),
                    map((unit) => new UnitsLoaded({ units: [unit] }))
                )
            )
        ));

    constructor(private actions$: Actions, private unitsService: UnitsService,
                private store: Store<any>) {}
}
