
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ScreenState } from '../interfaces';

import * as fromScreen from './screen.reducers';

export const selectScreenState = createFeatureSelector<ScreenState>('screen');

export const isMobile = createSelector(
    selectScreenState,
    (screenState) => screenState.mobile,
);

export const isTablet = createSelector(
    selectScreenState,
    (screenState) => screenState.tablet,
);

export const isDesktop = createSelector(
    selectScreenState,
    (screenState) => screenState.desktop,
);

export const selectScreenSize = createSelector(
    selectScreenState,
    (screenState) => screenState.mobile ? 'mobile' : (screenState.tablet ? 'tablet' : 'desktop'),
);

export const selectScreenSizeDual = createSelector(
    selectScreenState,
    (state) => state.desktop ? 'desktop' : 'mobile',
);
