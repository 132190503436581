import { IFile } from '@shared/interfaces';

export class FileHelpers {

    static domainsWithCorsProtection: string[] = [
        'ec.europa.eu'
    ];

    static stringToArrayBuffer(s): ArrayBuffer {
        const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
        const view = new Uint8Array(buf);  // create uint8array as viewer
        for (let i = 0; i < s.length; i++) { view[i] = s.charCodeAt(i) & 0xFF; } // convert to octet
        return buf;
    }

    static triggerBlobBasedFileDownload(blob: Blob, name: string, ext?: string): void {
        this.triggerFileDownload(window.URL.createObjectURL(blob), name, ext);
    }

    static triggerStringBasedFileDownload(data: any, name: string, ext: string): void {
        this.triggerFileDownload('data:text/' + ext + ';charset=utf-8,' + encodeURIComponent(data), name, ext);
    }

    /**
     * @param event
     * @param {IFile} file
     * @param {boolean} isEclFileComponent
     */
    static onItemClick(event, file: IFile, isEclFileComponent: boolean = false): void {
        event.preventDefault();

        // This is needed so on default file download file layout only on download button click we trigger download
        if (isEclFileComponent && ! event.target.classList.contains('ecl-link__label')) {
            return;
        }

        const href = file.href;

        if (! href) {
            return;
        }
        const lastHrefPart = href.split('/').pop();

        // If the link is not a file link just treat it as generic link
        if (! lastHrefPart.includes('.')) {
            const a = document.createElement('a');

            a.href = href;
            a.target = '_blank';

            a.click();
            return;
        }

        // NOTE files with CORS can't be downloaded as BLOB and custom filename can't be applied
        let isCorsProtected = false;
        for (const domain of this.domainsWithCorsProtection) {
            if (href.includes(domain)) {
                isCorsProtected = true;
                break;
            }
        }

        const fileName = file.fileName ? file.fileName : lastHrefPart;

        // Trigger BLOB solution only if filename is different then we want and it's not CORS protected that we know
        if (fileName !== lastHrefPart && ! isCorsProtected) {
            try {
                window.URL = window.URL || window.webkitURL;
                const xhr = new XMLHttpRequest();

                xhr.open('GET', href, true);
                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const newFile = new Blob([xhr.response], { type: 'application/octet-stream' });

                    this.triggerBlobBasedFileDownload(newFile, fileName);
                };
                xhr.send();

                return;
            } catch (e) {
                // ...
            }
        }

        this.triggerFileDownload(href, fileName);
    }

    static triggerFileDownload(href: string, fileName?: string, ext?: string): void {
        const target = '_blank';

        try {
            const a = document.createElement('a');

            if (! fileName) {
                fileName = href.split('/').pop();
            }

            if (ext) {
                fileName += '.' + ext;
            }

            a.href = href;
            a.target = target;
            a.download = fileName;

            a.click();

            return;
        } catch (e) {
           // ...
        }

        window.open(href, target).focus();
    }
}
