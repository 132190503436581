
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CmsChartLoadStrategies } from '@features/general/charts/structure';

export enum CmsPageParameterType {
    page = 'page',
    other = 'other'
}

export enum CmsChartParamConversionType {
    original = 'name',
    converted = 'converted'
}

export interface ICmsChartParameter {
    chart_id: string;
    name: string;
    converted: string;
    type: CmsPageParameterType;
    subordinates: string[];
    dependencies: string[];
    value: any;
    isCopy?: boolean;
    default_value?: any;
    origin?: string; // Origin is the name of the source param (in case of copied params)
    strategy: CmsChartLoadStrategies;
    options?: any;
    loadStatusIgnore: boolean;
    temporaryIgnore?: boolean;
}

export function selectCmsChartParameterId(param: Partial<ICmsChartParameter>): string {
    return param.chart_id + '_' + param.name;
}

export const cmsChartParameterAdapter: EntityAdapter<ICmsChartParameter> =
    createEntityAdapter<ICmsChartParameter>({
        selectId: selectCmsChartParameterId
    });

export interface CmsChartParametersState extends EntityState<ICmsChartParameter> {}
