import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/reducers';

import { of } from 'rxjs';
import { concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { QueueActionTypes, QueueRemove, QueueResolve } from '@features/general/queue/state/queue.actions';
import { selectQueueByType } from '@features/general/queue/state/queue.selectors';
import {queueAdapter} from '@features/general/queue/state/queue.entity';

@Injectable()
export class QueueEffects {

    resolveQueue = createEffect(() =>this.actions$
        .pipe(
            ofType<QueueResolve>(QueueActionTypes.QueueResolve),
            map((request) => request.payload.relation),
            concatMap((relation) => of(relation)
                .pipe(
                    withLatestFrom(this.store.select(selectQueueByType(relation))),
                    filter(([, actions]) => !!actions.length),
                    concatMap(([, actions]) => {
                        const ids: any = actions.map((action) => queueAdapter.selectId(action));
                        return [
                            new QueueRemove({ ids }),
                            ...actions.map((action) => action.action),
                        ];
                    }),
                )),
        ));

    constructor(private actions$: Actions, private store: Store<AppState>) {}
}
