import { createSelector } from '@ngrx/store';
import * as fromCmsCharts from '@features/general/charts/state/cms-charts.reducer';

import { selectGeneralState } from '@features/general/general.selectors';
import { selectListsByIds, selectListsUrlsNotLoadedNorRequested } from '@features/general/lists/state/lists.selectors';
import { ListsCmsSlugs, ListsScopes } from '@features/general/lists/structure';
import { CmsChartLoadStrategies, CmsChartLoadStrategy } from '@features/general/charts/structure';
import CmsChartsFiltersHelper from '@features/general/charts/state/filters/cms-charts-filters.helper';
import {CmsChartsRemoveUrlsFromRequested} from "@features/general/charts/state/main/actions";
import {set} from "d3";

export const selectCmsChartsFiltersState = createSelector(
    selectGeneralState,
    state => state.charts.filters
);

export const selectAllCmsChartsFilters = createSelector(
    selectCmsChartsFiltersState,
    fromCmsCharts.selectAlLChartsFilters
);

export const selectAllCmsChartsEntitiesFilters = createSelector(
    selectCmsChartsFiltersState,
    fromCmsCharts.selectEntitiesChartsFilters
);

export const selectCmsChartsGetChartFilters = (chart_id: string) => createSelector(
    selectAllCmsChartsFilters,
    (filters) => filters.filter((f) => f.chart_id === chart_id)
);

export const selectCmsChartsGetChartsFilters = (chart_ids: string[]) => createSelector(
    selectAllCmsChartsFilters,
    (filters) => filters.filter((f) => chart_ids.includes(f.chart_id))
);

export const selectCmsChartsFilterByParams = (chart_id: string, params: string[]) => createSelector(
    selectCmsChartsGetChartFilters(chart_id),
    (filters) => filters.filter((filter) => params.includes(filter.param))
);

export const selectCmsChartsFiltersByUrls = (urls: string[]) => createSelector(
    selectAllCmsChartsFilters,
    (filters) => filters.filter((f) => urls.includes(f.list_id))
);

/**
 * Checks if the lists are available or were already requested
 * @param urls
 */
export const selectCmsChartsFiltersActionsOnSetFilterUrl = (requests: {chart_id: string, param: string, slug: ListsCmsSlugs, url: string, loadStrategy?: CmsChartLoadStrategy, loadStrategies: any}[]) => createSelector(
    selectListsUrlsNotLoadedNorRequested(requests.map((d) => d.url)),
    selectListsByIds(requests.map((d) => d.url)),
    (urlsNotLoaded, lists) => {
        const listRequests = requests.filter((request) => urlsNotLoaded.includes(request.url))
            .map((request) => CmsChartsFiltersHelper.convertListNotLoadedIntoListRquestedByUrl(ListsScopes.page, request.slug, request.url));

        const setParams = requests.filter((request) => !urlsNotLoaded.includes(request.url) && (request?.loadStrategy !== CmsChartLoadStrategies.none));

        const setParamsListsUrls = setParams.map((param) => param.url);

        const setParamsLists = lists.filter((list) => setParamsListsUrls.includes(list.id));

        const setParamsAction = CmsChartsFiltersHelper.convertListIntoSetParams(setParamsLists, setParams);

        let actions: any = [...listRequests, ...setParamsAction];

        if (!actions.length) {
            actions = [
                new CmsChartsRemoveUrlsFromRequested({ urls: requests.map((r) => r.url) })
            ];
        }

        return actions;
    }
);
