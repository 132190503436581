import {createSelector} from '@ngrx/store';

import * as fromLists from './lists.reducer';
import {selectGeneralState} from '@features/general/general.selectors';
import {ListLoadingStates, ListsScopes} from '@features/general/lists/structure';
import {selectRouterUrl, selectRouterUrlAsArray} from 'app/core/selectors';
import {selectListId} from '@features/general/lists/state/list.entity';
/**
 * State selectors
 */

/**
 * @lists
 * Get the state of the data hub lists
 */
export const selectListsState = createSelector(
    selectGeneralState,
    state => state.lists
);

/**
 * @lists
 * Get the urls requested
 */
export const selectListsUrlsRequested = createSelector(
    selectListsState,
    state => state.requested
);

/**
 * END State selectors
 */


export const selectAllLists = createSelector(
    selectListsState,
    fromLists.selectAll
);

export const selectAllListsEntities = createSelector(
    selectListsState,
    fromLists.selectEntities
);

export const selectAllListsIds = createSelector(
    selectListsState,
    fromLists.selectIds
);

export const selectListsByScope = (scope: ListsScopes) => createSelector(
    selectAllLists,
    (lists) => lists.filter((list) => list.scope === scope)
);

export const selectListKeysByScope = (scope: ListsScopes) => createSelector(
    selectListsByScope(scope),
    (lists) => lists.map((list) => list.id)
);

export const selectListsByScopeId = (scope_id: string) => createSelector(
    selectAllLists,
    (lists) => lists.filter((list) => list.scope_id === scope_id)
);

export const selectListKeysByScopeId = (scope_id: string) => createSelector(
    selectListsByScopeId(scope_id),
    (lists) => lists.map((list) => list.id)
);

export const selectListsByNotScopeId = (scope_id: string) => createSelector(
    selectAllLists,
    (lists) => lists.filter((list) => (list.scope_id !== scope_id && list.scope === ListsScopes.page)
    || (list.scope === ListsScopes.feature && !scope_id.includes(list.scope_id)))
);

export const selectListKeysByNotScopeId = (scope_id: string) => createSelector(
    selectListsByNotScopeId(scope_id),
    (lists) => lists.map((list) => list.id)
);

export const selectListsByPage = (scope_id: string) => createSelector(
    selectListsByScopeId,
    (lists) => lists
);

export const selectListById = (id: string) => createSelector(
    selectAllListsEntities,
    (entities) => entities[id] || null
);

export const selectListsCurrentFeature = createSelector(
    selectListsState,
    state => state.current_feature
);

export const selectListsCurrentPage = createSelector(
    selectListsState,
    state => state.current_page
);

export const selectListsCurrentScopeByType = (type: ListsScopes) => createSelector(
    selectListsCurrentFeature,
    selectListsCurrentPage,
    (feature, page) => {
        switch(type) {
            case ListsScopes.page:
                return page;
            case ListsScopes.feature:
                return feature;
            default:
                return '';
        }
    }
);

export const selectListsScopeFeatureIsChanged = createSelector(
    selectRouterUrlAsArray,
    selectListsCurrentFeature,
    (router_url, current_feature) => router_url[0] !== current_feature
);

export const selectListsScopePageIsChanged = createSelector(
    selectRouterUrl,
    selectListsCurrentPage,
    (router_url, current_page) => router_url !== current_page
);

export const selectListsResetFeatureKeys = createSelector(
    selectAllLists,
    (lists) => lists.filter((list) => list.scope !== ListsScopes.app).map(list => selectListId(list))
);

export const selectListsResetPageKeys = createSelector(
    selectAllLists,
    selectListsCurrentPage,
    (lists, page) => lists.filter((list) => list.scope === ListsScopes.page && list.scope_id !== page)
        .map(list => selectListId(list))
);

export const selectListsActivePage = createSelector(
    selectAllLists,
    (lists) => lists.filter((list) => list.scope === ListsScopes.page)
);

export const selectListsActivePageByListId = (id: string) => createSelector(
    selectListsActivePage,
    (lists) => lists.find((list) => list.id === id)
);

export const selectListsActiveFeature = createSelector(
    selectAllLists,
    (lists) => lists.filter((list) => list.scope === ListsScopes.feature)
);

export const selectListsActiveFeatureByListId = (id: string) => createSelector(
    selectListsActiveFeature,
    (lists, id) => lists.find((list) => list.id === id)
);

export const selectListsLoadingStateById = (id: string) => createSelector(
    selectAllListsIds,
    selectListsUrlsRequested,
    (ids: string[], requested) => {
        let state: ListLoadingStates;
        if (requested.hasOwnProperty(id)) {
            state = ListLoadingStates.requested;
        } else if (ids.includes(id)) {
            // add the error state and no data
            state = ListLoadingStates.loaded;
        } else {
            state = ListLoadingStates.none;
        }
        return state;
    }
);

export const selectListsLoadingStateByIds = (ids: string[]) => createSelector(
    selectAllListsEntities,
    selectListsUrlsRequested,
    (all, requested) => {

        return ids.map((id) => {
            let state: ListLoadingStates;
            if (requested.hasOwnProperty(id)) {
                state = ListLoadingStates.requested;
            } else if (all.hasOwnProperty(id)) {
                // add the error state and no data
                state = ListLoadingStates.loaded;
            } else {
                state = ListLoadingStates.none;
            }
            return {id, state};
        });
    }
);

export const selectListsUrlIsLoaded = (url: string) => createSelector(
    selectAllListsEntities,
    (entities) => entities.hasOwnProperty(url)
);

export const selectListsUrlsNotLoaded = (urls: string[]) => createSelector(
    selectAllListsEntities,
    (entities) => urls.filter((url) => !entities[url])
);

export const selectListUrlIsRequested = (url: string) => createSelector(
    selectListsUrlsRequested,
    (urls) => urls.hasOwnProperty(url)
)

export const selectListsUrlNotLoadedNorRequested = (url: string) => createSelector(
    selectListsUrlIsLoaded(url),
    selectListUrlIsRequested(url),
    (isLoaded, isRequested) => !isLoaded && !isRequested
);

export const selectListsUrlsNotLoadedNorRequested = (urls: string[]) => createSelector(
    selectAllListsEntities,
    selectListsUrlsRequested,
    (entities, requested) => urls.filter((url) => !entities.hasOwnProperty(url) && !requested.hasOwnProperty(url))
);

export const selectListsByIds = (ids: string[]) => createSelector(
    selectListsActivePage,
    (lists) => lists.filter((list) => ids.includes(list.id))
);

export const selectListsListBySlug = (slug: string) => createSelector(
    selectListsActivePage,
    (lists) => lists.find((list) => list.slug === slug)
);

export const selectListsRequestedByUrls = (requests: {type: ListsScopes, slug: string, url: string}[]) => createSelector(
    selectListsLoadingStateByIds(requests.map((d) => d.url)),
    (states) => {
        const result = {
            loaded: [],
            needed: []
        };

        for (const request of requests) {
            const state = states.find((d) => d.id === request.url);
            switch(state.state) {
                case ListLoadingStates.loaded:
                    result.loaded.push(request);
                    break;
                case ListLoadingStates.none:
                    result.needed.push(request);
                    break;
            }
        }

        return result;
    }
)
