import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import {
    VariablesActionTypes,
    VariablesByUrlRequested,
    VariablesLoaded,
} from './variables.actions';
import { VariablesService } from './variables.service';

@Injectable()
export class VariablesEffects {

    loadVariablesByUrl$ = createEffect(() =>this.actions$
        .pipe(
            ofType<VariablesByUrlRequested>(VariablesActionTypes.VariablesByUrlRequested),
            map((request) => request.payload.url),
            mergeMap((url) => this.variablesService.getVariablesByUrl(url)),
            map( (variables) => new VariablesLoaded( { variables })),
        ));

    constructor(private actions$: Actions, private variablesService: VariablesService,
                private store: Store<any>) {}
}
