import {
    CmsChartsParamsActions, CmsChartsParamsActionTypes
} from '@features/general/charts/state/params/cms-charts-params.actions';
import {
    CmsChartsFiltersActions, CmsChartsFiltersActionTypes
} from '@features/general/charts/state/filters/cms-chart-filters.actions';
import {CmsChartsActions, CmsChartsActionTypes} from '@features/general/charts/state/main/actions/cms-charts.actions';
import {
    CmsChartsSeriesActions, CmsChartsSeriesActionTypes
} from '@features/general/charts/state/series/cms-charts-series.actions';
import {
    CmsChartsCategoriesActions, CmsChartsCategoriesActionTypes
} from '@features/general/charts/state/categories/cms-charts-categories.actions';
import {
    CmsChartsTooltipActions, CmsChartsTooltipActionTypes
} from '@features/general/charts/state/tooltip/cms-chart-tooltip.actions';
import { CmsChartsGeoActions } from '@features/general/charts/state/geo/cms-charts-geo.actions';
import {
    CmsChartsHighlightsActions,
    CmsChartsHighlightsActionTypes
} from "@features/general/charts/state/highlights/cms-charts-highlights.actions";

export * from '../../params/cms-charts-params.actions';
export * from '../../filters/cms-chart-filters.actions';
export * from './cms-charts.actions';
export * from '../../series/cms-charts-series.actions';
export * from '../../categories/cms-charts-categories.actions';
export * from '../../tooltip/cms-chart-tooltip.actions';

export const CmsChartsFullActionTypes = {
    ...CmsChartsActionTypes,
    ...CmsChartsParamsActionTypes,
    ...CmsChartsFiltersActionTypes,
    ...CmsChartsSeriesActionTypes,
    ...CmsChartsCategoriesActionTypes,
    ...CmsChartsTooltipActionTypes,
    ... CmsChartsHighlightsActionTypes
};

export type CmsChartsFullActions = CmsChartsActions
    | CmsChartsParamsActions
    | CmsChartsFiltersActions
    | CmsChartsSeriesActions
    | CmsChartsCategoriesActions
    | CmsChartsTooltipActions
    | CmsChartsGeoActions
    | CmsChartsHighlightsActions;
