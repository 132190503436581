import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {IStatistic} from '@shared/interfaces';
import {StatisticsActions, StatisticsActionTypes} from './statistics.actions';

export interface StatisticsState extends EntityState<IStatistic> {
    loadedUrls: string[];
}

export const adapter: EntityAdapter<IStatistic> =
    createEntityAdapter<IStatistic>({});

export const initialStatisticsState: StatisticsState = adapter.getInitialState({
    loadedUrls: []
});

export function statisticsReducer(state = initialStatisticsState , action: StatisticsActions): StatisticsState {

    switch (action.type) {
    case StatisticsActionTypes.StatisticsLoaded:
        const urls = state.loadedUrls.filter(url => url !== action.payload.url);
        return adapter.addMany(action.payload.statistics, {...state, loadedUrls: [...urls, action.payload.url] });
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
