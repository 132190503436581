import {
    ICmsChart,
    ICmsChartParams,
    ICmsChartsParamsRelations,
    ICmsChartsParamsRules,
    ICmsParams
} from '@features/general/charts/structure/charts.interfaces';
import {
    CmsChartParamConversionType,
    CmsPageParameterType,
    ICmsChartParameter,
    selectCmsChartParameterId
} from '@features/general/charts/state/params/cms-chart-parameters.entity';
import {selectCmsChartId} from '@features/general/charts/state/main/entities/cms-chart.entity';
import {CmsChartLoadStrategies} from "@features/general/charts/structure";

export class CmsChartParametersHelper {

    static count = 0;

    static getParamDependencies = (param_name: string, relations: ICmsChartsParamsRelations): string[] => {
        const keys = Object.keys(relations || []);
        return keys.reduce((param_relations, key) => {
            if (relations[key].includes(param_name)) {
                param_relations.push(key);
            }
            return param_relations;
        }, []);
    }

    static paramConvertor = (chart_id: string, params: ICmsParams, rules: ICmsChartsParamsRules, type: CmsPageParameterType, relations: ICmsChartsParamsRelations, chartOptions?): ICmsChartParameter[] => {
        if (!params) {
            return [];
        }
        const loadStrategies = chartOptions?.loadStrategies || {};
        const loadStrategy = chartOptions?.loadStrategy || CmsChartLoadStrategies.first;
        const loadStatusIgnore = chartOptions?.loadStatusIgnore || [];

        const keys = Object.keys(params);
        return keys.map((key) => {
            const strategy = loadStrategies[key] || loadStrategy;
            const statusIgnore = loadStatusIgnore.includes(key);
            const options = (chartOptions?.params || {})[key] || {};

            return {
                chart_id,
                name: key,
                converted: rules[key] || key,
                type,
                subordinates: relations && relations[key] || [],
                dependencies: CmsChartParametersHelper.getParamDependencies(key, relations),
                value: params[key],
                default_value: params[key] || null,
                isCopy: false,
                origin: key,
                strategy,
                loadStatusIgnore: statusIgnore,
                options
            };
        });
    }

    static chartParamConvert = (chart_id: string, params: ICmsChartParams, chartOptions): ICmsChartParameter[] => {
        if (!params) {
            return [];
        }

        const pageParams = CmsChartParametersHelper.paramConvertor(chart_id, params.page, params.rules, CmsPageParameterType.page, params.relations, chartOptions);
        const otherParams = CmsChartParametersHelper.paramConvertor(chart_id, params.other, params.rules, CmsPageParameterType.other, params.relations, chartOptions);

        return [...pageParams, ...otherParams];
    }

    static chartsParamConvert = (charts: ICmsChart[]): ICmsChartParameter[] => {
        return charts.reduce((params, chart) => {
            const chart_id = selectCmsChartId(chart);
            const chart_params = CmsChartParametersHelper.chartParamConvert(chart_id, chart.params, chart?.options);
            params.push(...chart_params);
            return params;
        }, []);
    }

    static getIdFromChartAndName = (chart_id: string, name: string): string => {
        return selectCmsChartParameterId({ chart_id, name });
    }

    static convertToCmsParams = (parameters: ICmsChartParameter[], type: CmsChartParamConversionType): ICmsParams => {
        return parameters.reduce((params, current) => {
            const param = current[type];
            if (current.value !== null) {
                if (params.hasOwnProperty(param)) {
                    if (Array.isArray(params)) {
                        params[param].push(current.value);
                    } else {
                        params[param] = [params[param], current.value];
                    }
                } else {
                    params[param] = current.value;
                }
            }
            return params;
        }, {});
    }

    static cloneParameter = (parameter: ICmsChartParameter, newChartId: string): ICmsChartParameter => {
        return { ...parameter, chart_id: newChartId };
    }

    static cloneParameters = (parameters: ICmsChartParameter[], newChartId: string): ICmsChartParameter[] => {
        return parameters.map((parameter) => CmsChartParametersHelper.cloneParameter(parameter, newChartId));
    }

    static copyParam = (parameter: ICmsChartParameter, new_name = null, new_value = null): ICmsChartParameter => {
        const name = CmsChartParametersHelper.calcCopyParamName(parameter, new_name);
        return { ...parameter, name, value: new_value, isCopy: true };
    }

    static calcCopyParamName = (parameter: ICmsChartParameter, new_name: string = null): string => {
        CmsChartParametersHelper.count++;
        return new_name || (parameter.origin + '_' + CmsChartParametersHelper.count + '_' + Date.now());
    }

    static GetParamsSubordinates = (params: ICmsChartParameter[]): string[] => {
        const subordinates = params.reduce((subs, param) => {
            subs.push(...param.subordinates);
            return subs;
        }, []);
        return Array.from(new Set(subordinates));
    }

}
