import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { LevelsActionTypes, LevelsByUrlRequested, LevelsLoaded } from './levels.actions';
import { selectLevelsUrlIsLoaded } from './levels.selectors';
import { LevelsService } from './levels.service';

@Injectable()
export class LevelsEffects {

    loadLevelsByUrl$ = createEffect(() =>this.actions$
        .pipe(
            ofType<LevelsByUrlRequested>(LevelsActionTypes.LevelsByUrlRequested),
            map((request) => request.payload.url),
            mergeMap((url) => of(url)
                .pipe(
                    withLatestFrom(this.store.select(selectLevelsUrlIsLoaded(url))),
                    filter(([action, loaded]) => !loaded),
                    switchMap(() => this.levelsService.getLevelsByUrl(url)),
                    map( (levels) => new LevelsLoaded( { levels })),
                ),
            ),
        ));

    constructor(private actions$: Actions, private levelsService: LevelsService,
                private store: Store<any>) {}
}
