
import { queueAdapter, QueueState } from '@features/general/queue/state/queue.entity';
import { QueueActions, QueueActionTypes } from '@features/general/queue/state/queue.actions';


const initialQueueState: QueueState = queueAdapter.getInitialState();

export function queueReducer(state = initialQueueState , action: QueueActions): QueueState {

    switch (action.type) {
        case QueueActionTypes.QueueAdd:
            return queueAdapter.addMany(action.payload.items, state);
   //         return queueAdapter.addMany(action.payload.items.map((item, i) => item.id ? item : { ...item, id:
        //         Date.now() + i }), state);
        case QueueActionTypes.QueueRemove:
            return queueAdapter.removeMany(action.payload.ids, state);
        default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = queueAdapter.getSelectors();
