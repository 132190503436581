import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {ICategory, IDeveloper} from '@shared/interfaces';
import { DevelopersActions, DevelopersActionTypes } from './developers.actions';

export interface DevelopersState extends EntityState<IDeveloper> {
    allDevelopersLoaded: boolean;
}

export const adapter: EntityAdapter<IDeveloper> = createEntityAdapter<IDeveloper>();

export const initialDevelopersState: DevelopersState = adapter.getInitialState({
    allDevelopersLoaded: false,
});

export function developersReducer(state = initialDevelopersState , action: DevelopersActions): DevelopersState {

    switch (action.type) {
    case DevelopersActionTypes.DeveloperLoaded:
        return adapter.addOne(action.payload.developer, state);
    case DevelopersActionTypes.AllDevelopersLoaded:
        return adapter.setAll(action.payload.developers, { ...state, allDevelopersLoaded: true });
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
