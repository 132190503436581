import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICountriesList } from '@shared/interfaces';
import { CoinState } from '../index';

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectCountriesState = createSelector(
    selectCoinState,
    (state) => state.countries,
);

export const selectCountriesGroupedByIncomeLevelByComponentList = createSelector(
    selectCountriesState,
    (state) => state.countriesByIncomeLevelByComponent
);

export const selectCountriesGroupedByIncomeLevelByComponentId = (id: number) => createSelector(
    selectCountriesGroupedByIncomeLevelByComponentList,
    (list): ICountriesList[] => list ? list.filter((item: ICountriesList) =>
        item.ownerEntity === 'component' && item.ownerEntityId === id
    ) : [],
);
