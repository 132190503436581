import { Action } from '@ngrx/store';
import { GeoGiscoFileTypes, GeoLocalFiles } from '@features/geo/structure';

export enum GeoActionTypes {
    GeoDataRequested = '[GEO] Request geographic data',
    GeoDataLoaded = '[GEO] Data loaded',
    GeoDataGiscoRequested = '[GEO] Request data from gisco',
    GetDataRequestedByUrl = '[GEO] Request data by url',
    GeoLayersRequested = '[GEO] Request layers',
    GeoLayerTypeRequestedByUrl = '[GEO] Request layer type by url'
}

export class GeoDataRequested implements Action {
    readonly type = GeoActionTypes.GeoDataRequested;

    constructor(public payload: { type: GeoLocalFiles }) {}
}

export class GeoDataLoaded implements Action {
    readonly type = GeoActionTypes.GeoDataLoaded;

    constructor(public payload: { data: any, url: string }) {}
}

export class GeoDataGiscoRequested implements Action {
    readonly type = GeoActionTypes.GeoDataGiscoRequested;

    constructor(public payload: { type: GeoGiscoFileTypes }) {}
}

export class GeoDataRequestedByUrl implements Action {
    readonly type = GeoActionTypes.GetDataRequestedByUrl;

    constructor(public payload: { url: string }) {}
}

export class GeoLayersRequested implements Action {
    readonly type = GeoActionTypes.GeoLayersRequested;

    constructor(public payload: any[]) {}
}

export class GeoLayerTypeRequestedByUrl implements Action {
    readonly type = GeoActionTypes.GeoLayerTypeRequestedByUrl;

    constructor(public payload: { url: string }) {}
}

export type GeoActions = GeoDataRequested
    | GeoDataLoaded
    | GeoDataGiscoRequested
    | GeoDataRequestedByUrl
    | GeoLayersRequested
    | GeoLayerTypeRequestedByUrl;
