import {IComponent} from "@shared/interfaces";

export class ComponentHelpers {

    static filterTopLevelComponents(components: IComponent[]): IComponent[] {
        return components ? components.filter((c) => !c.parent_id) : [];
    }

    static prepareComponentChildComponentsList(components: IComponent[], parentComponent: IComponent): IComponent[] {
        return components ? components.filter((component) => component.parent_id === parentComponent.id) : [];
    }
}




