import { Action } from '@ngrx/store';
import { IEdition, IQueue } from '@shared/interfaces';

export enum EditionsActionTypes {
    EditionByUrlRequested = '[EDITIONS] Request Editions By Url',
    AllEditionsRequested = '[EDITIONS] Request All Editions for an Indice',
    EditionRequested = '[EDITIONS] Request One Edition for an Indice',
    LastEditionRequested = '[EDITIONS] Request the Last Edition for one Indice',
    AllEditionsLoaded = '[EDITIONS] AllEditions Loaded',
    EditionsLoaded = '[EDITIONS] Editions Loaded',
}

export class EditionsLoaded implements Action {
    readonly type = EditionsActionTypes.EditionsLoaded;

    constructor(public payload: { editions: IEdition[] }) {}
}

export class EditionRequested implements Action {
    readonly type = EditionsActionTypes.EditionRequested;

    constructor(public payload: { indice: string, edition: number }) {}
}

export class AllEditionsLoaded implements Action {
    readonly type = EditionsActionTypes.AllEditionsLoaded;

    constructor(public payload: { indice: string }) {}
}

export class AllEditionsRequested implements Action {
    readonly type = EditionsActionTypes.AllEditionsRequested;

    constructor(public payload: { indice: string }) {}
}

export class LastEditionRequested implements Action {
    readonly type = EditionsActionTypes.LastEditionRequested;

    constructor(public payload: { indice: string }) {}
}

export class EditionByUrlRequested implements Action {
    readonly type = EditionsActionTypes.EditionByUrlRequested;

    constructor(public payload: { url: string }) {}
}

export type EditionsActions =
    EditionByUrlRequested
    | EditionRequested
    | AllEditionsRequested
    | AllEditionsLoaded
    | LastEditionRequested
    | EditionsLoaded;
