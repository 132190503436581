import { Injectable } from '@angular/core';
import { GeneralService } from '@features/general/general.service';
import {IList, ListsCmsHelper, ListsCmsSlugs} from '@features/general/lists/structure';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import {IAnswer} from '@shared/interfaces';
import {ICmsParams} from '@features/general/charts/structure';

@Injectable()
export class ListsService extends GeneralService {

    base_cms_lists_url = this.getApiUrl();

    getListByUrl = (url: string): Observable<IAnswer<IList>> => {
        return this.getFullAnswerByUrl<IList>(url);
    };

    getListCmsBySlug = (slug: ListsCmsSlugs, params: ICmsParams): Observable<IAnswer<IList>> => {

        const url = this.getListCmsUrlBySlug(slug, params);

        return url && this.getListByUrl(url) || of(null);
    };

    getListById = (list_id: string): Observable<IAnswer<IList>> => {
        const url = this.base_cms_lists_url + list_id;
        return url && this.getListByUrl(url) || of(null);
    };

    getListCmsUrlBySlug = (slug: ListsCmsSlugs, params: ICmsParams): string => {

        const listUrl = ListsCmsHelper.getPartialListCmsUrlBySlug(slug, params);

        return listUrl ? this.base_cms_lists_url + listUrl : null;
    };
}
