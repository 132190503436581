import { Injectable } from '@angular/core';
import { ICountriesList } from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';

@Injectable()
export class CountriesService extends SharedService {
    private countriesUrl = this.getApiUrl() + 'countries';

    getCountriesGroupedByIncomeLevelByComponentId(componentId: number): Observable<any> {
        return this.getByUrl(this.countriesUrl + '/top/income-level/' + componentId);
    }

    prepareCountriesGroupedByIncomeLevelByComponentResponseAsCountriesList(componentId: number, response): ICountriesList[] {
        const list = [];

        if (response) {
            for (const key in response) {
                if (!response.hasOwnProperty(key)) {
                    continue;
                }
                const item = response[key];
                list.push({
                    key: key.toString(),
                    countries: item,
                    ownerEntity: 'component',
                    ownerEntityId: componentId,
                } as ICountriesList);
            }
        }
        return list;
    }

}
