import { ItemTypesCOINAPI } from '@shared/enums';
import { RouterHelper } from '@shared/helpers';
import { ItemHelpers, ItemRouteType } from '@shared/helpers/item.helpers';

export enum DataHubRoutesList {
    HOME_PAGE = '',
    EXPLORER = 'explorer',
    SEARCH = 'search',
    TYPE_ITEM_LIST = 'type-item-list',
    INDICES = 'indices',
    UNITS = 'units',
    COUNTRIES_AND_TERRITORIES = 'countries-and-territories',
    CITIES = 'cities',
    REGIONS = 'regions',
    CATEGORIES = 'categories',
    DEVELOPERS = 'developers',
    PROFILE = 'profile',
    SCOREBOARDS = 'scoreboards',
    INDICES_AND_SCOREBOARDS = 'indices-and-scoreboards',
    MAINTENANCE = 'maintenance',
    TOPICS = 'topics',
    STORIES = 'stories',
    STORY = 'story',
    FAQ = 'faq',
    ABOUT = 'about',
    DATA_POLICY = 'data-policy',
    SYNERGIES = 'synergies',
}

export class DataHubRoutes extends RouterHelper {
    static routesClass = DataHubRoutes;
    static MAIN_URL = 'explorer';
    static CMS_SITE = 'datahub';
    static routes = DataHubRoutesList;

    static getCoinAPIUnitTypesToRoutesEquivalents() {
        const obj = {};
        obj[ItemTypesCOINAPI.INDICE] = DataHubRoutesList.INDICES;
        obj[ItemTypesCOINAPI.UNIT] = DataHubRoutesList.COUNTRIES_AND_TERRITORIES;
        // obj[ItemTypesCOINAPI.UNIT] = DataHubRoutesList.UNITS;
        obj[ItemTypesCOINAPI.CATEGORY] = DataHubRoutesList.CATEGORIES;
        obj[ItemTypesCOINAPI.CATEGORIES] = DataHubRoutesList.CATEGORIES;
        obj[ItemTypesCOINAPI.DEVELOPER] = DataHubRoutesList.DEVELOPERS;
        obj[ItemTypesCOINAPI.COUNTRY] = DataHubRoutesList.COUNTRIES_AND_TERRITORIES;
        obj[ItemTypesCOINAPI.CITY] = DataHubRoutesList.CITIES;
        obj[ItemTypesCOINAPI.REGION] = DataHubRoutesList.REGIONS;
        obj[ItemTypesCOINAPI.SCOREBOARD] = DataHubRoutesList.SCOREBOARDS;
        obj[ItemTypesCOINAPI.INDICES_AND_SCOREBOARDS] = DataHubRoutesList.INDICES_AND_SCOREBOARDS;
        return obj;
    }

    static generateRouterLinkForItemType(item: ItemRouteType|any, itemType: ItemTypesCOINAPI) {
        const routeByItemTypeEquivalents = DataHubRoutes.getCoinAPIUnitTypesToRoutesEquivalents();
        const route = routeByItemTypeEquivalents[itemType];
        const params = ItemHelpers.generateRouterLinkParams(itemType, item);
        return route ? DataHubRoutes.generateRouterLink(DataHubRoutes.routes.EXPLORER, [route].concat(params)) : [];
    }
}
