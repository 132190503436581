import { createSelector } from '@ngrx/store';
import { selectGeneralState } from '@features/general/general.selectors';
import * as fromQueue from './queue.reducer';

export const selectQueueState = createSelector(
    selectGeneralState,
    state => state.queue
);

export const selectAllQueue = createSelector(
    selectQueueState,
    fromQueue.selectAll,
);

export const selectAllQueueEntities = createSelector(
    selectQueueState,
    fromQueue.selectEntities,
);

export const selectQueueByType = (type: string) => createSelector(
    selectAllQueue,
    (queue) => queue.filter((q) => q.relation === type),
);
