import {ActionReducerMap} from '@ngrx/store';

import * as fromLists from './lists/state/lists.reducer';
import {ListsState} from '@features/general/lists/state/lists.reducer';
import {ListsEffects} from '@features/general/lists/state/lists.effects';

import * as fromCms from './cms/state/cms.reducer';
import {CmsState} from '@features/general/cms/state/cms.entity';
import {CmsEffects} from '@features/general/cms/state/cms.effects';

import * as fromQueue from './queue/state/queue.reducer';
import {QueueState} from '@features/general/queue/state/queue.entity';
import {QueueEffects} from '@features/general/queue/state/queue.effects';

import * as fromCmsCharts from './charts/state/cms-charts.reducer';
import {CmsChartsState} from '@features/general/charts/state/cms-charts.reducer';
import {CmsChartsEffects} from '@features/general/charts/state/cms-charts.effects';

import * as fromHead from './head/state/head.reducer';
import {HeadEffects} from "@features/general/head/state/head.effects";
import {HeadState} from "./head/state/head.reducer";
import {AsyncRequestFullState} from '@features/general/async_requests/async-requests.reducer';
import * as fromAsyncRequest from './async_requests/async-requests.reducer';
import {AsyncRequestsEffects} from '@features/general/async_requests/async-requests.effects';


export interface GeneralState {
    lists: ListsState,
    cms: CmsState,
    queue: QueueState,
    charts: CmsChartsState,
    head: HeadState,
    asyncRequests: AsyncRequestFullState
}

export const generalReducer: ActionReducerMap<GeneralState> = {
    lists: fromLists.listsReducer,
    cms: fromCms.cmsReducer,
    queue: fromQueue.queueReducer,
    charts: fromCmsCharts.cmsChartsReducer,
    head: fromHead.headReducer,
    asyncRequests: fromAsyncRequest.asyncRequestsReducer
};

export const GeneralEffects = [
    ListsEffects,
    CmsEffects,
    QueueEffects,
    CmsChartsEffects,
    HeadEffects,
    AsyncRequestsEffects,
];
