
import { ActionReducerMap } from '@ngrx/store';
import { ComponentVariablesEffects } from '@features/composite-indicator/state/component-variables/component-variables.effects';
import { ScalesEffects } from '@features/composite-indicator/state/scales/scales.effects';
import { CategoriesEffects } from './categories/categories.effects';
import * as fromCategories from './categories/categories.reducers';
import { CountriesEffects } from './countries/countries.effects';
import * as fromCountries from './countries/countries.reducers';
import { CategoryGroupsEffects } from './category-groups/category-groups.effects';
import * as fromCategoryGroups from './category-groups/category-groups.reducers';
import { ClassesEffects } from './classes/classes.effects';
import * as fromClasses from './classes/classes.reducers';
import { CoinQueueEffects } from './coin-queue/coin-queue.effects';
import * as fromQueue from './coin-queue/coin-queue.reducers';
import { ColorsEffects } from './colors/colors.effects';
import * as fromColors from './colors/colors.reducers';
import * as fromComponents from './components/components.reducers';
import * as fromComponentVariables from './component-variables/component-variables.reducers';
import { DataTypesEffects } from './data-types/data-types.effects';
import * as fromDataTypes from './data-types/data-types.reducers';
import { DataEffects } from './data/data.effects';
import * as fromData from './data/data.reducers';
import { EditionsEffects } from './editions/editions.effects';
import * as fromEditions from './editions/editions.reducers';
import { GroupsEffects } from './groups/groups.effects';
import * as fromGroups from './groups/groups.reducers';
import { IndicesEffects } from './indices/indices.effects';
import * as fromIndices from './indices/indices.reducers';
import { LevelsEffects } from './levels/levels.effects';
import * as fromLevels from './levels/levels.reducers';
import { ResourcesEffects } from './resources/resources.effects';
import * as fromResources from './resources/resources.reducers';
import * as fromScales from './scales/scales.reducers';
import { StatisticsEffects } from './statistics/statistics.effects';
import * as fromStatistics from './statistics/statistics.reducers';
import { CompositeIndicatorsEffects } from './status/composite-indicators.effects';
import * as fromStatus from './status/composite-indicators.reducer';
import { UnitEditionsEffects } from './unit-editions/unit-editions.effects';
import * as fromUnitEditions from './unit-editions/unit-editions.reducers';
import { UnitGroupsEffects } from './unit-groups/unit-groups.effects';
import * as fromUnitGroups from './unit-groups/unit-groups.reducers';
import { UnitsEffects } from './units/units.effects';
import * as fromUnits from './units/units.reducers';
import { VariableGroupsEffects } from './variable-groups/variable-groups.effects';
import * as fromVariableGroups from './variable-groups/variable-groups.reducers';
import { VariablesEffects } from './variables/variables.effects';
import * as fromVariables from './variables/variables.reducers';
import { DevelopersEffects } from './developers/developers.effects';
import * as fromDevelopers from './developers/developers.reducers';
import { ComponentsEffects } from '@features/composite-indicator/state/components/components.effects';

export interface CoinState {
    category_groups: fromCategoryGroups.CategoryGroupsState;
    categories: fromCategories.CategoriesState;
    countries: fromCountries.CountriesState;
    editions: fromEditions.EditionsState;
    indices: fromIndices.IndicesState;
    levels: fromLevels.LevelsState;
    resources: fromResources.ResourcesState;
    units: fromUnits.UnitsState;
    variables: fromVariables.VariablesState;
    components: fromComponents.ComponentsState;
    componentVariables: fromComponentVariables.ComponentVariablesState;
    data: fromData.DataState;
    data_types: fromDataTypes.DataTypesState;
    groups: fromGroups.GroupsState;
    classes: fromClasses.ClassesState;
    status: fromStatus.CompositeIndicatorsState;
    unit_groups: fromUnitGroups.UnitGroupsState;
    variable_groups: fromVariableGroups.VariableGroupsState;
    unit_editions: fromUnitEditions.UnitEditionsState;
    queue: fromQueue.CoinQueueState;
    colors: fromColors.ColorsState;
    statistics: fromStatistics.StatisticsState;
    scales: fromScales.ScalesState;
    developers: fromDevelopers.DevelopersState;
}

export const coinReducer: ActionReducerMap<CoinState> = {
    category_groups: fromCategoryGroups.categoryGroupsReducer,
    categories: fromCategories.categoriesReducer,
    countries: fromCountries.countriesReducer,
    editions: fromEditions.editionsReducer,
    indices: fromIndices.indicesReducer,
    levels: fromLevels.levelsReducer,
    resources: fromResources.resourcesReducer,
    units: fromUnits.unitsReducer,
    variables: fromVariables.variablesReducer,
    components: fromComponents.componentsReducer,
    componentVariables: fromComponentVariables.componentVariablesReducer,
    data: fromData.dataReducer,
    data_types: fromDataTypes.dataTypesReducer,
    groups: fromGroups.groupsReducer,
    classes: fromClasses.classesReducer,
    status: fromStatus.compositeIndicatorsReducer,
    unit_groups: fromUnitGroups.unitGroupsReducer,
    variable_groups: fromVariableGroups.variableGroupsReducer,
    unit_editions: fromUnitEditions.unitEditionsReducer,
    queue: fromQueue.coinQueueReducer,
    colors: fromColors.colorsReducer,
    statistics: fromStatistics.statisticsReducer,
    scales: fromScales.scalesReducer,
    developers: fromDevelopers.developersReducer,
};

export const CoinEffects = [
    CategoryGroupsEffects,
    CategoriesEffects,
    CountriesEffects,
    IndicesEffects,
    EditionsEffects,
    LevelsEffects,
    ResourcesEffects,
    UnitsEffects,
    VariablesEffects,
    DataEffects,
    DataTypesEffects,
    GroupsEffects,
    ClassesEffects,
    CompositeIndicatorsEffects,
    UnitGroupsEffects,
    VariableGroupsEffects,
    UnitEditionsEffects,
    CoinQueueEffects,
    ColorsEffects,
    StatisticsEffects,
    ScalesEffects,
    DevelopersEffects,
    ComponentsEffects,
    ComponentVariablesEffects
];
