import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslationsService } from '@features/general/translations/translations.service';
import { IAnswer, IData, IIndexMainStructure, IMainStructure, IStructure, IUnitEdition } from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';
import {
    LastEditionRoutes,
    LastEditionUrls
} from '@features/composite-indicator/shared/api_routes';
import {EnvService} from "@shared/services/env.service";

@Injectable()
export class CompositeService extends SharedService {

    constructor(
        protected envService: EnvService,
        protected http: HttpClient,
        protected translationsService: TranslationsService,
        protected lastEditionRoutes: LastEditionRoutes,
    ) {
        super(envService, http, translationsService);
    }

    getLastEditionUrlByType = (code: string, type: LastEditionUrls): string => {
        return this.lastEditionRoutes.generateUrl(code, type);
    }

    getEditionStructureByUrl = (url: string): Observable<IStructure> => {
        return this.getByUrl<IStructure>(url);
    }

    getAllCategoriesWithResources = (): Observable<any> => {
        const url = this.getApiUrl() + 'resources/categories';
        return this.getByUrl<any>(url);
    }

    getAllEditionUnitsByUrl = (url: string): Observable<IUnitEdition[]> => {
        return this.getByUrl<IUnitEdition[]>(url);
    }

    getAllEditionUnitsByUnit = (code: string): Observable<IUnitEdition[]> => {
        const url = this.getApiUrl() + `units/${code}/edition_unit`;
        return this.getByUrl<IUnitEdition[]>(url);
    }

    getLastEditionMainStructureByIndex = (code: string): Observable<IAnswer<IIndexMainStructure>> => {
        const url = this.getLastEditionUrlByType(code, LastEditionUrls.MAINSTRUCTURE);
        return this.getFullAnswerByUrl<IIndexMainStructure>(url);
    }

    getLastEditionMainDataByIndex = (code: string): Observable<IData[]> => {
        const url = this.getLastEditionUrlByType(code, LastEditionUrls.MAINDATA);
        return this.getByUrl<IData[]>(url);
    }

    getMainStructureByUnit = (code: string): Observable<IAnswer<IMainStructure>> => {
        const url = this.getLastEditionUrlByType(code, LastEditionUrls.MAINSTRUCTURE);
        return this.getFullAnswerByUrl<IMainStructure>(url);
    }

    getMainStructureAllIndices = (): Observable<IAnswer<IMainStructure>> => {
        const url = this.getApiUrl() + 'indices/main-structure';
        return this.getFullAnswerByUrl<IMainStructure>(url);
    }

}
