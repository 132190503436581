import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IGroup } from '@shared/interfaces';
import { GroupsActions, GroupsActionTypes } from './groups.actions';

export interface GroupsState extends EntityState<IGroup> {
}

export const adapter: EntityAdapter<IGroup> =
    createEntityAdapter<IGroup>({});

export const initialGroupsState: GroupsState = adapter.getInitialState({
});

export function groupsReducer(state = initialGroupsState , action: GroupsActions): GroupsState {

    switch (action.type) {
    case GroupsActionTypes.GroupsLoaded:
        // adapter.removeAll(state);
        return adapter.setAll(action.payload.groups, state);
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
