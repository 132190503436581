import { Action } from '@ngrx/store';
import { IComponent, IComponentVariable } from '@shared/interfaces';

export enum ComponentVariablesActionTypes {
    ComponentVariablesRequested = '[Component variable] Request component variables',
    ComponentVariablesLoaded = '[Component variable] Component variable loaded',
}

export class ComponentVariablesRequested implements Action {
    readonly type = ComponentVariablesActionTypes.ComponentVariablesRequested;

    constructor(public payload: { ids: number[] }) {}
}

export class ComponentVariablesLoaded implements Action {
    readonly type = ComponentVariablesActionTypes.ComponentVariablesLoaded;

    constructor(public payload: { componentVariables: IComponentVariable[] }) {}
}

export type ComponentVariablesActions =
    ComponentVariablesRequested
    | ComponentVariablesLoaded;
