import { Action } from '@ngrx/store';
import {IQueueItem} from '@shared/interfaces';

export enum CoinQueueActionTypes {
    CoinAddToQueue = '[COIN QUEUE] Add to queue',
    CoinRemoveFromQueue = '[COIN QUEUE] Remove from queue',
    CoinRequestTypeQueue = '[COIN QUEUE] Request queue by type',
    CoinResolveQueue = '[COIN QUEUE] Resolve queue'
}

export class CoinResolveQueue implements Action {
    readonly type = CoinQueueActionTypes.CoinResolveQueue;
}

export class CoinRequestTypeQueue implements Action {
    readonly type = CoinQueueActionTypes.CoinRequestTypeQueue;

    constructor(public payload: {type: string}) {}
}

export class CoinAddToQueue implements Action {
    readonly type = CoinQueueActionTypes.CoinAddToQueue;

    constructor(public payload: IQueueItem[]) {}
}

export class CoinRemoveFromQueue implements Action {
    readonly type = CoinQueueActionTypes.CoinRemoveFromQueue;

    constructor(public payload: {ids: string[]}) {}
}

export type CoinQueueActions = CoinAddToQueue
    | CoinRemoveFromQueue
    | CoinRequestTypeQueue
    | CoinResolveQueue;
