import { Injectable } from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import {
    ColorsActionTypes,
    ColorsLoaded, ColorsRequestedByEdition,
    ColorsRequestedByUrl,
} from '@features/composite-indicator/state/colors/colors.actions';
import { selectlColorsUrlIsLoaded } from '@features/composite-indicator/state/colors/colors.selectors';
import { ColorsService } from '@features/composite-indicator/state/colors/colors.service';
import { getEditionUrlByRel } from '@features/composite-indicator/state/editions/editions.selectors';
import { of } from 'rxjs';
import { concatMapTo, filter, first, map, mapTo, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { IColor } from '@shared/interfaces';

@Injectable()
export class ColorsEffects {

    requestColorsbyUrl$ = createEffect(() =>this.actions$
        .pipe(
            ofType<ColorsRequestedByUrl>(ColorsActionTypes.ColorsRequestedByUrl),
            map((action) => action.payload.url),
            mergeMap((url) => of(url)
                .pipe(
                    withLatestFrom(this.store.select(selectlColorsUrlIsLoaded(url))),
                    filter(([action, loaded]) => !loaded),
                    mergeMap(() => this.colorsService.getColorsByUrl(url)),
                    map((colors) => {
                        const arr: IColor[] = [];
                        for (const item of colors) {
                            arr.push({ ...item, indice: 'C3M' });
                        }
                        return new ColorsLoaded({ colors: arr, url });
                    }),
                )),
        ));

    requestColorsByEdition$ = createEffect(() =>this.actions$
        .pipe(
            ofType<ColorsRequestedByEdition>(ColorsActionTypes.ColorsRequestedByEdition),
            map((action) => action.payload),
            mergeMap((action) => of(action)
                .pipe(
                    withLatestFrom(this.store.select(getEditionUrlByRel(action.indice, action.edition, 'colors'))),
                    filter(([request, url]) => !!url),
                    map(([request, url]) => new ColorsRequestedByUrl({ url })),
                )),
        ));

    constructor(private actions$: Actions, private colorsService: ColorsService,
                private store: Store<any>) {

    }
}
