import { createSelector } from '@ngrx/store';

import { selectRouterData, selectRouterParams, selectRouterUrl } from 'app/core/selectors';
import {
    CmsChartsCategoriesHelper,
    selectCmsChartCategoryId
} from '@features/general/charts/state/categories/cms-chart-categories.entity';
import {
    selectAllCmsChartsCategories,
    selectCmsChartsCategoriesFromSomeParams,
    selectCmsChartsCategoriesGetCategoriesFromUrls,
    selectCmsChartsCategoriesGetChartIdsFromUrls,
    selectCmsChartsGetChartCategoriesElems
} from '@features/general/charts/state/categories/cms-charts-categories.selectors';
import {
    selectAllCmsChartsData,
    selectCmsChartsAllDataUrls
} from '@features/general/charts/state/data/cms-charts.data.selectors';
import CmsChartsFiltersHelper from '@features/general/charts/state/filters/cms-charts-filters.helper';
import {
    selectAllCmsChartsFilters,
    selectCmsChartsFiltersByUrls,
    selectCmsChartsGetChartFilters,
    selectCmsChartsGetChartsFilters
} from '@features/general/charts/state/filters/cms-charts-filters.selectors';
import { CmsChartGeoHelper } from '@features/general/charts/state/geo/cms-chart-geo-helper';
import { IChartGeoLayer } from '@features/general/charts/state/geo/cms-chart-geo.entity';
import {
    selectAllCmsChartsGeo,
    selectCmsChartsGetChartLayers
} from '@features/general/charts/state/geo/cms-charts-geo.selectors';
import { selectCmsChartHighlightId } from '@features/general/charts/state/highlights/cms-chart-highlights.entity';
import {
    selectAllCmsChartsHighlights,
    selectCmsChartsGetChartHighlights,
    selectCmsChartsGetChartsHighlights,
    selectCmsChartsHighlightsByUrls
} from '@features/general/charts/state/highlights/cms-chart-highlights.selectors';
import {
    CmsChartsEntityHelper,
    CmsChartsSeriesHelper,
    ICmsChartParameter,
    selectCmsChartFilterId,
    selectCmsChartId,
    selectCmsChartSeriesId
} from '@features/general/charts/state/main/entities';
import { CmsChartParametersHelper } from '@features/general/charts/state/params/cms-chart-parameter-helper';
import {
    selectAllCmsChartsParams,
    selectCmsChartsGetChartIndependentParamNames,
    selectCmsChartsGetChartParams,
    selectCmsChartsGetChartsIndependentParams,
    selectCmsChartsGetChartsParams,
    selectCmsChartsParamsByNames,
    selectCmsChartsParamsGetMultiParamsAllSubordinates,
    selectCmsChartsParamsGetMultiParamsDirectSubordinates,
    selectCmsChartsParamsGetParamsByMultipleNameAndValue,
    selectCmsChartsParamsGetParamsDependencies,
    selectCmsChartsParamsGetParamsSubordinates
} from '@features/general/charts/state/params/cms-charts-params.selectors';
import {
    selectAllCmsChartsSeries,
    selectCmsChartsGetChartSeriesElems,
    selectCmsChartsGetChartSeriesUrls,
    selectCmsChartsGetMultipleChartsSeriesUrls, selectCmsChartsMultipleSeriesElemsWithSomeParams,
    selectCmsChartsSeriesForMultipleCharts,
    selectCmsChartsSeriesFromSomeParams,
    selectCmsChartsSeriesGetChartIdsFromSeriesUrls,
    selectCmsChartsSeriesGetSeriesFromUrls,
} from '@features/general/charts/state/series/cms-charts-series.selectors';
import {
    CmsChartsCategoriesDefaultIds,
    CmsChartsFiltersDisplay,
    CmsChartsLoadingState,
    CmsChartsRouterTypes, CmsChartsSetParamsStrategy
} from '@features/general/charts/structure';
import { CmsChartsHelper } from '@features/general/charts/structure/cms-charts.helper';
import { selectCmsActiveCharts, selectCmsActiveModuleContentBySlug } from '@features/general/cms/state/cms.selectors';
import { selectGeneralState } from '@features/general/general.selectors';
import { selectAllLists } from '@features/general/lists/state/lists.selectors';
import { IList } from '@features/general/lists/structure';
import { selectGeoAllData } from '@features/geo/state/geo.selectors';
import { ArrayHelper } from '@shared/helpers';
import * as fromCmsCharts from '../../cms-charts.reducer';
/**
 * State selectors
 */

/**
 * @charts
 * Get the state of the data hub charts
 */
export const selectCmsChartsState = createSelector(
    selectGeneralState,
    (state) => state.charts
);

export const selectCmsChartsChartsState = createSelector(
    selectCmsChartsState,
    (state) => state.charts
);

export const selectCmsChartsUrlsRequested = createSelector(
    selectCmsChartsState,
    (state) => state.requested
);

/**
 * END State selectors
 */

/**
 * Adapter selectors
 */
export const selectAllCmsCharts = createSelector(
    selectCmsChartsChartsState,
    fromCmsCharts.selectAlLCharts
);

export const selectAllCmsChartsEntities = createSelector(
    selectCmsChartsChartsState,
    fromCmsCharts.selectEntitiesCharts
);

/**
 * END Adapter selectors
 */

export const selectCsmChartsActiveCharts = createSelector(
    selectRouterUrl,
    selectAllCmsCharts,
    (url, charts) => charts.filter((chart) => chart.page === url)
);
export const selectCmsChartsActiveChartsIds = createSelector(
    selectCsmChartsActiveCharts,
    (charts) => charts.map((chart) => selectCmsChartId(chart))
);

export const selectCmsChartsById = (id: string) => createSelector(
    selectAllCmsChartsEntities,
    (charts) => charts[id]
);

/**
 * Returns all the active page charts with the respective data
 */

export const selectCmsChartsDataUrlsNotLoadedOrRequested = (urls: string[]) => createSelector(
    selectCmsChartsAllDataUrls,
    selectCmsChartsUrlsRequested,
    (loaded, requested) => {
        return urls.filter((url) => !!url && !(loaded.includes(url) || requested.includes(url)));
    }
);

export const selectCmsChartsUrlsNotLoaded = (urls: string[]) => createSelector(
    selectCmsChartsAllDataUrls,
    (loaded) => {
        return urls.filter((url) => !loaded.includes(url));
    }
);

export const selectCmsChartsRouterParams = createSelector(
    selectRouterParams,
    selectRouterData,
    (params: any, data_params: any) => {
        let cmsParams;
        let type;
        if (params && params.page) {
            type = CmsChartsRouterTypes.get(params.page) || params.page;
        } else if (data_params && data_params.page) {
            type = CmsChartsRouterTypes.get(data_params.page);
        }

        if (type) {
            cmsParams = { ...params, type };
        } else {
            cmsParams = params;
        }
        return { ...cmsParams, ...data_params };
    }
);

/**
 * Subordinates = Filters depending on this one
 * @param chart_id
 * @param names
 */
export const selectCmsChartsFiltersGetSubordinates = (chart_id: string, names: string[]) => createSelector(
    selectCmsChartsGetChartFilters(chart_id),
    selectCmsChartsParamsGetParamsSubordinates(chart_id, names),
    (filters, params) => filters.filter((f) => params.includes(f.param))
);

/**
 * Dependencies = Parent filters from which this one is dependant.
 * If a parent filters is changed, the current one also needs to change
 * @param chart_id
 * @param names
 */
export const selectCmsChartsFiltersGetDependencies = (chart_id: string, names: string[]) => createSelector(
    selectCmsChartsGetChartFilters(chart_id),
    selectCmsChartsParamsGetParamsDependencies(chart_id, names),
    (filters, params) => filters.filter((f) => params.includes(f.param))
);

export const selectCmsFiltersGetByListIds = (ids: string[]) => createSelector(
    selectAllCmsChartsFilters,
    (filters) => filters.filter((f) => ids.includes(f.list_id))
);

export const selectCmsParamsGetByListIds = (ids: string[]) => createSelector(
    selectCmsFiltersGetByListIds(ids),
    selectAllCmsChartsParams,
    (filters, params) => {
        return params.map((param) => ({
            ...param,
            list_id: filters.find((filter) => filter.param === param.name && filter.chart_id === param.chart_id)?.list_id,
            default_param: params.find((d) => d.converted === param.converted && d.chart_id === param.chart_id && param.name !== d.name) || null
        }))
            .filter((param) => !!param.list_id);
    }
);

export const selectCmsChartsFiltersByParamsNames = (args: Array<{chart_id: string, names: string[]}>) => createSelector(
    selectCmsChartsGetChartsFilters(ArrayHelper.uniquePluck(args, 'chart_id')),
    (filters) => filters.filter((f) => {
        const arg = args.find((d) => d.chart_id === f.chart_id);
        return arg.names.includes(f.param);
    })
);

export const selectCmsChartsFiltersByParams = (params: ICmsChartParameter[]) => createSelector(
    selectCmsChartsGetChartsFilters(ArrayHelper.uniquePluck(params, 'chart_id')),
    (filters) => filters.filter((f) => {
        return !!params.find((d) => d.chart_id === f.chart_id && d.name === f.param);

    })
);

export const selectCmsChartsGetChartIndependentFilters = (chart_id: string) => createSelector(
    selectCmsChartsGetChartFilters(chart_id),
    selectCmsChartsGetChartIndependentParamNames(chart_id),
    (filters, params) => filters.filter((f) => params.includes(f.param))
);

export const selectCmsChartsGetChartsIndependentFilters = (chart_ids: string[]) => createSelector(
    selectCmsChartsGetChartsFilters(chart_ids),
    selectCmsChartsGetChartsIndependentParams(chart_ids),
    (filters, params) => filters.filter((f) => !!params.find((p) => p.name === f.param && p.chart_id === f.chart_id))
);

export const selectCmsChartsGetChartIndependentHighlights = (chart_id: string) => createSelector(
    selectCmsChartsGetChartHighlights(chart_id),
    selectCmsChartsGetChartIndependentParamNames(chart_id),
    (highlights, params) => highlights.filter((d) => params.includes(d.param))
);

export const selectCmsChartsGetChartsIndependentHighlights = (chart_ids: string[]) => createSelector(
    selectCmsChartsGetChartsHighlights(chart_ids),
    selectCmsChartsGetChartsIndependentParams(chart_ids),
    (highlights, params) => highlights.filter((d) => !!params.find((p) => p.name === d.param && p.chart_id === d.chart_id))
);

export const selectCmsChartsGetChartIndependentFiltersAndHighlights = (chart_id: string) => createSelector(
    selectCmsChartsGetChartIndependentFilters(chart_id),
    selectCmsChartsGetChartIndependentHighlights(chart_id),
    (filters, highlights) => ({ filters, highlights })
);

export const selectCmsChartsGetChartsIndependentFiltersAndHighlights = (chart_ids: string[]) => createSelector(
    selectCmsChartsGetChartsIndependentFilters(chart_ids),
    selectCmsChartsGetChartsIndependentHighlights(chart_ids),
    (filters, highlights) => ({ filters, highlights })
);

export const selectCmsChartsAllActiveSeries = createSelector(
    selectCmsChartsActiveChartsIds,
    selectAllCmsChartsSeries,
    (ids, series) => series.filter((series_elem) => ids.includes(series_elem.chart_id))
);

export const selectCmsChartsAllActiveFilters = createSelector(
    selectCmsChartsActiveChartsIds,
    selectAllCmsChartsFilters,
    (ids, filters) => filters.filter((series_elem) => ids.includes(series_elem.chart_id))
);

export const selectCmsChartsAllActiveCategories = createSelector(
    selectCmsChartsActiveChartsIds,
    selectAllCmsChartsCategories,
    (ids, categories) => categories.filter((categories_elem) => ids.includes(categories_elem.chart_id))
);

export const selectCmsChartAllActivePageChartObjs = createSelector(
    selectCsmChartsActiveCharts,
    selectCmsChartsAllActiveSeries,
    selectCmsChartsAllActiveFilters,
    selectCmsChartsAllActiveCategories,
    selectAllCmsChartsParams,
    selectAllCmsChartsGeo,
    (charts, series, filters, categories, params, layers) => {
        return {
            charts,
            series,
            filters,
            categories,
            params,
            layers
        };
    }
);

// todo: add layers
/**
 * Returns all the active page charts with the respective data
 */
export const selectCmsChartsAllPageCharts = createSelector(
    selectCmsChartAllActivePageChartObjs,
    selectAllCmsChartsData,
    selectAllLists,
    selectGeoAllData,
    selectAllCmsChartsHighlights,
    (charts, data, lists, geo, highlights) => {
        return CmsChartsHelper.cmsToEclCharts(charts.charts, charts.series, charts.filters, charts.categories, data, lists, charts.params, geo, charts.layers, highlights);
    }
);

export const selectCmsActiveChartElemById = (chart_id: string) => createSelector(
    selectCsmChartsActiveCharts,
    (charts) => charts.find((chart) => selectCmsChartId(chart) === chart_id)
);

export const selectCmsChartGeoByChartId = (chart_id: string) => createSelector(
    selectGeoAllData,
    selectCmsChartsGetChartLayers(chart_id),
    (geo, layers) => {
        const getLayerUrls = (layer: IChartGeoLayer): string[] => (layer.urls || []).map((d) => d.url);

        const urls = layers.reduce((res, layer) => [...res, ...getLayerUrls(layer)], []).filter((url) => url);

        return geo.filter((g) => urls.includes(g.url));
    }
);

export const selectCmsActiveChartObjById = (chart_id: string) => createSelector(
    selectCmsActiveChartElemById(chart_id),
    selectCmsChartsGetChartSeriesElems(chart_id),
    selectCmsChartsGetChartFilters(chart_id),
    selectCmsChartsGetChartCategoriesElems(chart_id),
    selectCmsChartsGetChartParams(chart_id),
    selectCmsChartsGetChartLayers(chart_id),
    selectCmsChartsGetChartHighlights(chart_id),
    (chart, series, filters, categories, params, layers, highlights) => {
        return {
            chart,
            series,
            filters,
            categories,
            params,
            layers,
            highlights
        };
    }
);

export const selectCmsActiveChartById = (chart_id: string) => createSelector(
    selectCmsActiveChartObjById(chart_id),
    selectAllCmsChartsData,
    selectAllLists,
    selectCmsChartGeoByChartId(chart_id),
    (chart, data, lists, geo) => {
        return CmsChartsHelper.cmsToEclChart(chart.chart, chart.series, chart.filters, chart.categories, data, lists, chart.params, geo, chart.layers, chart.highlights);
    }
);

export const selectCmsChartsPageChartsByIds = (chart_ids: string[], titles?: string[]) => createSelector(
    selectCmsChartsAllPageCharts,
    (charts) => {
        return chart_ids.map((chart_id, idx) => {
            const chart = charts.find((c) => chart_id === c.uid);
            const title = (titles && titles[idx]) || (chart && chart.name) || '';
            return { ...chart, section_title: title };
        });

    }
);

export const selectCmsChartsPageChartsFilteredByUids = (uids: string[]) => createSelector(
    selectCmsChartsAllPageCharts,
    (charts) => {
        return charts.filter((c) => uids.includes(c.uid));
    }
);

export const selectCmsChartsPageChartsByUids = (uids: string[], extra_param?: {attr: string, slug: string}) => createSelector(
    selectCmsChartsPageChartsFilteredByUids(uids),
    selectCmsActiveModuleContentBySlug(extra_param && extra_param.slug || ''),
    (charts, param) => {
        let completed_charts;
        if (param) {
            const cparam = [];
            cparam[extra_param.attr] = param;
            completed_charts = CmsChartsHelper.completeChartWithExtraParams(charts, cparam);
        } else {
            completed_charts = charts;
        }
        return uids.map((uid, idx) => {
            const ucharts = completed_charts.filter((c) => uid === c.uid)
                .sort((a, b) => (a.order || 0) < (b.order || 0) ? -1 : 1);
            return { charts: ucharts };
        });

    }
);

export const selectCmsChartsPageChartsByUidsFlattened = (uids: string[], extra_param?: {attr: string, slug: string}) => createSelector(
    selectCmsChartsPageChartsByUids(uids, extra_param),
    (charts) => charts.reduce((res, elem) => { res.push(...elem.charts); return res; }, [])
);

export const selectCmsRankFromChartByChartId = (chart_id: string, rank_size = 10) => createSelector(
    selectCmsActiveChartById(chart_id),
    (chart) => {
        const data = chart && chart.data && chart.data.series && chart.data.series[0] || null;
        if (!data) { return null; }
        const values = JSON.parse(JSON.stringify(data.values));
        const value_index = data.keys.indexOf('value');
        const category_id = data.keys[0];
        const best_values = values.sort((a, b) => a[value_index] > b[value_index] ? 1 : -1)
            .filter((value, idx) => idx < rank_size);

        return best_values.map((value) => {
            const category = chart.data.categories[CmsChartsCategoriesDefaultIds.primary].elements.find((cat) => cat[category_id] === value[0]);
            return { ...category, value: value[1] };
        });
    }
);

export const selectCmsChartCopyAllElements = (chart_id: string) => createSelector(
    selectCmsChartsById(chart_id),
    selectCmsChartsGetChartCategoriesElems(chart_id),
    selectCmsChartsGetChartSeriesElems(chart_id),
    selectCmsChartsGetChartParams(chart_id),
    selectCmsChartsGetChartFilters(chart_id),
    selectCmsChartsGetChartLayers(chart_id),
    (chart, categories, series, params, filters, layers) => {
        const new_chart = CmsChartsEntityHelper.cloneChart(chart);
        const new_chart_id = new_chart.id;
        const new_categories = CmsChartsCategoriesHelper.cloneCategories(categories, new_chart_id);
        const new_series = CmsChartsSeriesHelper.cloneSeries(series, new_chart_id);
        const new_params = CmsChartParametersHelper.cloneParameters(params, new_chart_id);
        const new_filters = CmsChartsFiltersHelper.cloneFilters(filters, new_chart_id);
        const new_layers = CmsChartGeoHelper.cloneLayers(layers, new_chart_id);

        return {
            chart: new_chart,
            categories: new_categories,
            series: new_series,
            params: new_params,
            filters: new_filters,
            layers: new_layers
        };
    }
);

export const selectCmsChartsLoadStrategy = (elems) => createSelector(
    selectCmsActiveCharts,
    (charts) => elems.map((elem) => {
        const chart = charts && charts.find((c) => selectCmsChartId(c) === elem.chart_id);

        const loadStrategy = CmsChartsHelper.getChartParamLoadStrategy(chart, elem.param);
        return { ...elem, loadStrategy };
    })
);

export const selectCmsChartsGetData = (chart_id: string) => createSelector(
    selectAllCmsChartsData,
    selectCmsChartsGetChartSeriesUrls(chart_id),
    (data, urls) => data.filter((d) => urls.includes(d.url) && d.data.length > 0)
);

export const selectCmsChartsGetDataMultipleCharts = (chart_ids: string[]) => createSelector(
    selectAllCmsChartsData,
    selectCmsChartsGetMultipleChartsSeriesUrls(chart_ids),
    (data, series) => {
        return chart_ids.map((chart_id) => {
            const chart_urls = series.filter((s) => s.chart_id === chart_id && (s.url || '').length).map((s) => s.url);
            const chart_data = data.filter((s) => chart_urls.includes(s.url) && s.data && s.data.length > 0);
            return {
                chart_id,
                data: chart_data
            };
        });
    }
);

export const selectCmsChartsHasData = (chart_id: string) => createSelector(
    selectCmsChartsGetData(chart_id),
    (data) => data.length > 0
);

export const selectCmsChartsFilterWhoHasData = (chart_ids: string[]) => createSelector(
    selectCmsChartsGetDataMultipleCharts(chart_ids),
    (obj) => {
        return obj.filter((d) => d.data.length);
    }
);

export const selectCmsChartParamsByConvertedId = (chart_id: string, converted: string) => createSelector(
    selectCmsChartsGetChartParams(chart_id),
    (params) => params.filter((param) => param.converted === converted)
);

export const selectCmsChartParamByName = (chart_id: string, name: string) => createSelector(
    selectCmsChartsGetChartParams(chart_id),
    (params) => params.filter((param) => param.name === name)
);

export const selectCmsChartGetChartIdsFromUrls = (urls: string[]) => createSelector(
    selectCmsChartsSeriesGetChartIdsFromSeriesUrls(urls),
    selectCmsChartsCategoriesGetChartIdsFromUrls(urls),
    (s_ids, c_ids) => Array.from(new Set([...(s_ids || []), ...(c_ids || [])]))
);

export const selectCmsChartsSeriesGetSeriesWithRequestedUrlsByChartIds = (chart_ids: string[]) => createSelector(
    selectCmsChartsSeriesForMultipleCharts(chart_ids),
    selectCmsChartsUrlsRequested,
    (series, urls) => series.filter((s) => urls.includes(s.url))
);

export const selectCmsChartsSeriesGetSeriesWithRequestedUrlsByUrls = (urls: string[]) => createSelector(
    selectCmsChartsSeriesGetSeriesFromUrls(urls),
    selectCmsChartsUrlsRequested,
    (series, requested) => series.filter((s) => requested.includes(s.url))
);

export const selectCmsChartsSeriesGetSeriesWithoutRequestedUrlsByUrls = (urls: string[]) => createSelector(
    selectCmsChartsSeriesGetSeriesFromUrls(urls),
    selectCmsChartsUrlsRequested,
    (series, requested) => series.filter((s) => !requested.includes(s.url))
);

export const selectCmsChartsSeriesCheckChartsPendingUrlsByUrls = (urls: string[]) => createSelector(
    selectCmsChartsSeriesGetSeriesWithRequestedUrlsByUrls(urls),
    (series) => Array.from(new Set(series.map((s) => s.chart_id)))
);

/**
 * Used in an effect. When a url is removed from the requested, because data was loaded,
 * it checks if the charts still have some pending urls requested
 */
export const selectCmsChartsSeriesChartsWithoutPendingUrlsByUrls = (urls: string[]) => createSelector(
    selectCmsChartsSeriesGetSeriesWithoutRequestedUrlsByUrls(urls),
    (series) => Array.from(new Set(series.map((s) => s.chart_id)))
);

export const selectCMsChartsEffectsSetFiltersAndHighlightsStatesOnListLoading = (lists: IList[], urlAttr) => createSelector(
    selectCmsChartsFiltersByUrls(ArrayHelper.uniquePluck(lists, urlAttr)),
    selectCmsChartsHighlightsByUrls(ArrayHelper.uniquePluck(lists, urlAttr)),
    (filters, highlights) => {

        return {
            filters: filters.map((f) => {
                return {
                    id: selectCmsChartFilterId(f),
                    changes: {
                        display: CmsChartsFiltersDisplay.enabled,
                        status: CmsChartsLoadingState.loaded
                    }
                };
            }),
            highlights: highlights.map((h) => {
                return {
                    id: selectCmsChartHighlightId(h),
                    changes: {
                        display: CmsChartsFiltersDisplay.enabled,
                        status: CmsChartsLoadingState.loaded
                    }
                };
            }),
        };
    }
);

export const cmsChartsEffectsRequestIndepFiltersAndHighlightsAfterSetPageParameters = (charts_ids: string[]) => createSelector(
    selectCmsChartsGetChartsIndependentFiltersAndHighlights(charts_ids),
    selectCmsChartsGetChartsParams(charts_ids),
    (indeps, params) => {
        const convertToUpdatePayload = (elems, getId) => {
            return elems.map((elem) => {
                const elem_params = params.filter((p) => p.chart_id === elem.chart_id);
                const conversionRules = elem?.options?.rules || null;

                return {
                    id: getId(elem),
                    changes: {
                        list_id: CmsChartsFiltersHelper.getUrlFromParams(elem.chart_id, elem.slug, elem_params, conversionRules),
                        display: CmsChartsFiltersDisplay.enabled,
                        status: CmsChartsLoadingState.loading
                    }
                };
            })
                .filter((d) => d.changes.list_id != null);
        };

        const convertToSetParams = (elems) => {
            const vFilters = elems.filter((elem) => elem?.values?.length > 0);
            if (vFilters?.length === 0) {
                return { single: [], multiple: [] };
            }
            return vFilters.reduce((res, filter) => {
                    const currentFilterValues = filter.values;
                    const list_params = params.filter((p) => p.name === filter.param);
                    const valuesFromList = CmsChartsHelper.setParamsFromList(list_params, currentFilterValues);

                    for (const val of valuesFromList) {
                        if (Array.isArray(val?.value) && val.value.length > 0) {
                            res.multiple.push({
                               chart_id: val.chart_id,
                               name: val.name,
                               values: val.value
                            });
                        } else {
                            res.single.push({
                                chart_id: val.chart_id,
                                name: val.name,
                                value: val.value
                            });
                        }
                    }
                    return res;
                }, { single: [], multiple: [] });
        };

        const setActions = convertToSetParams(indeps.filters);
        return {
            filters: convertToUpdatePayload(indeps.filters, selectCmsChartFilterId),
            highlights: convertToUpdatePayload(indeps.highlights, selectCmsChartHighlightId),
            setParams: setActions.single,
            setParamsWithMultipleValues: setActions.multiple
        };
    }
);

export const selectCmsChartsEffectsSetParamsFromLists = (lists: Array<{id: string, slug: string, values: any[]}>) => createSelector(
    selectCmsParamsGetByListIds(ArrayHelper.uniquePluck(lists, 'id')),
    (params) => {
        const convertToSetParams = (lists) => {
            const vlists = lists.filter((elem) => elem?.values?.length > 0);
            if (vlists?.length === 0) {
                return { single: [], multiple: [] };
            }
            return vlists.reduce((res, list) => {
                const list_params = params.filter((param) => param.list_id === list.id);
                const values = ArrayHelper.flattenMultiLevelArray(list.values);
                const valuesFromList = CmsChartsHelper.setParamsFromList(list_params, values);

                for (const val of valuesFromList) {
                    if (Array.isArray(val?.value)) {
                        if (val.value.length > 0) {
                            res.multiple.push({
                                chart_id: val.chart_id,
                                name: val.name,
                                values: val.value,
                                strategy: CmsChartsSetParamsStrategy.add
                            });
                        }
                    } else if (val.value !== null) {
                        res.single.push({
                            chart_id: val.chart_id,
                            name: val.name,
                            value: val.value
                        });
                    }
                }
                return res;
                //              return [...res, ...CmsChartsHelper.setParamsFromList(list_params, f_values)];
            }, { single: [], multiple: [] });
        };
        const setActions = convertToSetParams(lists);

        return {
            setParams: setActions.single,
            setParamsWithMultipleValues: setActions.multiple
        };
    }
);

export const selectCmsChartsEffectsSetParamsFromValues = (list: Array<{chart_id: string, name: string, value: any}>) => createSelector(
    selectCmsChartsParamsByNames(ArrayHelper.GroupBy(list, 'chart_id', 'names', ['name']) as any),
    selectCmsChartsParamsGetMultiParamsAllSubordinates(ArrayHelper.GroupBy(list, 'chart_id', 'names', ['name']) as any),
    (params, deps) => {
        const clearParams = deps.map((dep) => ({ chart_id: dep.chart_id, name: dep.name }));
        return {
            setParams: list,
            clearParams
        };
    }
);

export const selectCmsChartsEffectsFilterWithoutDeps = (args: Array<{chart_id: string, name: string, value }>) => createSelector(
    selectCmsChartsGetChartsParams(ArrayHelper.uniquePluck(args, 'chart_id')),
    (params) => {
        return args.filter((arg) => {
            const param = params.find((p) => p.name === arg.name && p.chart_id === arg.chart_id);
            return param?.subordinates?.length === 0;
        });
    }
);

export const selectCmsChartsEffectsSetSeriesUrls = (args: Array<{chart_id: string, names: string}>) => createSelector(
    selectCmsChartsSeriesFromSomeParams(args),
    selectCmsChartsCategoriesFromSomeParams(args),
    selectCmsChartsGetChartsParams(ArrayHelper.uniquePluck(args, 'chart_id')),
    (series, categories, params) => {
 //       console.log(args, params);
        return {
            series: CmsChartsSeriesHelper.getUrlsFromParams(series, params).filter((url) => url.url != null),
            categories: CmsChartsCategoriesHelper.getUrlsFromParams(categories, params).filter((url) => url.url != null)
        };
    }
);

export const selectCmsChartsEffectsFilterWithDeps = (params) => createSelector(
    selectCmsChartsGetChartsFilters(ArrayHelper.uniquePluck(params, 'chart_id')),
    selectCmsChartsGetChartsParams(ArrayHelper.uniquePluck(params, 'chart_id')),
    selectCmsChartsParamsGetMultiParamsAllSubordinates(params),
    selectCmsChartsParamsGetMultiParamsDirectSubordinates(params),
    (filters, cparams, deps, direct) => {
        const convertToUpdatePayload = (elems, getId) => {
            return elems.map((elem) => {
                const isDirect = !!direct.find((param) => param.name === elem.name);
                const elem_params = cparams.filter((p) => p.chart_id === elem.chart_id);

                const filter = filters.find((filter) => filter.chart_id === elem.chart_id && filter.param === elem.name);
                const conversionRules = filter?.options?.rules || null;
                return {
                    id: getId(filter),
                    changes: {
                        list_id: isDirect ? CmsChartsFiltersHelper.getUrlFromParams(elem.chart_id, filter.slug, elem_params, conversionRules) : null,
                        display: isDirect ? CmsChartsFiltersDisplay.enabled : CmsChartsFiltersDisplay.hidden,
                        status: isDirect ? CmsChartsLoadingState.loading : CmsChartsLoadingState.awaiting
                    }
                };
            });
    //            .filter((d) => d.changes.list_id != null);
        };
/*        const convertToSetUrlsPayload = (elems) => {
            return elems.map((elem) => {
                const filter = filters.find((f) => f.chart_id === elem.chart_id && f.param === elem.name);
                const elem_params = cparams.filter((p) => p.chart_id === elem.chart_id);
                const conversionRules = filter?.options?.rules || null;
                return {
                    chart_id: filter.chart_id,
                    param: filter.param,
                    slug: filter.slug,
                    url: CmsChartsFiltersHelper.getUrlFromParams(filter.chart_id, filter.slug, elem_params, conversionRules),
                    status: CmsChartsLoadingState.loading
                };
            });
        }*/
// testing with remove multiple copy
        const clearParams = deps.map((d) => ({ chart_id: d.chart_id, name: d.name, values: [] }));
        return {
            updateUrls: convertToUpdatePayload(deps, selectCmsChartFilterId),
            clearParams
        };
    }
);

export const selectCmsChartsSeriesGetSeriesAndCategoriesFromUrls = (urls: string[]) => createSelector(
    selectCmsChartsSeriesGetSeriesFromUrls(urls),
    selectCmsChartsCategoriesGetCategoriesFromUrls(urls),
    (series, categories) => ({ series, categories })
);

export const selectCmsChartsEffectsDataUrlsRequested = (urls: string[]) => createSelector(
    selectCmsChartsSeriesGetSeriesAndCategoriesFromUrls(urls),
    selectCmsChartsDataUrlsNotLoadedOrRequested(urls),
    selectCmsChartsUrlsNotLoaded(urls),
    (data, notLoadedOrRequested, notLoaded) => {
        const payload = {
            updates: {
                series: data.series.map((s) => ({
                    id: selectCmsChartSeriesId(s),
                    changes: { status: notLoaded.includes(s.url) ? CmsChartsLoadingState.loading : CmsChartsLoadingState.loaded }
                })),
                categories: data.categories.map((c) => ({
                    id: selectCmsChartCategoryId(c),
                    changes: { status: notLoaded.includes(c.url) ? CmsChartsLoadingState.loading : CmsChartsLoadingState.loaded }
                }))
            },
            urls: urls.filter((url) => notLoadedOrRequested.includes(url))
        };
        return payload;
    }
);

export const selectCmsChartsEffectsDataUrlsRemoveFromRequested = (data_loaded: Array<{ url: string, status: CmsChartsLoadingState }>) => createSelector(
    selectCmsChartsSeriesGetSeriesAndCategoriesFromUrls(ArrayHelper.uniquePluck(data_loaded, 'url')),
    (data) => {
        const getDataByUrl = (url) => data_loaded.find((d) => d.url === url);

        const urls = ArrayHelper.uniquePluck(data_loaded, 'url');

        const payload = {
            updates: {
                series: data.series.map((s) => ({
                    id: selectCmsChartSeriesId(s),
                    changes: { status: getDataByUrl(s.url)?.status || s.status }
                })),
                categories: data.categories.map((c) => ({
                    id: selectCmsChartCategoryId(c),
                    changes: { status: getDataByUrl(c.url)?.status || c.status }
                }))
            },
            urls
        };
        return payload;
    }
);

export const cmsChartsEffectsAddSeriesWhenSetCopiedParams = (args: Array<{ chart_id: string, params: string[], param: ICmsChartParameter, newName: string, newValue }>) => createSelector(
    selectCmsChartsMultipleSeriesElemsWithSomeParams(args),
    (series) => {
            const newSeries = [];
            const newParams = [];
            for (const arg of args) {
            const newParam = CmsChartParametersHelper.copyParam(arg.param, arg.newName, arg.newValue);
            const newSr = CmsChartsSeriesHelper.getSeriesWithSomeParams(series, arg.params)
                .map((s) => CmsChartsSeriesHelper.copySeries(s, newParam));
            newSeries.push(...newSr);
            newParams.push(newParam);
        }

            return {
            series: newSeries,
            params: newParams
        };
    }
);

export const cmsChartsEffectscmsChartsRemoveCopyParams = (args: Array<{chart_id: string, params: string}>) => createSelector(
    selectCmsChartsMultipleSeriesElemsWithSomeParams(args),
    selectCmsChartsParamsGetParamsByMultipleNameAndValue(args.map((p) => ({ chart_id: p.chart_id, name: p.params }))),
    (series, params) => {
//        const origin = params.find((p) => p.origin === p.name);
        return {
            paramsIds: params.filter((p) => p.name !== p.origin).map((param) => CmsChartParametersHelper.getIdFromChartAndName(param.chart_id, param.name)),
            seriesIds: series.map((s) => CmsChartsSeriesHelper.getIdFromChartAndName(s.chart_id, s.name))
        };
    }
);

