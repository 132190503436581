import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EuiAllModule } from '@eui/components';
import { EclAllModule } from '@eui/ecl';
import { UxAllModule } from '@eui/components/legacy';
import { EclLinkDirectiveModule } from '@eui/ecl';
import { TranslateModule } from '@ngx-translate/core';
import { CorrelationMatrixChartComponent } from '@features/charts2/charts/correlation-matrix/correlation-matrix-chart.component';
import { HeatmapChartComponent } from '@features/charts2/charts/heatmap/heatmap-chart.component';
import { HorizontalBarChartComponent } from '@features/charts2/charts/horizontal-bar/horizontal-bar-chart.component';
import { LayeredMapChartComponent } from '@features/charts2/charts/layered-map/layered-map-chart.component';
import { MapWithMenuChartComponent } from '@features/charts2/charts/map-wIth-menu/map-with-menu.chart.component';
import { MapChartComponent } from '@features/charts2/charts/map/map-chart.component';
import { OrdinalScatterChartComponent } from '@features/charts2/charts/ordinal-scatter/ordinal-scatter-chart.component';
import { RangeScatterChartComponent } from '@features/charts2/charts/range-scatter/range-scatter-chart.component';
import { ScatterPlotChartComponent } from '@features/charts2/charts/scatter-plot/scatter-plot-chart.component';
import { ChartsClickOutsideDirective } from '@features/charts2/directives/charts-click-outside.directive';
import { ChartTooltipComponent } from '@features/charts2/tooltips/tooltip.container.component';
import { ColumnChartComponent } from './charts/column/column-chart.component';
import { DotPlotComponent } from './charts/dot-plot/dot-plot.component';
import { DotRangeComponent } from './charts/dot-range/dot-range.component';
import { HeatmapDotGradientComponent } from './charts/heatmap-dot-gradient/heatmap-dot-gradient.component';
import { HeatmapDotComponent } from './charts/heatmap-dot/heatmap-dot.component';
import { LineBackgroundComponent } from './charts/line-background/line-background.component';
import { LineComponent } from './charts/line/line.component';
import { LollipopComponent } from './charts/lollipop/lollipop.component';
import { RangeColumnChartComponent } from './charts/range-column/range-column-chart.component';
import { SwarmComponent } from './charts/swarm/swarm.component';
import { DotsTooltipComponent } from './tooltips/dots-tooltip/dots-tooltip.component';
import { ScatterTooltipComponent } from './tooltips/scatter-tooltip/scatter.tooltip.component';

@NgModule({
    declarations: [
        ColumnChartComponent,
        RangeColumnChartComponent,
        HeatmapChartComponent,
        ScatterPlotChartComponent,
        OrdinalScatterChartComponent,
        RangeScatterChartComponent,
        MapChartComponent,
        HorizontalBarChartComponent,
        CorrelationMatrixChartComponent,
        ScatterTooltipComponent,
        ChartTooltipComponent,
        DotPlotComponent,
        DotRangeComponent,
        ChartsClickOutsideDirective,
        HeatmapDotComponent,
        DotsTooltipComponent,
        LayeredMapChartComponent,
        LineComponent,
        MapWithMenuChartComponent,
        LollipopComponent,
        SwarmComponent,
        HeatmapDotGradientComponent,
        LineBackgroundComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        EuiAllModule,
        EclAllModule,
        UxAllModule,
        EclLinkDirectiveModule,
    ],
    exports: [
        ColumnChartComponent,
        RangeColumnChartComponent,
        HeatmapChartComponent,
        ScatterPlotChartComponent,
        OrdinalScatterChartComponent,
        RangeScatterChartComponent,
        MapChartComponent,
        HorizontalBarChartComponent,
        CorrelationMatrixChartComponent,
        ChartTooltipComponent,
        DotPlotComponent,
        DotRangeComponent,
        ChartsClickOutsideDirective,
        HeatmapDotComponent,
        LayeredMapChartComponent,
        LineComponent,
        MapWithMenuChartComponent,
        LollipopComponent,
        SwarmComponent,
        HeatmapDotGradientComponent,
        LineBackgroundComponent,
        ScatterTooltipComponent
    ]
})
export class Charts2Module { }
