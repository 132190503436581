import { Injectable } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    GeoBaseUrls, GeoGiscoFileTypes, GeoGiscoProjections, GeoGiscoScales, geoGisCountriesFiles, GeoLocalFiles
} from '@features/geo/structure';
import {
    GeoBasePathType,
    GeoHelper,
    GeoLayer,
    GeoLayerOrder,
    GeoLayerTypes,
    LayerTypes
} from '@features/geo/structure/geo.structure';
import { IChartGeoLayer } from '@features/general/charts/state/geo/cms-chart-geo.entity';
import { CmsChartGeoHelper } from '@features/general/charts/state/geo/cms-chart-geo-helper';

@Injectable({
  providedIn: 'root'
})
export class GeoService extends SharedService {

    getBaseGeoApi = (locale?: string) => this.getApiUrl(locale);

    getBaseGeoLocal = () => 'assets/geo/';

    getLayerBaseUrl = (type: GeoBasePathType): string => {
        switch (type) {
            case GeoBasePathType.api:
                return this.getBaseGeoApi();
            case GeoBasePathType.local:
                return this.getBaseGeoLocal();
        }
    }

    baseUrlsAsMap = (locale?: string) => {
        return new Map([
            [
                GeoBasePathType.api,
                this.getBaseGeoApi(locale)
            ],
            [
                GeoBasePathType.local,
                this.getBaseGeoLocal()
            ]
        ]);
    }

    getLocalFile = (file: GeoLocalFiles): Observable<any> => {
        const url = GeoBaseUrls.local + file;
        return this.getGeoByUrl<any>(url);
    }

    getLayerTypeUrl = (layerSource: LayerTypes, params, conversionRules = null) => {
        const type = GeoLayerTypes.get(layerSource);
        let url: string;

        if (type) {
            url = GeoHelper.GetLayerTypeUrl(layerSource, params, conversionRules);
            url = url && (this.getLayerBaseUrl(type.base) + url) || null;
        } else {
            url = null;
        }

        return url;
    }

    getLayerUrl = (type: LayerTypes, params, conversionRules = null): string => {
        const meta = GeoLayer.get(type);
        let url: string;

        if (type) {
            url = GeoHelper.GetLayerUrl(type, params, conversionRules);
            url = url && (this.getLayerBaseUrl(meta.base) + url) || null;
        } else {
            url = null;
        }

        return url;
    }

    updateLayersUrls = (layers: IChartGeoLayer[], params = null): IChartGeoLayer[] => {
        return CmsChartGeoHelper.updateLayersUrls(layers, params, this.baseUrlsAsMap());
    }

    getGeoByUrl = <T>(url: string): Observable<{ data: T, url: string}> => this.http.get<T>(url)
      .pipe(
          map(res => ({ data: res, url }))
      )

    getCountries = (scale: GeoGiscoScales): Observable<any> => {
        const url = geoGisCountriesFiles(GeoGiscoFileTypes.topojson, scale, '2020', GeoGiscoProjections.epsg4326);
        return this.getGeoByUrl<any>(url);
    }

    getGiscoCountries = () => {
      const url = 'https://gisco-services.ec.europa.eu/distribution/v2/countries/topojson/CNTR_RG_10M_2020_4326.json';
      return this.getGeoByUrl<any>(url);
    }

    getGeoLayerByUnitType = (unitType, params, conversionRules = null) => {
        let meta;
        let layer;
        let url: string;

        switch (unitType.type) {
   //         case LayerTypes.lau:
            case LayerTypes.indice:
                meta = GeoLayer.get(LayerTypes.indice);
                url = this.getLayerUrl(LayerTypes.indice, params, conversionRules);
                break;
            default:
                meta = GeoLayer.get(unitType.type);
                url = this.getLayerUrl(unitType.type, unitType, conversionRules);
                break;
        }

        if (meta) {
            layer = {
                url,
                keys: { ...meta.keys }, // { id: meta.id, name: meta.name, parent: meta.keys.name },
                order: GeoHelper.GetLayerOrder(unitType)
            };
        } else {
            layer = {
                url: null,
                keys: { id: null, name: null, parent: null },
                order: null
            };
        }

        return layer;
    }
}
