import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
    selectAllComponents, selectVariableIdsByEdition, selectVariablesIdsByParentId
} from '../components/components.selectors';
import {selectAllData} from '../data/data.selectors';
import {getEditionUrlByRel} from '../editions/editions.selectors';
import {selectUnitByCode} from '../units/units.selectors';
import {selectAllVariables} from '../variables/variables.selectors';
import * as fromCompositeIndicators from './composite-indicators.reducer';
import {CoinStatus} from './composite-indicators.reducer';

import {selectScreenSize, selectScreenSizeDual} from '../../../../shared/screen/screen.selectors';
import {selectAllCategories} from '../categories/categories.selectors';
import {CoinState} from '../index';
import {selectAllResources, selectResourcesByIds} from '../resources/resources.selectors';

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectCoinStatusState = createSelector(
    selectCoinState,
    (state) => state.status,
);

export const selectCoinAllStatus = createSelector(
    selectCoinStatusState,
    fromCompositeIndicators.selectAll,
);

export const selectCoinEditionByCode = (indice: string, edition: number) => createSelector(
    selectCoinAllStatus,
    (editions) => editions.find((ed) => ed.indice === indice && ed.edition === edition),
);

export const selectCoinIndiceRequests = (indice: string) => createSelector(
    selectCoinAllStatus,
    (indices) => indices.filter((idx) => idx.indice === indice && idx.status === CoinStatus.REQUESTED),
);

export const selectCoinEditionIsBeingRequested = (indice: string, edition: number) => createSelector(
    selectCoinEditionByCode(indice, edition),
    (ed) => ed && ed.status === CoinStatus.REQUESTED,
);

export const selectCoinEditionAllDataLoadedOrLoading = (indice: string, edition: number) => createSelector(
    selectCoinEditionByCode(indice, edition),
    (ed) => ed && [CoinStatus.LOADED, CoinStatus.LOADING].includes(ed.allData),
);

export const selectCoinEditionAllDataLoaded = (indice: string, edition: number) => createSelector(
    selectCoinEditionByCode(indice, edition),
    (ed) => ed && ed.allData === CoinStatus.LOADED,
);

export const selectCoinDataIsBeingRequested = (indice: string, edition: number) => createSelector(
    selectCoinEditionByCode(indice, edition),
    (ed) => ed && ed.allData === CoinStatus.REQUESTED,
);

export const selectCoinEditionsBeingRequested = createSelector(
    selectCoinAllStatus,
    (editions) => editions.filter((ed) => ed.status === CoinStatus.REQUESTED),
);

export const selectCoinLastEditionIsLoaded = (indice: string) => createSelector(
    selectCoinAllStatus,
    (editions) => !!editions.find((ed) => ed.indice === indice && ed.isLastEdition && ed.status === CoinStatus.LOADED),
);

export const selectCoinEditionIsLoaded = (indice: string, edition: number) => createSelector(
    selectCoinEditionByCode(indice, edition),
    (ed) => ed && ed.status === CoinStatus.LOADED,
);

export const selectCoinEditionIsFullyLoaded = (indice: string, edition: number) => createSelector(
    selectCoinEditionByCode(indice, edition),
    (ed) => ed && (ed.status === CoinStatus.LOADED) && (ed.allData === CoinStatus.LOADED),
);

export const selectVariablesByEdition = (indice: string, edition: number) => createSelector(
    selectVariableIdsByEdition(indice, edition),
    selectAllVariables,
    (ids, variables) => {
        try {
            return variables.filter((v) => ids.includes(v.id));
        } catch (err) {
            return [];
        }
    },
);

export const selectVariablesByComponentParentId = (indice: string, edition: number, parent_id: number) => createSelector(
    selectVariablesIdsByParentId(indice, edition, parent_id),
    selectVariablesByEdition(indice, edition),
    (ids, variables) => {
        try {
            return variables.filter((variable) => ids.includes(variable.id));
        } catch (err) {
            return [];
        }
    },
);

export const selectDataByEdition = (indice: string, edition: number) => createSelector(
    selectVariableIdsByEdition(indice, edition),
    selectAllData,
    (ids, data) => {
        try {
            return data.filter((d) => ids.includes(d.variable_id));
        } catch (err) {
            return [];
        }
    },
);

export const selectDataByEditionUnit = (indice: string, edition: number, unit_code: string) => createSelector(
    selectDataByEdition(indice, edition),
    selectUnitByCode(unit_code),
    (data, unit) => {
        try {
            return data.filter((d) => d.unit_id === unit.id);
        } catch (err) {
            return [];
        }
    },
);

export const selectCoinIndiceAfterLoading = (indice: string, edition: number) => createSelector(
    getEditionUrlByRel(indice, edition, 'structure'),
    selectCoinEditionByCode(indice, edition),
    (url, edStatus) => {

        if (indice === 'C3M') {
            if (url.includes('structure')) {
                url = url + 'c3?year=' + edStatus.dataYear;
            }
        }

        return {
            url,
            dataIsRequested: edStatus.allData === CoinStatus.REQUESTED,
        };
    },
);

export const selectCoinEditionByDataUrl = (url: string) => createSelector(
    selectCoinAllStatus,
    (status) => status.find((s) => url.includes(s.dataUrl)),
);

export const selectCoinEditionDataIsLoading = (url: string) => createSelector(
    selectCoinEditionByDataUrl(url),
    (status) => status && status.allData === CoinStatus.LOADING,
);

export const selectDataByEditionType = (indice: string, edition: number, type_id: number) => createSelector(
    selectDataByEdition(indice, edition),
    (data) => data.filter((d) => d.type_id === type_id),
);

// todo: Implement data types
/*
export const selectDataByEditionTypeCode = (indice: string, edition: number, type: string) => createSelector(
    selectDataByEdition(indice, edition),
    selectDataTypeByCode(type),
    (data, type) => data.filter(d => d.type_id === type.id)
);
*/

export const selectDataByEditionUnitType = (indice: string, edition: number, unit_code: string, type_id: number) => createSelector(
    selectDataByEditionUnit(indice, edition, unit_code),
    (data) => data.filter((d) => d.type_id === type_id),
);

export const selectAllComponentsResources = createSelector(
    selectAllComponents,
    selectAllResources,
    (components, resources) => resources.filter((res) => res.resourceType === 'components' && components.find((comp) => comp.id === res.id)),
);

export const selectAllCategoriesResources = createSelector(
    selectAllCategories,
    selectAllResources,
    (categories, resources) => resources.filter((res) => res.resourceType === 'categories' && !!categories.find((cat) => cat.id === res.id)),
);

export const selectAllCategoriesResourceLinks = createSelector(
    selectAllCategoriesResources,
    (resources) => resources.map((res) => ({ id: res.id, link: res.links.find((link) => link.rel === 'self') })),
);

export const selectAllCategoriesResourceLinksForDesktop = createSelector(
    selectAllCategoriesResources,
    resources => resources.filter((res) => (res.rel === 'desktop') || (res.rel === 'icon'))
        .map((res) => ({ id: res.id, link: res.links.find((link) => link.rel === 'self') })),
);

export const selectAllCategoriesResourceLinksByScreenSize = createSelector(
    selectScreenSize,
    selectAllCategoriesResources,
    (size, resources) => resources.filter((res) => (res.rel === size) || (res.rel === 'icon'))
        .map((res) => ({ id: res.id, link: res.links.find((link) => link.rel === 'self') })),
);

export const selectAllCategoriesResourceLinksByScreenSizeDual = createSelector(
    selectScreenSizeDual,
    selectAllCategoriesResources,
    (size, resources) => resources.filter((res) => res.rel === size)
        .map((res) => ({ id: res.id, link: res.links.find((link) => link.rel === 'self') })),
);

export const selectCategoryBackgroundImage = (id: number) => createSelector(
    selectResourcesByIds([{ resourceType: 'categories', resourceId: id }]),
    (resources) => {
        try {
            const resource = resources.find((res) => res.rel === 'desktop');
            return resource.links.find((link) => link.rel === 'self').href;
        } catch (e) {
            return null;
        }
    },
);

export const selectResourcesByScreenSize = createSelector(
    selectAllResources,
    selectScreenSize,
    (resources, size) => resources.filter((resource) => resource.rel === size),
);

export const selectResourcesByScreenSizeDual = createSelector(
    selectAllResources,
    selectScreenSizeDual,
    (resources, size) => resources.filter((resource) => resource.rel === size),
);

export const selectAllCategoriesForDesktop = createSelector(
    selectAllCategories,
    selectAllCategoriesResourceLinksForDesktop,
    (categories, resources) => categories.map((cat) => {
        try {
            const resource = resources.find((res) => res.id === cat.id);
            return { ...cat, image: resource.link.href };
        } catch (e) {
            return { ...cat, image: 'assets/img/arrow.png' };
        }
    }),
);

export const selectAllCategoriesByScreenSizeDual = createSelector(
    selectAllCategories,
    selectAllCategoriesResourceLinksByScreenSizeDual,
    (categories, resources) => categories.map((cat) => {
        try {
            const resource = resources.find((res) => res.id === cat.id);
            return { ...cat, image: resource.link.href };
        } catch (e) {
            return { ...cat, image: 'assets/img/arrow.png' };
        }
    }),
);

export const selectAllCategoriesByScreenSize = createSelector(
    selectAllCategories,
    selectAllCategoriesResourceLinksByScreenSize,
    (categories, resources) => categories.map((cat) => {
        try {
            const resource = resources.find((res) => res.id === cat.id);
            return { ...cat, image: resource.link.href };
        } catch (e) {
            return cat; //, image: 'assets/img/arrow.png' };
        }
    }),
);
