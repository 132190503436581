import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ILevel } from '@shared/interfaces';
import { LevelsActions, LevelsActionTypes } from './levels.actions';

export interface LevelsState extends EntityState<ILevel> {
    loadedUrls: string[];
}

export const adapter: EntityAdapter<ILevel> =
    createEntityAdapter<ILevel>();

export const initialLevelsState: LevelsState = adapter.getInitialState({
    loadedUrls: [],
});

export function levelsReducer(state = initialLevelsState , action: LevelsActions): LevelsState {

    switch (action.type) {
    case LevelsActionTypes.LevelsLoaded:
        // adapter.removeAll(state);
        const urls = [...state.loadedUrls.filter((url) => url !== action.payload.url), action.payload.url];
        return adapter.setAll(action.payload.levels, { ...state, loadedUrls: urls });
//            return adapter.addMany(action.payload.levels, state);
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
