import { Direction, SortTypes, StatisticsType } from '@shared/enums';
import { IParam } from '@shared/interfaces';
import { Statistics } from '@shared/helpers/statistics.class';

/**
 * Array Helper Class
 * The class provides methods to manipulate an array
 */
export class ArrayHelper {

    static createParam = (attr: string, ids: Array<string | number | boolean> | (string | number | boolean)): IParam => {
        const arr = ids instanceof Array ? [...ids] : [ids];
        return {
            attr,
            ids: arr,
        };
    }

    static createParams = (attrs: string[], ids: Array<Array<string | number | boolean> | (string | number | boolean)>) => {
        return attrs.map((attr, i) => ArrayHelper.createParam(attr, ids[i]));
    }

    /**
     * Creates a unique array of values based on a specified attribute
     * @param T The type of the array elements
     * @param data The array of elements
     * @param attr The attribute to which extract the unique values
     * @example unique<IData>(data, 'year') generates an unsorted unique array of years, eg [2019, 2020, 2018])
     */
    static unique = <T>(data: T[], attr?: string) => {
        if (!Array.isArray(data)) { return []; }
        try {
            if (!attr) {
                return Array.from(new Set(data));
            } else {
                return Array.from(new Set(data.map((d: T) => d[attr])));
            }
        } catch (e) {
            return [];
        }
    }

    /**
     * Creates a unique array of values based on specified attributes
     * @param T The type of the array elements
     * @param data The array of elements
     * @param attrs The attributes to which extract the unique values
     * @example uniques<IData>(data, ['year', 'id']) generates an unsorted array of IData elements where the combination year-id is unique
     */
    static uniques = <T>(data: T[], attrs?: string[]) => {
        if (!Array.isArray(data)) { return []; }
        const id = (elem: T): string => attrs.reduce((res, curr) => res + '--' + elem[curr], '');
        try {
            if (!attrs) {
                return Array.from(new Set(data));
            } else {
                return Array.from(new Map(data.map((d: T) => [id(d), d])).values());
            }
        } catch (e) {
            return [];
        }
    }

    /**
     * Creates a unique sorted array of values based on a specified attribute
     * @param T The type of the array elements
     * @param {T[]} data The array of elements
     * @param {string} attr The attribute to which extract the unique values
     * @param {Direction} sorting The sorting type
     * @example unique<IData>(data, 'year', Direction.ASC) generates a sorted unique array of years, eg [2018, 2019, 2020])
     */
    static uniqueSorted = <T>(data: T[], attr: string, sorting: Direction) => {
        try {
            const unsorted = ArrayHelper.unique<T>(data, attr);

            return unsorted.sort((a, b) => a < b ? -sorting : sorting);
        } catch (e) {
            return [];
        }
    }

    /**
     *  Filter an array by a specified parameter
     * @param data: T[] - array to filter
     * @param param: IParam
     * @return T[]
     */
    static filterDataByParam = <T>(data: T[], param: IParam): T[] => {

        return data.filter((d) => param.ids.includes(d[param.attr]));
    }

    /**
     * Filter an array by multiple parameters
     * @type T: type of the input and return data
     * @param data: T[] - array to filter
     * @param params: IParam[]
     * @return T[]
     */
    static filterDataByMultipleParams = <T>(data: T[], params: IParam[]): T[] => {
        return params.reduce((fdata, param) => ArrayHelper.filterDataByParam<T>(fdata, param), data);
    }

    /**
     * Filter an array by multiple attributes
     * @type {T}: type of the input and return data
     * @param {T[]} data Array to filter
     * @param {string[]} attrs Array with all the parameters to filter
     * @param {Array<Array<string|number>|string|number>} ids An array containing the list of values to filter
     *          the values should be in the same order as the attrs
     * @return T[] Filtered array
     *
     * @example filterArrayByMultipleAttrs<IData>(data, ['variable_id', 'unit_id'], [[23, 25], 31])
     */
    static filterArrayByMultipleAttrs = <T>(data: T[], attrs: string[], ids: Array<Array<string|number>|string|number|boolean>): T[] => {
        try {
            if (!data) { return []; }
            const params = ArrayHelper.createParams(attrs, ids);
            return ArrayHelper.filterDataByMultipleParams<T>(data, params);
        } catch (e) {
            return [];
        }
    }

    static categorizeArray = <T>(data: T[], valueAttr: string, categoryAttr: string, type: StatisticsType, sortDirection: Direction = Direction.ASC) => {
        const categories = ArrayHelper.uniqueSorted<T>(data, categoryAttr, sortDirection);

        return categories.map((cat) => {
            const fdata = data.filter((d) => d[categoryAttr] === cat);
            const value = Statistics.calcStatByType<T>(fdata, valueAttr, type);
            return {
                id: cat,
                data: fdata,
                value,
            };
        });
    }

    static flatten = (arr: any[], propAsKey: string): any[] => {
        let obj = [];
        for (let key in arr) {
            if (!arr.hasOwnProperty(key)) {
                continue;
            }
            let item = arr[key];
            obj[item[propAsKey]] = item;
        }
        return Object.assign({}, obj);
    }

    /**
     * Toggles an element from an array
     * @type T the type of the array elements
     * @param {T[]} elements the array to modify
     * @param {T} elem the element to toggle
     * @return {T[]}
     */
    static toggleElement = <T>(elements: T[], elem: T): T[] => {
        if (elements.includes(elem)) {
            return elements.filter((c) => c !== elem );
        } else {
            return [...elements, elem];
        }
    }

    /**
     * Adds an element to an array, if still doesn't exists
     * @type T
     * @param elements
     * @param elem
     */
    static addElement = <T>(elements: T[], elem: T): T[] => {
        if (elements.includes(elem)) {
            return elements;
        } else {
            return [...elements, elem];
        }
    }

    /**
     * Removes an element from an array
     * @param elements
     * @param elem
     */
    static removeElement = <T>(elements: T[], elem: T): T[] => {
        return elements.filter(el => el !== elem);
    }

    /**
     * Ensures array to have only truthy values
     * @param rawArr
     */
    static ensureTruthyValues = <T>(rawArr: T[]): T[] => {
        let arr = [];
        for (let i = 0; i < rawArr.length; i++) {
            let item = rawArr[i];
            if (item) {
                arr.push(item);
            }
        }
        return arr;
    }

    static flattenMultiLevelArray = (arr, childrenAttr = 'children') => {
        if (!arr || arr.length === 0) { return []; }
        return arr.reduce((flatten, current) => {
            flatten.push(current);
            flatten.push(...ArrayHelper.flattenMultiLevelArray(current.children, childrenAttr));
            return flatten;
        }, []);
    }

    static getMultiLevelObjTotalLength = (obj, childrenAttr = 'children'): number => {
        if (!obj) { return 0; }
        if (!obj[childrenAttr] || !!obj[childrenAttr].length) return 1;
        return 1 + obj[childrenAttr].reduce((total, child) => total + ArrayHelper.getMultiLevelObjTotalLength(child, childrenAttr), 0);
    }

    static getMultiLevelObjLastLevelTotalLength = (obj, childrenAttr = 'children'): number => {
        if (!obj) { return 0; }
        if (Array.isArray(obj)) {
            return obj.reduce((total, elem) => {
                total += ArrayHelper.getMultiLevelObjLastLevelTotalLength(elem, childrenAttr);
                return total;
            }, 0);
        }
        if (!obj[childrenAttr] || !obj[childrenAttr].length) return 1;

        return obj[childrenAttr].reduce((total, child) => {
            total += ArrayHelper.getMultiLevelObjLastLevelTotalLength(child, childrenAttr);
            return total;
        }, 0);
    }

    static intersection = (array1, array2) => {
        return array1.filter((value) => array2.includes(value));
    }

    static intersects = (array1, array2): boolean => {
        return ArrayHelper.intersection(array1, array2).length > 0;
    }

    /**
     * Returns the sufix of a number
     * @param {number} ordinal - the number to get the sufix
     * @example: getOrdinalSufix(2) = 'nd'
     */
    static getOrdinalSufix = (ordinal: number): string => {
        if (!ordinal) { return null; }
        const comparator = (ordinal > 20) ? (ordinal % 10) : ordinal;
        switch (comparator) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }

    /**
     * Combines two arrays into an array with both elements returning only the intersection of the ids
     * @param first the first array to combine
     * @param second the second array to combine
     * @param firstIdAttr the key of the id in the first array
     * @param secondIdAttr the key of the id in the second array
     * @param firstLabel key of the first element in the combined result.
     * @default first
     * @param secondLabel key of the second element in the combined result
     * @default second
     * @example [{first: {id: 1, value: 5}, second: {id: 1, value: 8}}]
     */
    static combineCommonElements<T, U>(first: T[], second: U[], fristIdAttr: string, secondIdAttr: string, firstLabel = 'first', secondLabel = 'second') {

    }

    static MultipleSorter = <T>(a: T, b: T, attrs: string[], orders: SortTypes[]): number => {
        let attr: string;
        let order: SortTypes;
        let compare;

        if (!attrs.length) {
            return 0;
        } else {
            attr = attrs.shift();
            order = orders.shift();
            compare = order === SortTypes.ASC ? 1 : -1;
            if (a[attr] === b[attr]) {
                return ArrayHelper.MultipleSorter<T>(a, b, [...attrs], [...orders]);
            } else {
                return a[attr] < b[attr] ? -compare : compare;
            }
        }
    }

    /**
     * Sort an array by multiple criteria
     * @param array The array to be sorter
     * @param attrs The criteria to sort the array, entered sequencially
     * @param orders The orders of sorting for each property, entered sequencially, accordgin to attrs
     * @example ArrayHelper.SortByMultipleAttrs(data, ['order', 'name', 'value'], ['asc', 'asc','desc'])
     */
    static SortByMultipleAttrs = <T>(array: T[], attrs: string[], orders: SortTypes[]): T[] => {
        return array.sort((a, b) => ArrayHelper.MultipleSorter<T>(a, b, [...attrs], [...orders]));
    }

    /**
     * Extracts a property and returns an array of elements of that property
     * @param array
     * @param attr
     * @example pluck<IData>(data, 'year') returns a list of years
     */
    static pluck = <T>(array: T[], attr: string) => {
        return array.map((d) => d[attr]);
    }

    /**
     * Extracts a property and returns a unique array of elements of that property
     * @param array
     * @param attr
     * @example pluck<IData>(data, 'year') returns a unique list of years
     */
    static uniquePluck = <T>(array: T[], attr: string) => {
        return ArrayHelper.unique(ArrayHelper.pluck(array, attr));
    }

    /**
     * Plucks
     * Extract multiple properties from an array
     * @param array Source of the data
     * @param attrs An array with the properties names to extract
     * @return .{[_attr: string]: any}[]
     * @example plucks<IData>(array, ['year','id'] = {'year','id'}[]
     */
    static plucks = <T>(array: T[], attrs: string[]) => {
        return array.map((d) => {
            return attrs.reduce((elem, curr) => ({
                ...elem,
                [curr]: d[curr]
            }), {})
        });
    }

    static GroupBy = <T>(array: T[], groupAttr: string, groupName= 'elements', pluckAttrs?: string[]) => {
        let pluck;
        if (!pluckAttrs) {
            pluck = (elem) => elem;
        } else if (pluckAttrs.length === 1) {
            pluck = (elem) => elem[pluckAttrs[0]];
        } else {
            pluck = (elem) => ArrayHelper.plucks([elem], pluckAttrs)[0];
        }
        const groups = new Map(ArrayHelper.uniquePluck(array, groupAttr).map((d) => [d, { [groupAttr]: d, [groupName]: [] }]));
        for (const elem of array) {
            groups.get(elem[groupAttr])[groupName].push(pluck(elem));
        }

        return Array.from(groups.values());
    }

    /**
     * Filters a nested array returning only parents with children
     * Filter is applied to the last level (elements with no children)
     * If attrs anf values lengths are different, returns array
     * @param array
     * @param attrs List of attributes to check
     * @param values List of values matching the attributes (same order and length)
     */
    static filterNestedArray = <T extends {children?: any}>(array: T[], attrs: string[], values: any[]): T[] => {
        if (attrs?.length !== values?.length) {
            return array;
        }

        const check = (elem) => attrs.reduce((res, attr, i) => res && elem[attr] === values[i], true );

        const filter = (elem: T) => {
            if (elem.hasOwnProperty('children')) {
                const children = elem.children.map((child) => filter(child)).filter((child) => child);
                return children?.length > 0 ? { ...elem, children } : null;
            } else {
                return check(elem) && elem || null;
            }
        }

        return array.map((elem) => filter(elem)).filter((elem) => elem);
    }

    static isIterable = (obj: any): boolean => {
        // checks for null and undefined
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    }

}
