import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IVariableGroup } from '@shared/interfaces';
import { VariableGroupsActions, VariableGroupsActionTypes } from './variable-groups.actions';

export interface VariableGroupsState extends EntityState<IVariableGroup> {
}

export const adapter: EntityAdapter<IVariableGroup> =
    createEntityAdapter<IVariableGroup>({});

export const initialVariableGroupsState: VariableGroupsState = adapter.getInitialState({
});

export function variableGroupsReducer(state = initialVariableGroupsState , action: VariableGroupsActions): VariableGroupsState {

    switch (action.type) {
    case VariableGroupsActionTypes.VariableGroupsLoaded:
        // adapter.removeAll(state);
        return adapter.setAll(action.payload.variableGroups, state);
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
