import {CanActivate, NavigationEnd, ResolveData, Route, Router} from '@angular/router';
import { AppBreadcrumbRouteTypes, IAppBreadcrumbSetting } from '../../app-breadcrumb.service';
import { ContentHelpers } from '@shared/helpers/content.helpers';
import { Slug } from 'ng2-slugify';
import {selectCmsActiveContent, selectCmsPageIsLoaded} from "@features/general/cms/state/cms.selectors";
import {map, mergeMap, withLatestFrom} from "rxjs/operators";
import {CmsPageRequested} from "@features/general/cms/state/cms.actions";
import {Observable, of} from "rxjs";
import {DataHubRoutes} from "@features/data-hub/data-hub-routes";
// import {select} from "d3";
import {AppState} from "../../core/reducers";
import { Store, select } from '@ngrx/store';

export class RouterHelper {
    protected static routesClass = null;
    protected static MAIN_URL: string = null;
    protected static CMS_SITE: string = null;
    protected static routes;

    /**
     * @throws Error
     */
    public static generate(route: string): string {
        const routes = route.split('/');
        const lastRoute = routes[routes.length - 1];
        if (!this.getRoutesList().includes(lastRoute)) {
            throw new Error('Route requested doesn\'t exist.');
        }
        const url = this.MAIN_URL.length ? '/' + this.MAIN_URL : '';
        return url + '/' + route;
    }

    /**
     * @throws Error
     */
    public static generateRouterLink(route: string, params: string[]|object[]|any = []): string[] {
        const arr = this.routesClass ? [this.routesClass.generate(route)] : [route];
        for (let i = 0; i < params.length; i++) {
            let param = params[i];

            let slugifyNeeded = true;
            if (typeof param === 'object') {
                // @ts-ignore
                if (param.hasOwnProperty('slugify') && ! param.slugify) {
                    slugifyNeeded = false;
                }
                // @ts-ignore
                param = <string>param.value;
            }
            if (slugifyNeeded) {
                param = RouterHelper.slugify(param);
            }
            arr.push(param);
        }
        return arr;
    }

    public static slugify(string: string): string {
        return (new Slug('default')).slugify(ContentHelpers.normalize(string));
    }

    protected static getRoutesList(): string[] {
        const arr = Object.values(this.routesClass.routes);
        arr.push(this.routesClass.MAIN_URL);
        return arr.map((value) => value.toString());
    }

    /**
     * @param {string} path
     * @param {object} component
     * @param {CanActivate[]} canActivate
     * @param {object}data
     * @param children
     * @param {ResolveData} resolve
     * @param outlet
     */
    public static generateRouteObj(
        path: string,
        component: any,
        canActivate: any[] = [],
        data: any = null,
        children: any[] = null,
        resolve: ResolveData|null|undefined = null,
        outlet: any = null,
    ): Route {
        return {
            path,
            component,
            canActivate,
            data: RouterHelper.prepareRouteData(path, data, this.MAIN_URL),
            children,
            resolve,
            outlet
        } as Route;
    }

    /**
     * @param {string} path
     * @param {object|null} data
     * @param {string} mainUrl
     */
    public static prepareRouteData(path: string, data, mainUrl: string) {
        data = !data ? {} : data;
        if (!data.breadcrumb) {
            // data.breadcrumb = path.split('/').filter((part) => part.length > 0).length > 1 ?
            //     AppBreadcrumbRouteTypes.SPLIT : AppBreadcrumbRouteTypes.DEFAULT;
            data.breadcrumb = AppBreadcrumbRouteTypes.SPLIT;
        }
        let parts = path.split('/');
        let type = data.breadcrumb;
        let generateAlone = true;
        let innerTypes = [];
        let replace = null;
        if (typeof data.breadcrumb === 'object') {
            type = data.breadcrumb.type;
            innerTypes = data.breadcrumb.innerTypes;
            replace = data.breadcrumb.replace;
            if (data.breadcrumb.generateAlone) {
                generateAlone = data.breadcrumb.generateAlone;
            }
        }
        switch (type) {
            case AppBreadcrumbRouteTypes.SPLIT:
                const newParts = [];
                for (let i = 0; i < parts.length; i++) {
                    let part = parts[i];

                    if ((part === ':code' && parts[i + 1] === ':slugifiedTitle')
                        || (part === ':unit' && parts[i + 1] === ':slugifiedTitle')
                    ) {
                        delete parts[i];
                        continue;
                    }
                    if (replace) {
                        for (const replacement of replace) {
                            if (replacement[0] === part) {
                                part = replacement[1];
                                break;
                            }
                        }
                    }
                    newParts.push(part);
                }
                parts = newParts;
                data.breadcrumb = {
                    type,
                    value: parts.map((item) => !item.startsWith(':') ? mainUrl + '.page.' + item : item).join('/'),
                    generateAlone,
                    innerTypes,
                    replace
                } as IAppBreadcrumbSetting;
                break;
        }
        return data;
    }

    public static CMSProfilePageGuard(
        store: Store<AppState>,
        router: Router,

        cmsPage: string,
        cmsParams: {[_: string]: any},
        urlParams: {[_: string]: any},

        moduleKey?: string,
        moduleTitleProperty?: string,
        redirectUrl?: string
    ) {
        try {
            return store.pipe(
                select(selectCmsPageIsLoaded(cmsPage, cmsParams)),
                map((loaded: boolean) => {
                    if (!loaded) {
                        store.dispatch(new CmsPageRequested({ page: cmsPage, params: cmsParams }));
                    }
                    return loaded;
                }),
                // filter((loaded: any) => !!loaded),
                // take(1)
                mergeMap((payload) => of(payload)
                    .pipe(
                        withLatestFrom(store.select(selectCmsActiveContent)),
                        mergeMap(([payload , activeContent]) => {
                            let isContentOk = !!activeContent;

                            // Check if slugified title is correct
                            if (activeContent && urlParams.slugifiedTitle) {
                                let module = activeContent.modules.find((module) => module.api.hasOwnProperty(moduleKey));
                                // @ts-ignore
                                module = module.api[moduleKey];
                                isContentOk = RouterHelper.slugify(module[moduleTitleProperty]) === urlParams.slugifiedTitle;

                                if (! isContentOk) {
                                    router.navigate([redirectUrl]);
                                    return;
                                }
                            }

                            return new Observable((observer) => {
                                if (isContentOk) {
                                    observer.next(true);
                                    observer.complete();
                                }
                            });
                        })
                    )
                )
            );
        } catch (e) {
            return of(false);
        }
    }
}

/**
 * @param {Router} router
 * @param {Object} s
 *
 * @Example
 * <a [routerLink]="[C3Routes.generate(C3Routes.routes.FAQ)]" fragment="question_3_16" target="_self">3.16</a>
 *
 * Where 'fragment' is the id of element you want to anchor to
 */
export const anchorScroll = (router: Router, s: any) => {
    if (s instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        if (tree.fragment) {
            const element = document.querySelector('#' + tree.fragment);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
};
