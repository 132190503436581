export const environment = {
    title: "_noProxProd",
    showEnvTitleOnLogo: true,
    configFilePath: "assets/openid-login-config.json",
    production: false,
    enableDevToolRedux: true,
    apiUrl: "https://coin-backend-api-2-main-coin.apps.ocp.jrc.ec.europa.eu/api/v1/{{locale}}/",
    cmsApiUrl: "https://coin-backend-api-2-main-coin.apps.ocp.jrc.ec.europa.eu/api/v1/{{locale}}/cms",
    i18nUrl: "https://coin-backend-api-2-main-coin.apps.ocp.jrc.ec.europa.eu/api/v1/{{locale}}/cms/i18n",
    cmsStorageUrl: "https://coin-frontend-cointools-staging-main-coin.apps.ocp.jrc.ec.europa.eu/assets/storage",
    enableAnalyticsTracking: false,
    matomoUrl: null,
    matomoSiteId: null,
    enableGoogleAnalyticsTracking: false,
    googleAnalyticsSiteId: null,
};
