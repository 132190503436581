import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ICmsItem } from '@features/general/cms/structure';

export function selectCmsId(page: ICmsItem): string {
    const params = Object.keys(page.map).reduce((res, elem) => res += '_' + page.map[elem], '');
    return page.page_type + params;
}

export const cmsAdapter: EntityAdapter<ICmsItem> =
    createEntityAdapter<ICmsItem>({
        selectId: selectCmsId
    });

export interface CmsState extends EntityState<ICmsItem>{
}
