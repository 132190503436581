import { Injectable } from '@angular/core';
import { ICategoryGroup } from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';

@Injectable()
export class CategoryGroupsService extends SharedService {
    private groupsLink = this.getApiUrl() + 'category-groups';

    loadCategoryGroups(): Observable<ICategoryGroup[]> {
        return this.getByUrl<ICategoryGroup[]>(this.groupsLink);
    }

    loadCategoryGroup(id: number): Observable<ICategoryGroup> {
        return this.getByUrl<ICategoryGroup>(this.groupsLink + '/' + id);
    }

}
