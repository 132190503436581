import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CoinStatus } from '@features/composite-indicator/state/status/composite-indicators.reducer';
import { IComponent } from '@shared/interfaces';
import { ComponentsActions, ComponentsActionTypes } from './components.actions';

export interface ComponentsState extends EntityState<IComponent> {}

export const adapter: EntityAdapter<IComponent> = createEntityAdapter<IComponent>();

export const initialComponentsState: ComponentsState = adapter.getInitialState();

export function componentsReducer(state = initialComponentsState , action: ComponentsActions): ComponentsState {

    switch (action.type) {
    case ComponentsActionTypes.ComponentsLoaded:
        // adapter.removeAll(state);
        return adapter.setAll(action.payload.components, state);

    case ComponentsActionTypes.UpdateComponent:
        return adapter.updateOne({ id: action.payload.component.id, changes: action.payload.component }, state);

    default:
        return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
