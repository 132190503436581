<div class="app-slider">
    <mat-slider
        [min]="data.weight.min"
        [max]="data.weight.max"
        [step]="0.001"

        [displayWith]="formatLabel"
        (change)="sliderChange($event)"
        (mouseup)="changeValue()"
        (mousedown)="mouseDown($event)"
    ></mat-slider>
<!--    [value]="data.weight.weight"-->
<!--    [thumbLabel]="config.thumbLabel"-->
</div>
