export const environment = {
    title: 'production',
    showEnvTitleOnLogo: false,
    configFilePath: 'assets/openid-login-config.json',
    production: true,
    enableDevToolRedux: false,
    apiUrl: 'https://composite-indicators.jrc.ec.europa.eu/api/v1/{{locale}}/',
    cmsApiUrl: 'https://composite-indicators.jrc.ec.europa.eu/api/v1/{{locale}}/cms',
    i18nUrl: 'https://composite-indicators.jrc.ec.europa.eu/api/v1/{{locale}}/cms/i18n',
    cmsStorageUrl: 'https://composite-indicators.jrc.ec.europa.eu/assets/storage',
    enableAnalyticsTracking: true,
    matomoUrl: 'https://stats.jrc.ec.europa.eu/',
    matomoSiteId: 19,
    enableGoogleAnalyticsTracking: false,
    googleAnalyticsSiteId: null
};
