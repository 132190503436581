import { Injectable } from '@angular/core';
import { ILevel } from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';

@Injectable()
export class LevelsService extends SharedService {

    getLevelsByUrl(url: string): Observable<ILevel[]> {
        return this.getByUrl<ILevel[]>(url);
    }

}
