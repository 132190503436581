export class UrlHelpers {

    /**
     * @param url
     * @param isExternal
     */
    public static ensureHttpProtocol = (url: string, isExternal = true): string => {
        let completeUrl = url;
        if (isExternal && url) {
            completeUrl = url.indexOf('http') !== 0 ? 'http://' + url : completeUrl;
        }
        return completeUrl;
    }
}
