import { ItemTypesCOINAPI } from '@shared/enums';

export const CmsChartsRouterTypes = new Map([
    [
        'countries',
        ItemTypesCOINAPI.COUNTRY,
    ],
    [
        'cities',
        ItemTypesCOINAPI.CITY
    ],
    [
        'countries-and-regions',
        ItemTypesCOINAPI.COUNTRY
    ],
    [
        'countries-and-territories',
        ItemTypesCOINAPI.COUNTRY
    ]
]);

export enum CmsChartsCategoriesTypes {
    main = 'main',
    series = 'series'
}

export enum CmsChartsCategoriesDefaultIds {
    primary = 'main_0'
}

export enum CmsChartsSetParamsStrategy {
    add = 'add',
    remove = 'remove',
    toggle = 'toggle',
    set = 'set as active',
    nodeps = 'set without add/remove copies',
    default = 'default'
}

export enum CmsChartLoadStrategies {
    none = 'none',
    first = 'first',
    firstequal = 'first-equal',
    last = 'last',
    lastequal = 'last-equal',
    origin = 'origin',
    random = 'random',
    all = 'all'
}

export type CmsChartLoadStrategy = CmsChartLoadStrategies.none
    | CmsChartLoadStrategies.first
    | CmsChartLoadStrategies.last
    | CmsChartLoadStrategies.origin
    | CmsChartLoadStrategies.random;

export enum CmsChartsDataSourceTypes {
    dataIndices = 'indices',
    dataComponents = 'components',
    dataMainComponents = 'main-components',
    dataUnits = 'units',
    dataComponentsUnits = 'components-units',
    dataMainComponentsUnits = 'main-components-units',
    statistics = 'statistics',
    statisticsByComponents = 'statistics-components',
    statisticsByMainComponents = 'statistics-main-components',
    statisticsByIndices = 'statistics-indices',
    correlationMatrix = 'correlation-matrix',
    unit_comparison_values_full_framework = 'unit-comparison-values-full-framework',
    dataUnitsWithBreakdowns = 'values-by-unit-breakdowns',
    frameworkWithDataByUnit = 'framework-with-data-by-unit',
    dataUnitYearsByBreakdown = 'data-unit-year-breakdown'
}

export enum CmsChartsCategoriesSourceTypes {
    indices = 'indices',
    components = 'components',
    componentsAltSort = 'components-alt-sort',
    mainComponents = 'main-components',
    mainComponentsAltSort = 'main-components-alt-sort',
    units = 'units',
    unitsPerType = 'units-per-type',
    unitsPerIndex = 'units-per-index',
    yearsPerIndex = 'years-per-index',
    yearsPerBreakdown = 'years-per-breakdown'
}

export enum CmsChartsLoadingState {
    loading = 'loading',
    initial = 'initial',
    loaded = 'loaded',
    error = 'error',
    nodata = 'nodata',
    awaiting = 'awaiting inputs'
}

export enum CmsChartsFiltersDisplay {
    hidden = 'hidden',
    loading = 'loading',
    enabled = 'enabled',
    disable = 'disabled',
    error = 'error'
}

export const CmsChartsApiCategoriesRoutes = new Map<CmsChartsCategoriesSourceTypes, any>([
    [
        CmsChartsCategoriesSourceTypes.indices,
        {
            route: (params = {}) => 'chart/data?method=categoryIndices&transform=ChartDataCategoryResource',
            rules: []
        }
    ],
    [
        CmsChartsCategoriesSourceTypes.components,
        {
            route: (params = {}) => 'chart/data?method=categoryComponents&transform=ChartDataCategoryResource',
            rules: []
        }
    ],
    [
        CmsChartsCategoriesSourceTypes.componentsAltSort,
        {
            route: (params = {}) => 'chart/data?method=categoryComponents&transform=ChartDataCategoryResource&orderby=hierarchy',
            rules: []
        }
    ],
    [
        CmsChartsCategoriesSourceTypes.mainComponents,
        {
            route: (params = {}) => 'chart/data?method=categoryComponents&transform=ChartDataCategoryResource&main=1',
            rules: []
        }
    ],
    [
        CmsChartsCategoriesSourceTypes.mainComponentsAltSort,
        {
            route: (params = {}) => 'chart/data?method=categoryComponents&transform=ChartDataCategoryResource&main=1&orderby=hierarchy',
            rules: []
        }
    ],
    [
        CmsChartsCategoriesSourceTypes.units,
        {
            route: (params: any = {}) => `chart/data?method=categoryUnits&transform=ChartDataCategoryUnits`,
            rules: []
        }
    ],
    [
        CmsChartsCategoriesSourceTypes.unitsPerType,
        {
            route: (params: any = {}) => `units/type/${params.unit_type}`,
            rules: ['unit_type']
        }
    ],
    [
        CmsChartsCategoriesSourceTypes.yearsPerIndex,
        {
            route: (params: any = {}) => `/list/indice/${params.index_code}/years`,
            rules: ['index_code']
        }
    ],
    [
        CmsChartsCategoriesSourceTypes.unitsPerIndex,
        {
            route: (params: any = {}) => `/list/indice/${params.index_code}/units`,
            rules: ['index_code']
        }
    ]
]);

export const CmsChartsApiDataRoutes = new Map([
    [
        CmsChartsDataSourceTypes.dataIndices,
        {
            route: (params: any = {}) => 'chart/data?method=dataIndices&transform=ChartDataResource',
            rules: []
        }
    ],
    [
        CmsChartsDataSourceTypes.dataComponents,
        {
            route: (params: any = {}) => 'chart/data?method=dataComponents&transform=ChartDataResource',
            rules: []
        }
    ],
    [
        CmsChartsDataSourceTypes.dataMainComponents,
        {
            route: (params: any = {}) => 'chart/data?method=dataComponents&transform=ChartDataResource&main=1',
            rules: []
        }
    ],
    [
        CmsChartsDataSourceTypes.dataUnits,
        {
            route: (params: any = {}) => 'chart/data?method=dataUnits&transform=ChartDataResource',
            rules: []
        }
    ],
    [
        CmsChartsDataSourceTypes.dataComponentsUnits,
        {
            route: (params: any = {}) => 'chart/data?method=dataComponentsUnits&transform=ChartDataComponentUnitResource',
            rules: []
        }
    ],
    [
        CmsChartsDataSourceTypes.dataMainComponentsUnits,
        {
            route: (params: any = {}) => 'chart/data?method=dataComponentsUnits&transform=ChartDataComponentUnitResource&main=1',
            rules: []
        }
    ],
    [
        CmsChartsDataSourceTypes.statistics,
        {
            route: (params: any = {}) => 'chart/data?method=statistics&transform=ChartStatisticsResource',
            rules: []
        }
    ],
    [
        CmsChartsDataSourceTypes.statisticsByComponents,
        {
            route: (params: any = {}) => 'chart/data?method=statistics&transform=ChartStatisticsKeyValueResource',
            rules: []
        }
    ],
    [
        CmsChartsDataSourceTypes.statisticsByMainComponents,
        {
            route: (params: any = {}) => 'chart/data?method=statistics&transform=ChartStatisticsKeyValueResource&main=1',
            rules: []
        }
    ],
    [
        CmsChartsDataSourceTypes.statisticsByIndices,
        {
            route: (params: any = {}) => 'chart/data?method=statisticsIndex&transform=ChartStatisticsKeyValueResource',
            rules: []
        }
    ],
    [
        CmsChartsDataSourceTypes.correlationMatrix,
        {
            route: (params: any = {}) => 'chart/pearson?main=1',
            rules: []
        }
    ],
    [
        CmsChartsDataSourceTypes.unit_comparison_values_full_framework,
        {
            route: (params: any = {}) => `components/${params.index_code}/profile?year=${params.year}&type_id=${params.data_type_id}&compare_type_id=${params.compare_type_id}&units=${params.unit}`,
            rules: ['index_code', 'year', 'data_type_id', 'compare_type_id', 'unit']
        }
    ],
    [
        CmsChartsDataSourceTypes.dataUnitsWithBreakdowns,
        {
            route: (params: any = {}) => {
                return `chart/data-by-vgroups?component=${params.component}&data_type_id=${params.data_type_id}&year=${params.year}`;
            },
            rules: ['component', 'year', 'data_type_id']
        }
    ],
    [
        CmsChartsDataSourceTypes.frameworkWithDataByUnit,
        {
            route: (params: any = {}) => {
                return `indice/${params.index_code}/data-with-breakdowns?unit=${params.unit}`;
            },
            rules: ['index_code', 'unit']
        }
    ],
    [
        CmsChartsDataSourceTypes.dataUnitYearsByBreakdown,
        {
            route: (params: any = {}) => `component/${params.component}/${params.breakdown}/countries-data?type_id=${params.type_id}`,
            rules: ['component', 'breakdown', 'type_id']
        }
    ]
]);
