import { Injectable } from '@angular/core';
import {SharedService} from "@shared/shared.service";
import {EnvironmentType, RescaledModelDefault, StatisticsType} from "@shared/enums/app.enums";
import {Observable} from "rxjs";
import {IAnswer, IStatistic} from "@shared/interfaces";

@Injectable()
export class StatisticsService extends SharedService{

    private getType(type: StatisticsType): string {
        switch(type) {
            case StatisticsType.Median:
                return 'median';
            default:
                return 'median';
        }
    }

    private getEnvironment(environment: EnvironmentType): string {
        switch (environment) {
            case EnvironmentType.EU:
                return 'eu';
            case EnvironmentType.INT:
                return '';
            default:
                return '';
        }
    }

    private getStatType(type: StatisticsType, environment: EnvironmentType): string {
        return this.getType(type) + this.getEnvironment(environment);
    }

    makeUrlForStatisticsRequestedByType(type: StatisticsType, environment: EnvironmentType): string {
        return this.getApiUrl() + `statistics/${this.getStatType(type, environment)}`;
    }

    makeUrlForStatisticsRescaledRequestedByType(type: StatisticsType, environment: EnvironmentType, min?: number, max?: number): string {
        min = min || RescaledModelDefault.min;
        max = max || RescaledModelDefault.max;

        return this.getApiUrl() + `statistics/${this.getStatType(type, environment)}`; ///rescaled/${min}/${max}`;
    }

    getStatisticsByUrl(url: string): Observable<IStatistic[]> {
        return this.getByUrl<IStatistic[]>(url);
    }
}
