import { ScreenState } from '../interfaces';
import { ScreenStateActions, ScreenStateActionTypes } from './screen.actions';

const initialState: ScreenState = {
    mobile: false,
    tablet: false,
    desktop: false,
};

export function screenStateReducer(state: ScreenState = initialState, action: ScreenStateActions): ScreenState {
    switch (action.type) {
    case ScreenStateActionTypes.SET_SCREEN:
        return { ...state, ...action.payload };
    default:
        return state;
    }

}
