
import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    selectAllLevelsIdsByLevel,
    selectEditionLevelByLevel,
    selectLevelsMainIds
} from '../levels/levels.selectors';
import * as fromComponents from './components.reducers';

import { selectAllResources } from '@features/composite-indicator/state/resources/resources.selectors';
import { filterArrayByMultipleAttrs } from '@shared/helpers/array.helpers';
import { IComponent, IResource } from '@shared/interfaces';
import { CoinState } from '../index';

import * as helpers from '@shared/helpers';

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectComponentsState = createSelector(
    selectCoinState,
    (state) => state.components,
);

export const selectAllComponents = createSelector(
    selectComponentsState,
    fromComponents.selectAll,
);

export const selectAllComponentsByLevel = (level: number) => createSelector(
    selectAllComponents,
    selectAllLevelsIdsByLevel(level),
    (components, ids) => components.filter((c) => ids.includes(c.level_id))
);

export const selectAllMainComponents = createSelector(
    selectAllComponents,
    selectLevelsMainIds,
    (components, levelids) => {
        return helpers.filterArrayByMultipleAttrs<IComponent>(components, ['level_id'], [levelids]);
    }
);

export const selectEditionComponents = (indice: string, edition: number) => createSelector(
    selectAllComponents,
    (components) => helpers.filterArrayByMultipleAttrs<IComponent>(components, ['indice', 'edition'], [indice, edition])
);

export const selectComponentById = (id: number) => createSelector(
    selectAllComponents,
    (components) => components.find((comp) => comp.id === id),
);

export const selectComponentsByLevel = (indice: string, edition: number, level: number) => createSelector(
    selectEditionComponents(indice, edition),
    selectEditionLevelByLevel(indice, edition, level),
    (components, lvl) => {
        try {
            return components.filter((comp) => comp.level_id === lvl.id);
        } catch (err) {
            return [];
        }
    },
);

export const selectVariableIdsByLevel = (indice: string, edition: number, level: number) => createSelector(
    selectComponentsByLevel(indice, edition, level),
    (components) => components.map((comp) => comp.variable_id),
);

export const selectVariableIdsByEdition = (indice: string, edition: number) => createSelector(
    selectEditionComponents(indice, edition),
    (components) => components.map((component) => component.variable_id),
);

export const selectComponentsByParentId = (indice: string, edition: number, parentId: number) => createSelector(
    selectEditionComponents(indice, edition),
    (components) => components.filter((comp) => comp.parent_id === parentId),
);

export const selectVariablesIdsByParentId = (indice: string, edition: number, parentId: number) => createSelector(
    selectComponentsByParentId(indice, edition, parentId),
    (components) => components.map((comp) => comp.variable_id),
);

export const selectComponentsByParams = (params: Partial<IComponent>) => createSelector(
    selectAllComponents,
        (components) => {
        const attrs = Object.keys(params);
        const values = Object.values(params) as any[];
        return filterArrayByMultipleAttrs<IComponent>(components, attrs, values);
        }
);

/**
 * Get all the resources relating to components
 */
export const selectAllComponentsResources = createSelector(
    selectAllResources,
    (resources) => resources.filter((r) => r.resourceType === 'components')
);

/**
 * Get all the components icons available
 * @return {IResource[]}
 */
export const selectAllComponentsIcons = createSelector(
    selectAllResources,
    (resources) => resources.filter((r) => r.resourceType === 'components' && r.rel === 'icon')
);

/**
 * Get all the components extended with icon
 * @return {IComponent[]}
 */
export const selectAllComponentsWithIcon = createSelector(
    selectAllComponents,
    selectAllComponentsIcons,
    (components, icons) => components.map((c) => {
        let icon: string;
        try {
            icon = icons.find((r) => r.id === c.id).links.find((l) => l.rel === 'self').href;
        } catch (e) {
            icon = null;
        }
        return { ...c, icon };
    }),
);
