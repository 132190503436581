import {
    IScale,
    IScenario,
    IScenarioComponent,
    IScenarioData,
    IScenarioUnit,
    IScenarioScaledNode, IComponent, IData
} from "@shared/interfaces";
import {ComponentHelpers} from "@shared/helpers/component.helpers";
import {DataHelpers} from "@shared/helpers/data.helpers";
import {ScaleHelpers} from "@shared/helpers/scales.helpers";
import {ComponentLevels} from "@shared/enums/app.enums";
import { Scale } from '@shared/models/scale.model';

export class ScenarioHelpers {

    static getUnitScenarios(scenarios: IScenario[], unit: IScenarioUnit): IScenario[] {
        return scenarios && unit ? scenarios.filter((scenario) => scenario.unit_id === unit.id) : [];
    }

    static getScenarioData(scenario: IScenario, data: IScenarioData[]): IScenarioData[] {
        return scenario && data ? data.filter((dataItem) => dataItem.scenario_id === scenario.id) : [];
    }

    static getScenarioComponents(scenario: IScenario, components: IScenarioComponent[]): IScenarioComponent[] {
        return scenario && components ? components.filter((component) => component.scenario_id === scenario.id) : [];
    }

    static getScenarioScales(scenario: IScenario, scales: IScale[]): IScale[] {
        return scenario && scales ? scales.filter((scale) => scale.indice === scenario.indice && scale.edition === scenario.edition) : null;
    }

    static createScaledScenarioTree = (components: IScenarioComponent[], data: IScenarioData[], scales: IScale[]): IScenarioScaledNode[] => {
        const createNode = (component: IScenarioComponent, level: number): IScenarioScaledNode => {
            return {
                component,
                data: ScenarioHelpers.getScenarioComponentData(data, component),
                scale: new Scale(ScaleHelpers.getComponentScale(scales, component)),
                level,
                order: component.order,
                children: ScenarioHelpers.prepareScenarioComponentChildComponentsList(components, component)
                    .map((c) => createNode(c, level + 1)),
            };
        };

        let root = ScenarioHelpers.filterTopLevelScenarioComponents(components);

        return root ? root.map((component) => createNode(component, ComponentLevels.INDEX)) : [];
    }

    static filterTopLevelScenarioComponents(components: IScenarioComponent[]): IScenarioComponent[] {
        return ComponentHelpers.filterTopLevelComponents(components) as IScenarioComponent[];
    }

    static getScenarioComponentData(data: IScenarioData[], component: IComponent): IScenarioData {
        return DataHelpers.getComponentData(data, component) as IScenarioData;
    }

    static prepareScenarioComponentChildComponentsList(components: IScenarioComponent[], component: IScenarioComponent): IScenarioComponent[] {
        return ComponentHelpers.prepareComponentChildComponentsList(components, component) as IScenarioComponent[];
    }
}
