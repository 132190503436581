export const environment = {
    title: 'ngrok2',
    showEnvTitleOnLogo: true,
    configFilePath: 'assets/openid-login-config.json',
    production: false,
    enableDevToolRedux: true,
    apiUrl: 'https://coin-frontend-cointools-main-coin.apps.ocpt.jrc.cec.eu.int/api/v1/{{locale}}/',
    cmsApiUrl: 'https://coin-frontend-cointools-main-coin.apps.ocpt.jrc.cec.eu.int/api/v1/{{locale}}/cms',
    i18nUrl: 'https://coin-frontend-cointools-main-coin.apps.ocpt.jrc.cec.eu.int/api/v1/{{locale}}/cms/i18n',
    cmsStorageUrl: 'https://coin-frontend-cointools-main-coin.apps.ocpt.jrc.cec.eu.int/assets/storage',
    enableAnalyticsTracking: false,
    matomoUrl: null,
    matomoSiteId: null,
    enableGoogleAnalyticsTracking: false,
    googleAnalyticsSiteId: null,
};
