import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CmsService } from '@features/general/cms/state/cms.service';
import { ICmsPage } from '@features/general/cms/structure';
import { ContentHelpers } from '@shared/helpers/content.helpers';
import { BreadCrumb, ICategory } from '@shared/interfaces';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppBreadcrumbService {
    private cmsPage: ICmsPage;
    private customLastBreadcrumb: string;

    constructor(
        public translateService: TranslateService,
        private route: ActivatedRoute,
        private http: HttpClient,
        private cmsService: CmsService
    ) {
    }

    setCmsPage(data: ICmsPage) {
        this.cmsPage = data;
    }

    setCustomLastBreadcrumb(str?: string) {
        this.customLastBreadcrumb = str;
    }

    generate(): BreadCrumb[] {
        let breadcrumbs = this._buildBreadCrumbs(this.route.root, '', []);

        // Handle last item
        if (breadcrumbs.length > 0) {
            const lastItem = breadcrumbs[breadcrumbs.length - 1];
            lastItem.active = true;
            breadcrumbs = breadcrumbs.filter((b) => b.label !== lastItem.label);
            if (this.customLastBreadcrumb) {
                lastItem.label = this.translateService.instant(this.customLastBreadcrumb);
            } else if (!lastItem.label.length && this.cmsPage) {
                lastItem.label = this.translateService.instant(this.cmsPage.title);
            }
            breadcrumbs.push(lastItem);

            // Add ecl.footer.EUROPEAN-COMMISSION in the front off all breadcrumbs
            const firstItem = { ...lastItem };
            firstItem.label = this.translateService.instant('ecl.footer.EUROPEAN-COMMISSION');
            firstItem.routerLink = null;
            firstItem.queryParams = null;
            firstItem.active = false;
            firstItem.href = 'https://ec.europa.eu/info/index_en';
            breadcrumbs.unshift(firstItem);
        }
        return breadcrumbs;
    }

    private _buildBreadCrumbs(route: ActivatedRoute, url: string = '', breadcrumbs = []) {
        let newBreadcrumbs = breadcrumbs;
        let label = '';
        let path = '';
        let nextUrl = url;
        let isBreadcrumbAdded = false;
        if (route.routeConfig) {
            if (route.routeConfig.data) {
                if (route.routeConfig.data[ 'breadcrumb' ]) {
                    const data = route.routeConfig.data[ 'breadcrumb' ];
                    let isDefault = false;
                    if (typeof(data) !== 'string') {
                        const setting: IAppBreadcrumbSetting = data;
                        if (setting.generateAlone === false && !route.firstChild) {
                            return [];
                        }
                        switch (setting.type) {
                            case AppBreadcrumbRouteTypes.DEFAULT:
                                isDefault = true;
                                break;
                            case AppBreadcrumbRouteTypes.SPLIT:
                                const valuesArr = setting.value.split('/');
                                let routesArr = route.routeConfig.path.split('/');

                                if (setting.replace) {
                                    const arr = [];
                                    for (let routesArrItem of routesArr) {
                                        for (const replacement of setting.replace) {
                                            if (replacement[0] === routesArrItem) {
                                                routesArrItem = replacement[1];
                                                break;
                                            }
                                        }
                                        arr.push(routesArrItem);
                                    }
                                    routesArr = arr;
                                }

                                if (valuesArr.length !== routesArr.length) {
                                    throwError('Breadcrumb setting value is different length than the route, when splitting by "/"');
                                }

                                for (let i = 0; i < valuesArr.length; i++) {
                                    let value = valuesArr[i];

                                    if (! value) {
                                        continue;
                                    }
                                    let isCustomValue = false;
                                    const customRoute = null;
                                    let customHref = null;
                                    let queryParams = {};

                                    if (setting.innerTypes && setting.innerTypes.length) {
                                        for (const innerType of setting.innerTypes) {
                                            let slug = null;
                                            let moduleRaw = null;
                                            let module = null;

                                            switch (innerType) {
                                                case AppBreadcrumbRouteInnerTypes.CATEGORY_WITH_CATEGORY_GROUP:
                                                    slug = null;
                                                    moduleRaw = null;
                                                    module = null;

                                                    if (this.cmsPage) {
                                                        slug = 'data-hub-profile-header';
                                                        moduleRaw = this.cmsService.searchPageModule(this.cmsPage, slug);

                                                        if (moduleRaw) {
                                                            module = moduleRaw.api[slug];
                                                        }
                                                    }

                                                    if (module) {
                                                        const parts = value.split('.'); // ... .page. ...

                                                        if (parts[parts.length - 1] === 'categories') {
                                                            value = this.translateService.instant(value);
                                                            customHref = nextUrl + '/' + routesArr[i] + '?subVariable=' + module.parent;
                                                            queryParams = { subVariable: module.parent };

                                                            isCustomValue = true;
                                                        }
                                                    }
                                                    break;
                                                case AppBreadcrumbRouteInnerTypes.CODE_REPLACE_WITH_HEADER_TITLE:
                                                    slug = null;
                                                    moduleRaw = null;
                                                    module = null;

                                                    if (this.cmsPage) {
                                                        slug = 'data-hub-profile-header';
                                                        moduleRaw = this.cmsService.searchPageModule(this.cmsPage, slug);
                                                        module = null;

                                                        if (moduleRaw) {
                                                            module = moduleRaw.api[slug];
                                                        }
                                                    }
                                                    if (module) {
                                                        if (value === ':code' || value === ':unit') {
                                                            value = module.title;

                                                            isCustomValue = true;
                                                        }
                                                    }
                                                    break;
                                            }
                                        }
                                    }

                                    if (! value) {
                                        continue;
                                    }

                                    let isTranslatable = !isCustomValue;
                                    if (value.startsWith(':')) {
                                        if (!! route.snapshot) {
                                            const paramName = value.split(':')[1];
                                            routesArr[i] = route.snapshot.params[paramName];
                                            value = ContentHelpers.convertNormalizeStringToText(route.snapshot.params[paramName]);
                                        }
                                        isTranslatable = false;
                                    }
                                    if (nextUrl.slice(-1) !== '/') {
                                        nextUrl += '/';
                                    }
                                    nextUrl += routesArr[i];
                                    const breadcrumb1 = {
                                        label: value && isTranslatable ? this.translateService.instant(value) : value,
                                        routerLink: customRoute ? customRoute : nextUrl,
                                        queryParams,
                                        href: customHref
                                    };
                                    if ( breadcrumb1.label) {
                                        newBreadcrumbs = [...newBreadcrumbs, breadcrumb1];
                                    }
                                }
                                isBreadcrumbAdded = true;
                                break;
                        }
                    } else {
                        isDefault = true;
                    }
                    if (isDefault) {
                        label = data;
                        path = route.routeConfig.path;
                    }
                }
            }
        }
        if (!isBreadcrumbAdded) {
            if (!label.length && !breadcrumbs.length) {
                label = 'coin.page.main';
            }
            // If the route is dynamic route such as ':id', remove it
            const lastRoutePart = path.split('/').pop();
            const isDynamicRoute = lastRoutePart.startsWith(':');
            if (isDynamicRoute && !!route.snapshot) {
                const paramName = lastRoutePart.split(':')[1];
                path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
                label = route.snapshot.params[paramName];
            }
            // In the routeConfig the complete path is not available,
            // so we rebuild it each time
            if (path) {
                nextUrl = `${url}${path}/`;
            }
            const breadcrumb = {
                label: label ? this.translateService.instant(label) : label,
                routerLink: nextUrl
            };
            if ( breadcrumb.label) {
                newBreadcrumbs = [...breadcrumbs, breadcrumb];
            }
        }
        if (route.firstChild) {
            // If we are not on our current path yet,
            // there will be more children to look after, to build our breadcumb
            return this._buildBreadCrumbs(route.firstChild, nextUrl, newBreadcrumbs);
        }
        return newBreadcrumbs;
    }
}

export enum AppBreadcrumbRouteTypes {
    DEFAULT,
    SPLIT,
}

export enum AppBreadcrumbRouteInnerTypes {
    CATEGORY_WITH_CATEGORY_GROUP,
    CODE_REPLACE_WITH_HEADER_TITLE,
}

export interface IAppBreadcrumbSetting {
    type: AppBreadcrumbRouteTypes;
    value: string;
    generateAlone?: boolean;
    innerTypes?: AppBreadcrumbRouteInnerTypes[];
    replace?: any;
}
