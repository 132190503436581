import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromVariables from './variables.reducers';

import { CoinState } from '../index';

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectVariablesState = createSelector(
    selectCoinState,
    (state) => state.variables,
);

export const selectAllVariables = createSelector(
    selectVariablesState,
    fromVariables.selectAll,
);

export const selectVariablesById = (ids: number[]) => createSelector(
    selectAllVariables,
    (variables) => variables.filter((v) => ids.includes(v.id)),
);
