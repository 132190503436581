import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDevelopers from './developers.reducers';

import { CoinState } from '../index';
import {ArrayHelper} from "@shared/helpers";

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectDevelopersState = createSelector(
    selectCoinState,
    (state) => state.developers,
);

export const selectAllDevelopers = createSelector(
    selectDevelopersState,
    fromDevelopers.selectAll,
);

export const selectAllDevelopersFlattened = createSelector(
    selectDevelopersState,
    fromDevelopers.selectEntities,
);

export const selectDeveloperById = (id: number) => createSelector(
    selectAllDevelopersFlattened,
    (developers) => developers.hasOwnProperty(id) ? developers[id] : null,
);

export const selectAllDevelopersLoaded = createSelector(
    selectDevelopersState,
    (developersState) => developersState.allDevelopersLoaded,
);
