import {createSelector} from '@ngrx/store';
import {selectRouterUrl as routerUrl} from 'app/core/selectors/router.selectors';
import {selectCmsId} from '@features/general/cms/state/cms.entity';
import {selectGeneralState} from '@features/general/general.selectors';
import {ArrayHelper} from '@shared/helpers/array-helper.class';
import * as fromCms from './cms.reducer';
import {CmsModulesTypes} from "@features/general/cms/structure";
import {CmsHelper} from "@features/general/cms/state/cms.helper";

/**
 * State selectors
 */

/**
 * @cms
 * Get the state of the data hub lists
 */
export const selectCmsState = createSelector(
    selectGeneralState,
    (state) => state.cms
);

/**
 * END State selectors
 */

export const selectAllCmsPages = createSelector(
    selectCmsState,
    fromCms.selectAll
);

export const selectAllCmsEntities = createSelector(
    selectCmsState,
    fromCms.selectEntities
);

export const selectCmsPageIsLoaded = (page: string, params: {[_: string]: any}) => createSelector(
    selectCmsEntityByKey(page, params),
    (entity) => !!entity
);

export const selectCmsEntityByKey = (page: string, params: {[_: string]: any}) => createSelector(
    selectAllCmsEntities,
    (entities) => entities ? entities[selectCmsId({ frontend_page: null, page_type: page, content: null, map: params })] || null : null
);

/**
 * something something
 */

export const selectCmsActivePage = createSelector(
    routerUrl,
    selectAllCmsPages,
    (url, pages) => pages.find((page) => page.frontend_page === url)
);

export const selectCmsActiveContent = createSelector(
    selectCmsActivePage,
    (page) => page && page.content && page.content.data
);

export const selectCmsActiveModules = createSelector(
    selectCmsActiveContent,
    (content) => content && content.modules
);

export const selectCmsActiveCharts = createSelector(
    selectCmsActiveContent,
    (content) => content && content.charts
);

export const selectCmsActiveChartBySlug = (slug: string) => createSelector(
    selectCmsActiveCharts,
    (charts) => charts && charts.find((chart) => chart.uid === slug) || null
);

export const selectCmsActiveChartsFilters = createSelector(
    selectCmsActiveCharts,
    (charts) => {
        const filterSet = new Set();
        charts.map((chart) => {
            chart.filters.map((filter) => filterSet.add(filter.slug));
        });
        return Array.from(filterSet);
    }
);

export const selectCmsActiveModuleBySlug = (slug: string, contains_slug = false) => createSelector(
    selectCmsActiveModules,
    (modules: any) => modules && modules.find((module) => (contains_slug && module.slug.indexOf(slug) >= 0) || (module.slug === slug)) || null
);

export const selectCmsActiveModulesBySlugs = (slugs: string[], contains_slug = false) => createSelector(
    selectCmsActiveModules,
    (modules: any) => modules && modules.filter((module) => (contains_slug && !!slugs.find(slug => module.slug.indexOf(slug) >= 0)) || (slugs.includes(module.slug))) || []
);

export const selectCmsActiveModuleContentBySlug = (slug: string, unique = true, contains_slug = false) => createSelector(
    selectCmsActiveModuleBySlug(slug, contains_slug),
    (module: any) => module && module.api && ((unique && module.api[slug]) || module.api)
);

export const selectCmsActiveModuleContentBySlugAsElement = <T>(slug: string, unique = true) => createSelector(
    selectCmsActiveModuleContentBySlug(slug, unique),
    (content) => content as T
);

export const selectCmsActiveModuleContentBySlugAsArray = <T>(slug: string, unique = false, contains_slug= false) => createSelector(
    selectCmsActiveModuleContentBySlug(slug, unique, contains_slug),
    (content) => {
        if (!content) {
            return [];
        }
        if (Array.isArray(content)) {
            return content as T[];
        } else {
            return Object.values(content) as T[];
        }
    }
);

export const selectCmsActiveModuleContentBySlugAsArrayWithAdditionalCheck = <T>(slug: string, unique = false, contains_slug = false) => createSelector(
    selectCmsActiveModuleContentBySlugAsArray(slug, unique, contains_slug),
    (content) => {
        let arr = ArrayHelper.ensureTruthyValues(content);

        // Cover response of ["The specified URL cannot be found", 404]
        if (arr.length === 2 && arr[1] === 404) {
            arr = [];
        }
        return arr;
    }
);

export const selectCmsActiveModulesAsObject = createSelector(
    selectCmsActiveModules,
    (modules) => {
        if (!modules || !modules.length) {
            return {};
        } else {
            return Object.assign({}, modules);
        }
    }
);

export const selectCmsActiveModuleContentBySlugAsObject = (slug: string) => createSelector(
    selectCmsActiveModuleContentBySlug(slug),
    (content: any) => {
        if (Array.isArray(content)) {
            return Object.assign({}, content);
        } else {
            return content || {};
        }
    }
);

export const selectCmsCustomData = createSelector(
    selectCmsActiveContent,
    (content) => content && content.custom_data
);

export const selectCmsTranslations = createSelector(
    selectCmsCustomData,
    (data) => data && data.translations
);


export const selectCmsActiveModulesByType = (type: CmsModulesTypes) => createSelector(
    selectCmsActiveModules,
    (modules) => (modules || []).filter((module) => module.json_field_1 && module.json_field_1.type === type)
);

export const selectCmsActiveModulesContentByType = (type: CmsModulesTypes) => createSelector(
    selectCmsActiveModulesByType(type),
    (modules) => CmsHelper.ModulesContent(modules)
);

export const selectCmsActiveModulesLists = createSelector(
    selectCmsActiveModulesByType(CmsModulesTypes.list),
    (modules) => modules.map((module) => CmsHelper.ConvertModuleToList(module))
);

export const selectCmsActiveModulesByTypeProperty = (type: string) => createSelector(
    selectCmsActiveModules,
    (modules) => modules && modules.filter((module) => module.hasOwnProperty('type') && (module['type'] === type))
);
