import { Action } from '@ngrx/store';
import { IEdition, IIndice, IQueue } from '@shared/interfaces';

export enum IndicesActionTypes {
    EditionByUrlRequested = '[Indice] Request Editions By Url',
    AllEditionsRequested = '[Indice] Request All Editions for an Indice',
    EditionRequested = '[Indice] Request One Edition for an Indice',
    LastEditionRequested = '[Indice] Request the Last Edition for one Indice',
    IndiceByCodeRequested = '[Indice] Indice by code requested',
    IndiceByUrlRequested = '[Indice] Indice by url requested',
    AllIndicesRequested = '[Indice] All indices requested',
    IndicesByCategoryRequested = '[Indice] Indices request for category',
    IndicesLoaded = '[Indice] Indices Loaded',
    AllIndicesLoaded = '[Indice] All indices loaded',
    AllEditionsLoaded = '[Indice] AllEditions Loaded',
    EditionsLoaded = '[Indice] Editions Loaded',
    IndicesAddToQueue = '[Indice] Add method to Indices queue',
    EditionsAddToQueue = '[Indice] Add method to Editions queue',
    IndicesResolveQueue = '[Indice] Resolve Indice queue',
    EditionsResolveQueue = '[Indice] Resolve Editions queue',
    IndicesRemoveFromQueue = '[Indice] Remove from Indices queue',
    EditionsRemoveFromQueue = '[Indice] Remove from Editions queue',
    EditionAddRequested = '[Indice] Add Edition to requested array',
    RemoveIndiceByCode = '[Indice] Remove Indice by code',
}

export class EditionsRemoveFromQueue implements Action {
    readonly type = IndicesActionTypes.EditionsRemoveFromQueue;

    constructor(public payload: { id: string }) {}
}

export class IndicesRemoveFromQueue implements Action {
    readonly type = IndicesActionTypes.IndicesRemoveFromQueue;

    constructor(public payload: { id: string }) {}
}

export class EditionsResolveQueue implements Action {
    readonly type = IndicesActionTypes.EditionsResolveQueue;

    constructor(public payload: { id: string }) {}
}

export class IndicesResolveQueue implements Action {
    readonly type = IndicesActionTypes.IndicesResolveQueue;

    constructor(public payload: { id: string }) {}
}

export class EditionsAddToQueue implements Action {
    readonly type = IndicesActionTypes.IndicesAddToQueue;

    constructor(public payload: IQueue) {}
}

export class IndicesAddToQueue implements Action {
    readonly type = IndicesActionTypes.IndicesAddToQueue;

    constructor(public payload: { id: string, method: any, params: string }) {}
}

export class AllIndicesLoaded implements Action {
    readonly type = IndicesActionTypes.AllIndicesLoaded;
}

export class AllIndicesRequested implements Action {
    readonly type = IndicesActionTypes.AllIndicesRequested;
}

export class IndiceByCodeRequested implements Action {
    readonly type = IndicesActionTypes.IndiceByCodeRequested;

    constructor(public payload: { code: string, dataYear?: number }) {}
}

export class IndiceByUrlRequested implements Action {
    readonly type = IndicesActionTypes.IndiceByUrlRequested;

    constructor(public payload: { url: string }) {}
}

export class IndicesByCategoryRequested implements Action {
    readonly type = IndicesActionTypes.IndicesByCategoryRequested;

    constructor(public payload: { category_id: number }) {}
}

export class IndicesLoaded implements Action {
    readonly type = IndicesActionTypes.IndicesLoaded;

    constructor(public payload: { indices: IIndice[] }) {}
}
export class EditionsLoaded implements Action {
    readonly type = IndicesActionTypes.EditionsLoaded;

    constructor(public payload: { editions: IEdition[] }) {}
}

export class EditionRequested implements Action {
    readonly type = IndicesActionTypes.EditionRequested;

    constructor(public payload: { indice: string, edition: number }) {}
}

export class EditionAddRequested implements Action {
    readonly type = IndicesActionTypes.EditionAddRequested;

    constructor(public payload: { indice: string, edition: number }) {}
}

export class AllEditionsLoaded implements Action {
    readonly type = IndicesActionTypes.AllEditionsLoaded;

    constructor(public payload: { indice: string }) {}
}

export class AllEditionsRequested implements Action {
    readonly type = IndicesActionTypes.AllEditionsRequested;

    constructor(public payload: { indice: string }) {}
}

export class LastEditionRequested implements Action {
    readonly type = IndicesActionTypes.LastEditionRequested;

    constructor(public payload: { indice: string }) {}
}

export class EditionByUrlRequested implements Action {
    readonly type = IndicesActionTypes.EditionByUrlRequested;

    constructor(public payload: { url: string }) {}
}

export class RemoveIndiceByCode implements Action {
    readonly type = IndicesActionTypes.RemoveIndiceByCode;

    constructor(public payload: { code: string }) {}
}

export type IndicesActions =
    IndiceByCodeRequested
    | IndiceByUrlRequested
    | IndiceByCodeRequested
    | IndicesByCategoryRequested
    | EditionByUrlRequested
    | EditionRequested
    | AllEditionsRequested
    | AllEditionsLoaded
    | LastEditionRequested
    | IndicesLoaded
    | EditionsLoaded
    | AllIndicesRequested
    | AllIndicesLoaded
    | IndicesAddToQueue
    | EditionsAddToQueue
    | IndicesRemoveFromQueue
    | EditionsRemoveFromQueue
    | IndicesResolveQueue
    | EditionsResolveQueue
    | EditionAddRequested
    | RemoveIndiceByCode;
