import { Action } from '@ngrx/store';
import {IColor} from "@shared/interfaces";

export enum ColorsActionTypes {
    ColorsRequestedByUrl = '[Colors] Colors requested by Url',
    ColorsRequestedById = '[Colors] Colors requested by id',
    ColorsRequestedByEdition = '[Colors] All Colors requested for one edition',
    ColorsLoaded = '[Colors] Colors loaded',
}

export class ColorsRequestedByUrl implements Action {
    readonly type = ColorsActionTypes.ColorsRequestedByUrl;

    constructor(public payload: { url: string }) {}
}

export class ColorsRequestedById implements Action {
    readonly type = ColorsActionTypes.ColorsRequestedById;

    constructor(public payload: { type: string, id: number }) {}
}

export class ColorsRequestedByEdition implements Action {
    readonly type = ColorsActionTypes.ColorsRequestedByEdition;

    constructor(public payload: { indice: string, edition: number }) {}
}

export class ColorsLoaded implements Action {
    readonly type = ColorsActionTypes.ColorsLoaded;

    constructor(public payload: { colors: IColor[], url: string}) {}
}

export type ColorsActions =
    ColorsRequestedByUrl
    | ColorsRequestedById
    | ColorsRequestedByEdition
    | ColorsLoaded;
