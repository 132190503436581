<!-- BEGIN: Official European Union website block -->
<div class="globan dark logo-flag" id="globan" data-nosnippet="true">
    <div class="globan-center">
        <div class="globan-content">
            <span>{{'site.official_website_proof.title' | translate}}</span><span>{{'site.official_website_proof.title_mobile' | translate}}</span>
            <a href="" aria-controls="globan-dropdown" aria-expanded="false" (click)="showGlobanDropdown = !showGlobanDropdown" onClick="return false;">{{'site.official_website_proof.dropdown_title' | translate}}</a>
        </div>
        <div id="globan-dropdown" class="globan-dropdown" *ngIf="showGlobanDropdown">
            <p innerHTML="{{'site.official_website_proof.explanation' | translate}}"></p>
            <p><a href="//europa.eu/european-union/contact/institutions-bodies_en">{{'site.official_website_proof.link_title' | translate}}</a></p>
        </div>
    </div>
</div>
<!-- END: Official European Union website block -->

<!--<ng-container *ngIf="currentLanguage">-->
<!--    <div #languageSelectorContainerElement class="ecl-u-d-none">-->
<!--        <a href="#" aria-label="language list overlay" class="ecl-button ecl-button&#45;&#45;ghost ecl-site-header__language-selector" (click)="onLanguageClick($event)">-->
<!--            <span class="ecl-site-header__language-icon">-->
<!--    &lt;!&ndash;            <ecl-icon icon="language" size="s" class="ecl-site-header__icon" style="display: none;"></ecl-icon>&ndash;&gt;-->
<!--                <svg aria-hidden="true" focusable="false" class="ecl-icon ecl-site-header__icon ecl-icon&#45;&#45;s">-->
<!--                    <use xlink:href="assets/images/ecl/ec/icons/sprites/ecl-icons.svg#language"></use>-->
<!--                </svg>-->
<!--                <span class="ecl-site-header__language-code">{{ currentLanguage.code }}</span>-->
<!--            </span> {{ currentLanguage.label }}-->
<!--        </a>-->
<!--    </div>-->

<!--&lt;!&ndash;    <ecl-site-header-language&ndash;&gt;-->
<!--&lt;!&ndash;        [isOverlay]="true"&ndash;&gt;-->
<!--&lt;!&ndash;        [selectedLanguageCode]="currentLanguage.code"&ndash;&gt;-->
<!--&lt;!&ndash;        [isHidden]="isEclLanguageListHidden"&ndash;&gt;-->
<!--&lt;!&ndash;        (languageSelected)="onLanguageDismiss($event)"&ndash;&gt;-->
<!--&lt;!&ndash;        (close)="onLanguageDismiss($event)"&ndash;&gt;-->
<!--&lt;!&ndash;    >&ndash;&gt;-->
<!--&lt;!&ndash;        <ecl-site-header-language-popover&ndash;&gt;-->
<!--&lt;!&ndash;            *ngFor="let lang of languages"&ndash;&gt;-->
<!--&lt;!&ndash;            [languageCode]="lang.code"&ndash;&gt;-->
<!--&lt;!&ndash;            [languageLabel]="lang.label"&ndash;&gt;-->
<!--&lt;!&ndash;            [isActive]="lang.code === currentLanguage.code"&ndash;&gt;-->
<!--&lt;!&ndash;        ></ecl-site-header-language-popover>&ndash;&gt;-->
<!--&lt;!&ndash;    </ecl-site-header-language>&ndash;&gt;-->
<!--</ng-container>-->

<ecl-app>
    <ecl-site-header [siteName]="this.toolTitle | translate">
        <div class="{{ ! envService.showEnvTitleOnLogo ? 'ecl-u-d-none' : '' }}" eclSiteHeaderEnvironment>{{ envService.title }}</div>

        <ecl-site-header-action>

<!--            <ecl-site-header-login-->
<!--                [isLoggedIn]="isLoggedIn"-->
<!--                (login)="onLogin($event)"-->
<!--            >-->
<!--                <p eclSiteHeaderLoginDescription>-->
<!--                    {{ 'ecl.site-header.LOGGED-IN-AS' | translate }} John Doe-->
<!--                </p>-->
<!--                <hr eclSiteHeaderLoginSeparator />-->
<!--                <a eclLink variant="standalone" href="#" (click)="onLogout($event)">-->
<!--                    {{ 'ecl.site-header.LOGOUT' | translate }}-->
<!--                </a>-->
<!--            </ecl-site-header-login>-->
                <ecl-site-header-language
                    #siteHeaderLanguageElement
                    [class]=""
                    (languageClick)="onLanguageClick($event)"
                    [languageLabel]="currentLanguage.label"
                    [languageCode]="currentLanguage.code"
                    [languageCodes]="languageCodes"
                >
                </ecl-site-header-language>

            <ecl-site-header-search (search)="onSearch($event)"></ecl-site-header-search>
        </ecl-site-header-action>

<!--        <ecl-site-header-banner-top>-->
<!--            <a eclLink routerLink="/">Class name</a>-->
<!--        </ecl-site-header-banner-top>-->

        <ecl-menu group="1" (menuItemSelect)="onMenuItemSelected($event)" *ngIf="menuLinks.length">
            <ng-container *ngFor="let menuLink of menuLinks">
                <ecl-menu-item
                   [id]="menuLink.id"
                   [label]="menuLink.label"
                   [isCurrent]="menuLink.active"
                   [href]="menuLink.url"
                   onClick="return false;"
                >
                    <ecl-menu-mega *ngIf="menuLink.hasChildren">
                        <ecl-menu-mega-item
                            *ngFor="let menuLinkChild of menuLink.children"
                            [id]="menuLinkChild.id"
                            [label]="menuLinkChild.label"
                            [isCurrent]="menuLinkChild.active"
                            [href]="menuLinkChild.url"
                            onClick="return false;"
                        ></ecl-menu-mega-item>
                    </ecl-menu-mega>
                </ecl-menu-item>
            </ng-container>
        </ecl-menu>

    </ecl-site-header>

    <ecl-page-header *ngIf="breadcrumbs.length > 1">
        <ecl-breadcrumb>
            <ecl-breadcrumb-segment
                *ngFor="let breadcrumb of breadcrumbs"
                [isCurrentPage]="breadcrumb.active"
                href="{{!breadcrumb.active ? breadcrumb.href ? breadcrumb.href : breadcrumb.routerLink : null}}"
                [routerLink]="!breadcrumb.active ? [breadcrumb.routerLink] : null"
                [queryParams]="!breadcrumb.active ? breadcrumb.queryParams : null"
                onClick="return false;"
            >
                {{ breadcrumb.label }}
            </ecl-breadcrumb-segment>
        </ecl-breadcrumb>
    </ecl-page-header>


<!--        <div eclPageHeaderMeta>-->
<!--            META INFO-->
<!--        </div>-->
<!--        <h1 eclPageHeaderTitle>Page title</h1>-->
<!--        <p eclPageHeaderDescription>-->
<!--            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec ullamcorper mi. Morbi interdum fermentum tempus. Nam nec rhoncus risus, eget dictum elit. Vestibulum gravida tincidunt venenatis.-->
<!--        </p>-->

<!--    <div class="ecl-container eui-u-p-none" *ngIf="breadcrumbs.length > 1">-->
<!--        <eui-breadcrumb class="eui-u-pt-none">-->
<!--            <eui-breadcrumb-item-->
<!--                *ngFor="let breadcrumb of breadcrumbs"-->
<!--                [isCurrentPage]="breadcrumb.active"-->
<!--                href="{{!breadcrumb.active ? breadcrumb.href ? breadcrumb.href : breadcrumb.routerLink : null}}"-->
<!--                [routerLink]="!breadcrumb.active ? [breadcrumb.routerLink] : null"-->
<!--                [queryParams]="!breadcrumb.active ? breadcrumb.queryParams : null"-->
<!--                onClick="return false;"-->
<!--                [label]="breadcrumb.label">-->
<!--            </eui-breadcrumb-item>-->
<!--        </eui-breadcrumb>-->
<!--    </div>-->

<!--    <div class="ecl-u-pv-xl">-->
<!--        <div class="ecl-container">-->
<!--            Content..-->
<!--        </div>-->
<!--    </div>-->

    <main id="ecl-main-content">
        <div class="ecl-container" *ngIf="loadingContent">
            <app-ecl-skeleton-loader>
                <div style="height: 400px; width: 100%;">
                    <div class="skeleton-loader__icon"><i class="fa fa-spinner fa-spin"></i></div>
                </div>
            </app-ecl-skeleton-loader>
        </div>
        <div *ngIf="! loadingContent">
            <router-outlet
               (activate)="onActivate($event)"
               (deactivate)="onDeactivate($event)"></router-outlet>
        </div>
    </main>

    <!-- Feedback form -->
    <div id="feedback-form-placeholder"></div>

    <ecl-site-footer>
        <div eclSiteFooterRow>
            <div eclSiteFooterColumn>
                <div eclSiteFooterSection isSiteInfo>
                    <h2 eclSiteFooterTitle>
                        <ng-container [ngSwitch]="lastToolId">
                            <ng-container *ngSwitchCase="DataHubRoutes.MAIN_URL">
                                <a eclLink variant="standalone" eclSiteFooterTitleLink [routerLink]="[DataHubRoutes.MAIN_URL]">{{ this.toolTitle | translate }}</a>
                            </ng-container>
                            <ng-container *ngSwitchCase="EuMimfRoutes.MAIN_URL">
                                <a eclLink variant="standalone" eclSiteFooterTitleLink [routerLink]="[EuMimfRoutes.MAIN_URL]">{{ this.toolTitle | translate }}</a>
                            </ng-container>
                            <ng-container *ngSwitchCase="GesMonitorRoutes.MAIN_URL">
                                <a eclLink variant="standalone" eclSiteFooterTitleLink [routerLink]="[GesMonitorRoutes.MAIN_URL]">{{ this.toolTitle | translate }}</a>
                            </ng-container>
                            <ng-container *ngSwitchCase="C3Routes.MAIN_URL">
                                <a eclLink variant="standalone" eclSiteFooterTitleLink [routerLink]="[C3Routes.MAIN_URL]">{{ this.toolTitle | translate }}</a>
                            </ng-container>
                            <ng-container *ngSwitchCase="SocialScoreboardRoutes.MAIN_URL">
                                <a eclLink variant="standalone" eclSiteFooterTitleLink [routerLink]="[SocialScoreboardRoutes.MAIN_URL]">{{ this.toolTitle | translate }}</a>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <a eclLink variant="standalone" eclSiteFooterTitleLink routerLink="/">{{ this.toolTitle | translate }}</a>
                            </ng-container>
                        </ng-container>
                    </h2>
                    <div eclSiteFooterDescription>{{ 'site.footer.DESCRIPTION' | translate }}
                    </div>
                    <ul eclSiteFooterList>
                        <li eclSiteFooterListItem>
                            <a eclLink eclSiteFooterLink variant="standalone" [routerLink]="[HomeRoutes.routes.ACCESSIBILITY_STATEMENT]">
                                {{ 'key_accessibility' | translate }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div eclSiteFooterColumn>
                <div eclSiteFooterSection>
                    <h2 eclSiteFooterTitle
                        [isSeparator]="true">
                        {{ 'ecl.footer.CONTACT-US' | translate }}
                    </h2>
                    <ul eclSiteFooterList>
                        <li eclSiteFooterListItem>
                            <a eclLink
                               eclSiteFooterLink
                               [attr.aria-label]="'ecl.footer.CONTACT-INFO-DG' | translate"
                               variant="standalone"
                               href="https://ec.europa.eu/info/departments/joint-research-centre_en">
                                {{ 'ecl.footer.CONTACT-INFO-DG' | translate }} <ecl-icon icon="external" size="xs"></ecl-icon>
                            </a>
                        </li>
                    </ul>
                </div>
                <ng-container *ngIf="lastToolId === DataHubRoutes.MAIN_URL">
                    <div eclSiteFooterSection>
                        <h2 eclSiteFooterTitle [isSeparator]="true">{{ 'Data policy' | translate }}</h2>
                        <ul eclSiteFooterList>
                            <li eclSiteFooterListItem>
                                <!--                        <a eclLink eclSiteFooterStandardisedLink variant="standalone" href="assets/explorer/Data Policy 1.0.pdf">-->
                                <!--                            {{ 'Data policy' | translate }} <i class="fa fa-download" style="font-size: 1rem;"></i>-->
                                <!--                        </a>-->
                                <a eclLink eclSiteFooterLink variant="standalone" [routerLink]="[DataHubRoutes.MAIN_URL, DataHubRoutes.routes.DATA_POLICY]">
                                    {{ 'Data policy' | translate }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </div>
            <div eclSiteFooterColumn>
                <div eclSiteFooterSection>
                    <h2 eclSiteFooterTitle
                        [isSeparator]="true">
                        {{ 'ecl.footer.ABOUT-US' | translate }}
                    </h2>
                    <ul eclSiteFooterList>
                        <li eclSiteFooterListItem>
                            <a eclLink
                               eclSiteFooterLink
                               [attr.aria-label]="'ecl.footer.INFO-ABOUT-DG' | translate"
                               variant="standalone"
                               href="https://ec.europa.eu/info/departments/joint-research-centre_en">
                                {{ 'ecl.footer.INFO-ABOUT-DG' | translate }} <ecl-icon icon="external" size="xs"></ecl-icon>
                            </a>
                        </li>
                    </ul>
                </div>
                <div eclSiteFooterSection>
                    <h2 eclSiteFooterTitle
                        [isSeparator]="true">
                        {{ 'ecl.footer.RELATED-SITES' | translate }}
                    </h2>
                    <ul eclSiteFooterList>
                        <li eclSiteFooterListItem *ngFor="let relatedLink of relatedLinks">
                            <a *ngIf="relatedLink.routerLink" eclLink eclSiteFooterLink variant="standalone" [routerLink]="relatedLink.routerLink">
                                {{relatedLink.label}}
                            </a>
                            <a *ngIf="relatedLink.urlExternal" eclLink eclSiteFooterLink variant="standalone" target="_blank" [href]="relatedLink.urlExternal" (click)="matomoService.trackLink(relatedLink.urlExternal)">
                                {{relatedLink.label}} <ecl-icon icon="external" size="xs"></ecl-icon>
                            </a>
                        </li>
                        <li eclSiteFooterListItem>
                            <a eclLink eclSiteFooterLink variant="standalone" target="_blank" href="https://knowledge4policy.ec.europa.eu/composite-indicators_en" (click)="matomoService.trackLink('https://knowledge4policy.ec.europa.eu/composite-indicators_en')">
                                {{'site.related-links.competence_centre_on_composite_indicators_scoreboards' | translate}} <ecl-icon icon="external" size="xs"></ecl-icon>
                            </a>
                        </li>
                        <li eclSiteFooterListItem>
                            <a eclLink eclSiteFooterLink variant="standalone" target="_blank" href="https://ec.europa.eu/info/departments/joint-research-centre_en" (click)="matomoService.trackLink('https://ec.europa.eu/info/departments/joint-research-centre_en')">
                                {{'site.related-links.joint-research-center' | translate}} <ecl-icon icon="external" size="xs"></ecl-icon>
                            </a>
                        </li>
                        <li eclSiteFooterListItem>
                            <a eclLink eclSiteFooterLink variant="standalone" target="_blank" href="https://ec.europa.eu/jrc/en" (click)="matomoService.trackLink('https://ec.europa.eu/jrc/en')">
                                {{'site.related-links.eu-science-hub' | translate}} <ecl-icon icon="external" size="xs"></ecl-icon>
                            </a>
                        </li>
                        <li eclSiteFooterListItem>
                            <a eclLink eclSiteFooterLink variant="standalone" target="_blank" href="https://ec.europa.eu/knowledge4policy/" (click)="matomoService.trackLink('https://ec.europa.eu/knowledge4policy/')">
                                {{'site.related-links.knowledge4policy' | translate}} <ecl-icon icon="external" size="xs"></ecl-icon>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
<!--        <div eclSiteFooterRow>-->
<!--            <div eclSiteFooterColumn>-->
<!--                <div eclSiteFooterSection-->
<!--                     [isCondensed]="true">-->
<!--                    <div eclSiteFooterContent>{{ 'ecl.footer.MORE-INFO' | translate }}:</div>-->
<!--                    <ul eclSiteFooterList>-->
<!--                        <li eclSiteFooterListItem>-->
<!--                            <a eclLink-->
<!--                               eclSiteFooterLink-->
<!--                               variant="standalone"-->
<!--                               routerLink="/">-->
<!--                                Class name 1-->
<!--                            </a>-->
<!--                        </li>-->
<!--                        <li eclSiteFooterListItem>-->
<!--                            <a eclLink-->
<!--                               eclSiteFooterLink-->
<!--                               variant="standalone"-->
<!--                               routerLink="/">-->
<!--                                Class name 2-->
<!--                            </a>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </ecl-site-footer>

    <div id="app-release-info-container">
        <div class="ecl-container">
            <div class="small eui-u-text-center">
                Release date: 2024-03-04 APP version: 1.2.0
            </div>
        </div>
    </div>

</ecl-app>
