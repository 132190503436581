import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ColorsState } from './colors.reducers';

import * as fromColors from './colors.reducers';

import { CoinState } from '../index';

export const selectCoinState = createFeatureSelector<CoinState>('composite');

/**
 * @namespace COINColors
 * The colors state
 * @returns {ColorsState}
 */
export const selectColorsState = createSelector(
    selectCoinState,
    (state) => state.colors,
);

/**
 * @namespace COINColors
 * All the colors in the store
 * @returns {IColor[]}
 */
export const selectAllColors = createSelector(
    selectColorsState,
    fromColors.selectAll,
);

/**
 * @namespace COINColors
 * Get one color by id
 * @param {number}id Id of the color
 * @returns {IColor}
 */
export const selectColorsById = (id: number) => createSelector(
    selectAllColors,
    (colors) => colors.find((c) => c.id === id),
);

/**
 * @namespace COINColors
 * Checks if an url is loaded
 * @param {string} url The url to check
 * @returns {boolean}
 */
export const selectlColorsUrlIsLoaded = (url: string) => createSelector(
    selectColorsState,
    (state) => state.urlsLoaded.includes(url),
);
