import { Injectable } from '@angular/core';
import {Actions, createEffect} from '@ngrx/effects';
import { Effect } from 'signal';
import { Store } from '@ngrx/store';

import { CompositeService } from '@features/composite-indicator/composite.service';

@Injectable()
export class GroupsEffects {

    constructor(private actions$: Actions, private compositeService: CompositeService,
                private store: Store<any>) {}
}
