import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IGroupClass } from '@shared/interfaces';
import { ClassesActions, ClassesActionTypes } from './classes.actions';

export interface ClassesState extends EntityState<IGroupClass> {
}

export const adapter: EntityAdapter<IGroupClass> =
    createEntityAdapter<IGroupClass>({});

export const initialClassesState: ClassesState = adapter.getInitialState({
});

export function classesReducer(state = initialClassesState , action: ClassesActions): ClassesState {

    switch (action.type) {
    case ClassesActionTypes.ClassesLoaded:
        // adapter.removeAll(state);
        return adapter.setAll(action.payload.classes, state);
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
