import { Injectable } from '@angular/core';
import {Actions, createEffect} from '@ngrx/effects';
import { Effect } from 'signal';
import { Store } from '@ngrx/store';

import { CompositeService } from '../../composite.service';
import {
    UnitGroupsActionTypes,
    UnitGroupsByUrlRequested,
    UnitGroupsLoaded,
} from './unit-groups.actions';

@Injectable()
export class UnitGroupsEffects {
/*
    @createEffect(() =>)
    loadComponentsByUrl$ = this.actions$.ofType<UnitGroupsByUrlRequested>(UnitGroupsActionTypes.UnitGroupsByUrlRequested)
        .pipe(
            map(request => request.payload.url),
            mergeMap(url => this.unitsService.getComponentsByUrl(url)),
            map( groups => new UnitGroupsLoaded( { groups }))
        );
*/
    constructor(private actions$: Actions, private compositeService: CompositeService,
                private store: Store<any>) {}
}
