import {Component} from '@angular/core';
import {BaseComponent} from "@shared/base-classes/base-component";
import {Action} from "../../../../features/charts/models/charts.interfaces";
import {ITableMultilevelCell} from "@shared/interfaces";

@Component({
  selector: 'app-table-multilevel-cell',
  templateUrl: './table-multilevel-cell.component.html',
  styleUrls: ['./table-multilevel-cell.component.scss']
})
export class TableMultilevelCellComponent extends BaseComponent<ITableMultilevelCell, any, Action> {

    configUpdate() {
    }

    dataUpdate() {
    }

    initConfig(): any {
        return this.initialConfig;
    }
}
