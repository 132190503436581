import { createSelector } from '@ngrx/store';
import * as fromCmsCharts from '@features/general/charts/state/cms-charts.reducer';
import { CmsChartsCategoriesTypes } from '@features/general/charts/structure';

import { selectGeneralState } from '@features/general/general.selectors';
import { ArrayHelper } from '@shared/helpers';

export const selectCmsChartsCategoriesState = createSelector(
    selectGeneralState,
    (state) => state.charts.categories
);

export const selectAllCmsChartsCategories = createSelector(
    selectCmsChartsCategoriesState,
    fromCmsCharts.selectAllChartsCategories
);

export const selectAllCmsChartsEntitiesCategories = createSelector(
    selectCmsChartsCategoriesState,
    fromCmsCharts.selectEntitiesChartsCategories
);

export const selectCmsChartsGetChartCategoriesElems = (chart_id: string) => createSelector(
    selectAllCmsChartsCategories,
    (categories) => categories.filter((f) => f.chart_id === chart_id)
);

export const selectCmsChartsGetChartMainCategoriesElems = (chart_id: string) => createSelector(
    selectAllCmsChartsCategories,
    (categories) => categories.filter((f) => f.chart_id === chart_id && f.type === CmsChartsCategoriesTypes.main)
);

export const selectCmsChartsCategoriesElemsWithSomeParams = (chart_id: string, params: string[]) => createSelector(
    selectCmsChartsGetChartCategoriesElems(chart_id),
    (categories) => {
        return categories.filter((elem) => ArrayHelper.intersection(elem.params, params).length > 0);
    }
);

export const selectCmsChartsGetChartCategoriesUrls = (chart_id: string) => createSelector(
    selectCmsChartsGetChartCategoriesElems(chart_id),
    (categories) => categories.map((elem) => elem.url).filter((url) => !!url)
);

export const selectCmsChartsCategoriesGetChartIdsFromUrls = (urls: string[]) => createSelector(
    selectAllCmsChartsCategories,
    (categories) => {
        const ids = categories.filter((d) => urls.includes(d.url)).map((d) => d.chart_id);
        return Array.from(new Set(ids));
    }
);

export const selectCmsChartsCategoriesForMultipleCharts = (chart_ids: string[]) => createSelector(
    selectAllCmsChartsCategories,
    (cats) => cats.filter((s) => chart_ids.includes(s.chart_id))
);

export const selectCmsChartsCategoriesFromSomeParams = (args: {chart_id: string, names: string}[]) => createSelector(
    selectCmsChartsCategoriesForMultipleCharts(ArrayHelper.uniquePluck(args, 'chart_id')),
    (cats) => {
        return cats.filter((cat) => {
            const arg = args.find((d) => d.chart_id === cat.chart_id);
            return ArrayHelper.intersects(cat.params, arg.names);
        })
    }
);

export const selectCmsChartsCategoriesGetCategoriesFromUrls = (urls: string[]) => createSelector(
    selectAllCmsChartsCategories,
    (categories) => categories.filter((s) => urls.includes(s.url) && s.url != null)
);
