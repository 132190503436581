import { Action } from '@ngrx/store';
import { IList } from '@features/general/lists/structure';
import { ICmsChartParameter } from '@features/general/charts/state/params/cms-chart-parameters.entity';
import { CmsChartsSetParamsStrategy } from '@features/general/charts/structure';

export enum CmsChartsParamsActionTypes {
    CmsChartsSetParam = '[CmsCharts params] Set a param for one chart',
    CmsChartsSetPageParam = '[CmsCharts params] Set a page param for one chart',
    CmsChartsSetParams = '[CmsCharts params] Set params for multiple charts',
    CmsChartsSetValuesParams = '[CmsCharts params] Set params based on given values for multiple charts',
    CmsChartsSetPageParams = '[CmsCharts params] Set page params for multiple charts',
    CmsChartsSetParamsFromLists = '[CmsCharts params][CmsChartsSetParamsFromLists] Set params from lists',
    CmsChartsSetMultipleParamRequest = '[CmsCharts params][CmsChartsSetMultipleParamRequest] Set a multiple value param',
    CmsChartsAddCopyParam = '[CmsCharts params] Add a copied param',
    CmsChartsAddCopyParams = '[CmsCharts params][CmsChartsAddCopyParams] Add multiple copied params',
    CmsChartsRemoveCopyParam = '[CmsCharts params] Remove a copied param',
    CmsChartsRemoveCopyParams = '[CmsCharts params][CmsChartsRemoveCopyParams] Remove multiple copied params',
    CmsChartsParamsSetParamActiveValues = '[CmsCharts params][CmsChartsParamsSetParamActiveValues] Set the active values for a param',
    CmsChartsResetCopiedParam = '[CmsCharts params][CmsChartsResetCopiedParam] Removes copied params of the same origin',
    CmsChartsResetCopiedParams = '[CmsCharts params][CmsChartsResetCopiedParams] Removes copied params of the same origins (multiple action)',
    CmsChartsClearParams = '[CmsCharts params][CmsChartsClearParams] Clear the specified params'
}

export class CmsChartsSetParam implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsSetParam;
    constructor (public payload: { chart_id: string, name: string, value: any, temporaryIgnore?: boolean }) {}
}

export class CmsChartSetPageParam implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsSetPageParam;
    constructor (public payload: { chart_id: string, name: string, value: any, temporaryIgnore?: boolean }) {}
}

export class CmsChartsSetParams implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsSetParams;
    constructor (public payload: { chart_id: string, name: string, value: any, temporaryIgnore?: boolean }[]) {}
}

/**
 * Set params which values are defined on the filters from cms, they don't come from lists
 */
export class CmsChartsSetValuesParams implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsSetValuesParams;
    constructor (public payload: { chart_id: string, name: string, value: any }[]) {}
}

export class CmsChartsSetPageParams implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsSetPageParams;
    constructor (public payload: { chart_id: string, name: string, value: any, temporaryIgnore?: boolean }[]) {}
}

export class CmsChartsSetParamsFromLists implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsSetParamsFromLists;
    constructor (public payload: { lists: IList[] }) {}
}

export class CmsChartsSetMultipleParamRequest implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsSetMultipleParamRequest;
    constructor (public payload: { chart_id: string, name: string, value: any, strategy?: CmsChartsSetParamsStrategy }[]) {}
}
export class CmsChartsAddCopyParam implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsAddCopyParam;
    constructor (public payload: { param: ICmsChartParameter, name: string, value: any }) {}
}

export class CmsChartsAddCopyParams implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsAddCopyParams;
    constructor (public payload: { param: ICmsChartParameter, name: string, value: any }[]) {}
}


export class CmsChartsRemoveCopyParam implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsRemoveCopyParam;
    constructor (public payload: { chart_id: string, name: string }) {}
}

export class CmsChartsRemoveCopyParams implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsRemoveCopyParams;
    constructor (public payload: { chart_id: string, name: string }[]) {}
}

/**
 * Set the param with the values and remove active values not present in the action
 */
export class CmsChartsParamsSetParamActiveValues implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsParamsSetParamActiveValues;
    constructor (public payload: { chart_id: string, name: string , values: any[], strategy?: CmsChartsSetParamsStrategy }) {}
}

export class CmsChartsResetCopiedParam implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsResetCopiedParam;

    constructor(public payload: { chart_id: string, origin: string; }) {}
}

export class CmsChartsResetCopiedParams implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsResetCopiedParams;

    constructor(public payload: { chart_id: string, origin: string; }[]) {}
}

export class CmsChartsClearParams implements Action {
    readonly type = CmsChartsParamsActionTypes.CmsChartsClearParams;
    constructor (public payload: { chart_id: string, name: string }[]) {}
}

export type CmsChartsParamsActions = CmsChartsSetParam
    | CmsChartSetPageParam
    | CmsChartsSetParams
    | CmsChartsSetValuesParams
    | CmsChartsSetPageParams
    | CmsChartsSetParamsFromLists
    | CmsChartsSetMultipleParamRequest
    | CmsChartsAddCopyParam
    | CmsChartsAddCopyParams
    | CmsChartsRemoveCopyParam
    | CmsChartsParamsSetParamActiveValues
    | CmsChartsResetCopiedParam
    | CmsChartsResetCopiedParams
    | CmsChartsClearParams
    | CmsChartsRemoveCopyParams;
