import { NgModule } from '@angular/core';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {
    CachePreventionInterceptor,
    CorsSecurityInterceptor,
    CsrfPreventionInterceptor,
    EuLoginSessionTimeoutHandlingInterceptor,
    CoreModule as EuiCoreModule,
    translateConfig,
    CoreModuleEffects,
    EUI_CONFIG_TOKEN,
} from '@eui/core';

import './operators';

import { appConfig } from '../../config';
import { environment } from '../../environments/environment';

import { getReducers, REDUCER_TOKEN } from './reducers/index';

import { EffectsModule } from '@ngrx/effects';
import { CustomSerializer } from 'app/core/serializers/custom-route-serializer';
import { GeneralModule } from '@features/general/general.module';
import { SharedModule } from '../shared/shared.module';
import { CustomCoreEffects } from './effects';
import { RouterModule } from '@angular/router';
import { BreadcrumbsStateActionTypes } from '@shared/breadcrumbs/breadcrumbs.actions';
import { breadcrumbsStateReducer } from '@shared/breadcrumbs/breadcrumbs.reducers';
import { EclAllModule, EclAppComponentModule } from '@eui/ecl';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EuiAllModule } from '@eui/components';
import { screenStateReducer } from '@shared/screen/screen.reducers';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        BrowserModule,
        FormsModule,
        EuiAllModule,
        SharedModule,
        HttpClientModule,
        EffectsModule.forFeature(CustomCoreEffects),
        EffectsModule.forRoot([...CoreModuleEffects]),
        TranslateModule.forRoot(translateConfig),
        StoreModule.forRoot(REDUCER_TOKEN),
        !!environment.enableDevToolRedux ? StoreDevtoolsModule.instrument({ maxAge: 150 }) : [],
        StoreRouterConnectingModule.forRoot({
            serializer: CustomSerializer
        }),
        EuiCoreModule.forRoot(),
    ],
    declarations: [
    ],
    exports: [
        SharedModule,
        GeneralModule
    ],
    providers: [
        {
            provide: REDUCER_TOKEN,
            deps: [],
            useFactory: getReducers,
        },
        {
            provide: EUI_CONFIG_TOKEN,
            useValue: { appConfig, environment }
        },
 /*       {
            provide: HTTP_INTERCEPTORS,
            useClass: CorsSecurityInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EuLoginSessionTimeoutHandlingInterceptor,
            multi: true,
        },*/
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CsrfPreventionInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CachePreventionInterceptor,
            multi: true,
        },
 /*       {
            provide: HTTP_INTERCEPTORS,
            useFactory: openidConnectInterceptorFactory,
            deps: [CONFIG_TOKEN],
            multi: true,
        },*/
    ],
})
export class CoreModule {

}
