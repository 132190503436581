import {Inject, Injectable} from "@angular/core";
import {environment as envLocalDemo} from "../../../environments/environment.local-demo";
import {environment as envDemoCoinDevEu} from "../../../environments/environment.demo.coin-dev-eu";
import {environment as envProd} from "../../../environments/environment.prod";
import {environment as envCoinFrontendCoinToolsMainCoin} from "../../../environments/environment.coin-frontend-cointools-main-coin";
import {environment as envCoinNginxReverseProxyMainCoin} from "../../../environments/environment.coin-nginx-reverse-proxy-main-coin";
import {environment as envCoinNginxReverseProxyMainCoinProduction} from "../../../environments/environment.coin-nginx-reverse-proxy-main-coin-production";
import {environment as envCoinNginxReverseProxyStagingMainCoin} from "../../../environments/environment.coin-nginx-reverse-proxy-staging-main-coin";
import {environment as envCoinNginxReverseProxyStagingMainCoinProduction} from "../../../environments/environment.coin-nginx-reverse-proxy-staging-main-coin-production";
import {environment as openshiftProduction} from "../../../environments/environment.openshiftProduction";
import {environment as envNoProxStage} from "../../../environments/environment.no-prox-stage";
import {environment as envNoProxProd} from "../../../environments/environment.no-prox-prod";
import {environment as coinApiJRC} from "../../../environments/environment.coinapi-jrc";

export enum Environment {
    Local = 'local',
    DemoCoinDevEu = 'demoCoinDevEu',
    Prod = 'prod',
    CoinFrontendCoinToolsMainCoin = 'coinFrontendCoinToolsMainCoin',
    CoinNginxReverseProxyMainCoin = 'coinNginxReverseProxyMainCoin',
    CoinNginxReverseProxyMainCoinProduction = 'coinNginxReverseProxyMainCoinProduction',
    CoinNginxReverseProxyStagingMainCoin = 'coinNginxReverseProxyStagingMainCoin',
    CoinNginxReverseProxyStagingMainCoinProduction = 'coinNginxReverseProxyStagingMainCoinProduction',
    OpenshiftProduction = 'openshiftProduction',
    NoProxStage = 'noProxStage',
    NoProxProd = 'noProxProd',
    coinApiJRC = 'coinApiJRC',
    // Openid left for open id development purposes only for now
}

@Injectable({ providedIn: 'root' })
export class EnvService {
    private _isNode: boolean;
    private _env: Environment;
    private _title: string;
    private _showEnvTitleOnLogo: boolean;
    private _production: boolean;
    private _enableDevToolRedux: boolean;
    private _apiUrl: string;
    private _cmsApiUrl: string;
    private _i18nUrl: string;
    private _cmsStorageUrl: string;
    private _enableAnalyticsTracking: boolean;
    private _matomoUrl: string;
    private _matomoSiteId: string;
    private _enableGoogleAnalyticsTracking: boolean;
    private _googleAnalyticsSiteId: string;

    get env(): Environment {
        return this._env;
    }

    get title(): string {
        return this._title;
    }

    get showEnvTitleOnLogo(): boolean {
        return this._showEnvTitleOnLogo;
    }

    get production(): boolean {
        return this._production;
    }

    get enableDevToolRedux(): boolean {
        return this._enableDevToolRedux;
    }

    get apiUrl(): string {
        return this._apiUrl;
    }

    get cmsApiUrl(): string {
        return this._cmsApiUrl;
    }

    get i18nUrl(): string {
        return this._i18nUrl;
    }

    get cmsStorageUrl(): string {
        return this._cmsStorageUrl;
    }

    get enableAnalyticsTracking(): boolean {
        return this._enableAnalyticsTracking;
    }

    get matomoUrl(): string {
        return this._matomoUrl;
    }

    get matomoSiteId(): string {
        return this._matomoSiteId;
    }

    get enableGoogleAnalyticsTracking(): boolean {
        return this._enableGoogleAnalyticsTracking;
    }

    get googleAnalyticsSiteId(): string {
        return this._googleAnalyticsSiteId;
    }

    private _envMapOfVars = new Map([
        [Environment.Local, envLocalDemo],
        [Environment.DemoCoinDevEu, envDemoCoinDevEu],
        [Environment.Prod, envProd],
        [Environment.CoinFrontendCoinToolsMainCoin, envCoinFrontendCoinToolsMainCoin],
        [Environment.CoinNginxReverseProxyMainCoin, envCoinNginxReverseProxyMainCoin],
        [Environment.CoinNginxReverseProxyMainCoinProduction, envCoinNginxReverseProxyMainCoinProduction],
        [Environment.CoinNginxReverseProxyStagingMainCoin, envCoinNginxReverseProxyStagingMainCoin],
        [Environment.CoinNginxReverseProxyStagingMainCoinProduction, envCoinNginxReverseProxyStagingMainCoinProduction],
        [Environment.OpenshiftProduction, openshiftProduction],
        [Environment.NoProxStage, envNoProxStage],
        [Environment.NoProxProd, envNoProxProd],
        [Environment.coinApiJRC, coinApiJRC],
    ]);

    constructor() {
        this._isNode = (typeof process !== 'undefined') && (process.release.name === 'node');

        const isEnvLoaded = localStorage.getItem('isEnvLoaded');

        if (! parseInt(isEnvLoaded)) {
            const isEnvLoading = localStorage.getItem('isEnvLoading');

            if (parseInt(isEnvLoading)) {
                return;
            }
            localStorage.setItem('isEnvLoading', '1');

            this.init();
            localStorage.setItem('isEnvLoaded', '1');
            localStorage.removeItem('isEnvLoading');
            return;
        }
        this.setEnvVariables();
    }
    init(): void {
        this.determineEnv();
        this.determineEnvVariables();
        this.setEnvVariables();
    }

    reset(): void {
        localStorage.removeItem('isEnvLoaded');
        localStorage.removeItem('isEnvLoading');
    }

    private determineEnv(): void {
        if (this._isNode) {
            console.log('Recognized as _isNode and env detected from .env: ' + process.env.environment);
            this._env = process.env.environment as Environment;

            if (!Object.values(Environment).includes(this._env)) {
                throw new Error('Given environment doesn\'t exist -> ' + this._env);
            }
        } else {
            const hostname = window && window.location && window.location.hostname;

            switch (true) {
                case /^.*localhost.*/.test(hostname):
                    this._env = Environment.Local;
                    break;

                case /^demo.coin-dev.eu*/.test(hostname):
                    this._env = Environment.DemoCoinDevEu;
                    break;

                case /^composite-indicators.jrc.ec.europa.eu*/.test(hostname):
                    this._env = Environment.Prod;
                    break;

                case /^coin-frontend-cointools-main-coin.apps.ocpt.jrc.cec.eu.int*/.test(hostname):
                    this._env = Environment.CoinFrontendCoinToolsMainCoin;
                    break;

                case /^coin-nginx-reverse-proxy-main-coin.apps.ocpt.jrc.cec.eu.int*/.test(hostname):
                    this._env = Environment.CoinNginxReverseProxyMainCoin;
                    break;

                case /^coin-nginx-reverse-proxy-main-coin.apps.ocp.jrc.ec.europa.eu*/.test(hostname):
                    this._env = Environment.CoinNginxReverseProxyMainCoinProduction;
                    break;

                case /^coin-nginx-reverse-proxy-staging-main-coin.apps.ocpt.jrc.cec.eu.int*/.test(hostname): // staging_2 branch
                    this._env = Environment.CoinNginxReverseProxyStagingMainCoin;
                    break;

                case /^coin-nginx-reverse-proxy-staging-main-coin.apps.ocp.jrc.ec.europa.eu*/.test(hostname): // staging_2 branch
                    this._env = Environment.CoinNginxReverseProxyStagingMainCoinProduction;
                    break;

                case /^coin-frontend-cointools-main-coin.apps.ocp.jrc.ec.europa.eu*/.test(hostname):
                    this._env = Environment.OpenshiftProduction;
                    break;

                case /^coin-backend-api-2-main-coin.apps.ocpt.jrc.cec.eu.int*/.test(hostname):
                    this._env = Environment.NoProxStage;
                    break;

                case /^coin-backend-api-2-main-coin.apps.ocp.jrc.ec.europa.eu*/.test(hostname):
                    this._env = Environment.NoProxProd;
                    break;

                case /^coinapi.jrc.com*/.test(hostname): // staging_3 branch
                    this._env = Environment.coinApiJRC;
                    break;

                default:
                    console.error(`Cannot find environment for host name ${hostname}`);
                    return;
                    break;
            }
        }

        localStorage.setItem('env', this._env);
    }

    private determineEnvVariables(): void {
        let environment = this._envMapOfVars.get(this._env);

        if (!environment) {
            throw new Error('Given environment variables not found -> ' + this._env);
        }

        localStorage.setItem('environment', JSON.stringify(environment));
    }

    private setEnvVariables(): void {
        this._env = <Environment>localStorage.getItem('env');

        const environment = JSON.parse(localStorage.getItem('environment'));

        this._title = environment.title;
        this._showEnvTitleOnLogo = environment.showEnvTitleOnLogo;
        this._production = environment.production;
        this._enableDevToolRedux = environment.enableDevToolRedux;
        this._apiUrl = environment.apiUrl;
        this._cmsApiUrl = environment.cmsApiUrl;
        this._i18nUrl = environment.i18nUrl;
        this._cmsStorageUrl = environment.cmsStorageUrl;
        this._enableAnalyticsTracking = environment.enableAnalyticsTracking;
        this._matomoUrl = environment.matomoUrl;
        this._matomoSiteId = environment.matomoSiteId;
        this._enableGoogleAnalyticsTracking = environment.enableGoogleAnalyticsTracking;
        this._googleAnalyticsSiteId = environment.googleAnalyticsSiteId;
    }
}
