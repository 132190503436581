import { Injectable } from '@angular/core';
import { C3Routes } from '@features/cultural-creative-cities/cultural-creative-cities-routes';
import { DataHubRoutes } from '@features/data-hub/data-hub-routes';
import { EuMimfRoutes } from '@features/eu-mimf/eu-mimf-routes';
import { GesMonitorRoutes } from '@features/ges-monitor/ges-monitor-routes';
import { SocialScoreboardRoutes } from '@features/social-scoreboard/social-scoreboard-routes';

@Injectable()
export class ToolHelper {

    // @ts-ignore
    public static toolRouters = new Map([
        [
            DataHubRoutes.MAIN_URL,
            DataHubRoutes.routesClass
        ],
        [
            EuMimfRoutes.MAIN_URL,
            EuMimfRoutes.routesClass
        ],
        [
            GesMonitorRoutes.MAIN_URL,
            GesMonitorRoutes.routesClass
        ],
        [
            C3Routes.MAIN_URL,
            C3Routes.routesClass
        ],
        [
            SocialScoreboardRoutes.MAIN_URL,
            SocialScoreboardRoutes.routesClass
        ],
    ]);
}
