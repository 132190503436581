import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromEditions from './editions.reducers';

import { CoinState } from '../index';
import {selectAllIndices} from "@features/composite-indicator/state/indices/indices.selectors";

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectEditionsState = createSelector(
    selectCoinState,
    (state) => state.editions,
);

export const selectAllEditions = createSelector(
    selectEditionsState,
    fromEditions.selectAll,
);

export const selectAllLatestEditions = createSelector(
    selectAllIndices,
    selectAllEditions,
    (indices, editions) => editions.filter(ed => indices.find(ind => ind.code === ed.indice && ind.latest_edition === ed.year))
);

export const selectEditionsByIndice = (code: string) => createSelector(
    selectAllEditions,
    (editions) => editions.filter((edition) => edition.indice === code),
);

export const selectEdition = (code: string, year: number) => createSelector(
    selectAllEditions,
    (editions) => editions.find((ed) => ed.indice === code && ed.year === year),
);

export const selectLastEdition = (code: string) => createSelector(
    selectEditionsByIndice(code),
    (editions) => !editions.length ? null : editions.sort((a, b) => a.year > b.year ? -1 : 1)[0],
);

export const getEditionLinks = (indice: string, year: number) => createSelector(
    selectEdition(indice, year),
    (edition) => edition ? edition.links : null
);

/**
 * Returns an url for an edition based on its rel
 * @param {string}indice The indice code
 * @param {number}year The edition year
 * @param {string}rel The type of link to return
 * @returns {string} The requested url
 */
export const getEditionUrlByRel = (indice: string, year: number, rel: string) => createSelector(
    getEditionLinks(indice, year),
    (links) => {
        let url = findLink(links, rel);

        // if (rel === 'colors') {
        //     url = 'http://demo.api.coin-dev.eu/api/v1/en/indices/C3/2019/colors';
        // }
        return url;
    },
);

const findLink = function(links, rel) {
    try {
        return links.find((link) => link.rel === rel).href;
    } catch (error) {
        return null;
    }
};
