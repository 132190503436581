
import { CmsActions, CmsActionTypes } from '@features/general/cms/state/cms.actions';
import { cmsAdapter, CmsState } from '@features/general/cms/state/cms.entity';

export const initialCmsReducerState: CmsState = cmsAdapter.getInitialState({
});

export function cmsReducer(state = initialCmsReducerState, action: CmsActions): CmsState {
    switch (action.type) {
        case CmsActionTypes.CmsPagesLoaded:
            return cmsAdapter.setAll(action.payload.pages, state);
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
} = cmsAdapter.getSelectors();
