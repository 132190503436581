import { Action } from '@ngrx/store';
import { SortTypes } from '@shared/enums';
import {Chart2HoverElementTypes} from "@features/charts2/types/charts.enums";
import {ICharts2TabbedHighlight} from "@features/charts2/interfaces";

export interface Charts2Action extends Action {
    payload?: any;
}

export enum Charts2ActionsTypes {
    Charts2Print = '[CHARTS] print chart',
    Charts2SortData = '[CHARTS] sort data',
    Charts2SetSorter = '[CHARTS] set sorter',
    Charts2SaveAsPNG = '[CHARTS] export to png',
    Charts2RequestPNGUri = '[CHARTS] request png uri',
    Charts2SetPNGUri = '[CHARTS] set png uri',
    Charts2SetTooltipData = '[CHARTS] sets data for tooltip',
    Charts2RequestTooltipData = '[CHARTS] Requests data for tooltip',
    Charts2HideTooltip = '[CHARTS] Request the tooltip to hide',
    Charts2SetHighlights = '[CHARTS] Set highlights',
    Charts2HoverHighlight = '[CHARTS] Hover highlight',
    Charts2HoverElement = '[CHARTS] Hover element',
    Charts2ResetHighlight = '[CHARTS] reset highlight',
    Charts2RequestMetadata = '[CHARTS] Request metadata'
}

export class Charts2Print implements Charts2Action {
    readonly type = Charts2ActionsTypes.Charts2Print;
}

export class Charts2SortData implements Charts2Action {
    readonly type = Charts2ActionsTypes.Charts2SortData;

    constructor(public payload: SortTypes) {}
}

export class Charts2SetSorter implements Charts2Action {
    readonly type = Charts2ActionsTypes.Charts2SetSorter;
}

export class Charts2SaveAsPNG implements Charts2Action {
    readonly type = Charts2ActionsTypes.Charts2SaveAsPNG;
    constructor(public payload?: {[option: string]: number | string}) {}
}

export class Charts2RequestPNGUri implements Charts2Action {
    readonly type = Charts2ActionsTypes.Charts2RequestPNGUri;
    constructor(public payload?: {[option: string]: number | string}) {}
}

export class Charts2SetPNGUri implements Charts2Action {
    readonly type = Charts2ActionsTypes.Charts2SetPNGUri;
    constructor(public payload: string) {}
}

export class Charts2SetTooltipData implements Charts2Action {
    readonly type = Charts2ActionsTypes.Charts2SetTooltipData;
    constructor(public payload: any) {}
}

export class Charts2RequestTooltipData implements Charts2Action {
    readonly type = Charts2ActionsTypes.Charts2RequestTooltipData;
    constructor(public payload: any) {}
}

export class Charts2HideTooltip implements Charts2Action {
    readonly type = Charts2ActionsTypes.Charts2HideTooltip;
}

export class Charts2SetHighlights implements Charts2Action {
    readonly type = Charts2ActionsTypes.Charts2SetHighlights;
    constructor(public payload: { chart_id: string; data: ICharts2TabbedHighlight[]; }) {}
}

export class Charts2RequestMetadata implements Charts2Action {
    readonly type = Charts2ActionsTypes.Charts2RequestMetadata;
    constructor(public payload: { chart_id: string; options?: any; }) {}
}

export class Charts2HoverElement implements Charts2Action {
    readonly type = Charts2ActionsTypes.Charts2HoverElement;
    constructor(public payload: { chart_id: string; element_type: Chart2HoverElementTypes, id: any }) {}
}

export class Charts2ResetHighlight implements Charts2Action {
    readonly type = Charts2ActionsTypes.Charts2ResetHighlight;
    constructor(public payload: { chart_id: string; }) {}
}

export type Charts2Actions = Charts2Print
    | Charts2SortData
    | Charts2SetSorter
    | Charts2SaveAsPNG
    | Charts2RequestPNGUri
    | Charts2SetPNGUri
    | Charts2SetTooltipData
    | Charts2RequestTooltipData
    | Charts2HideTooltip
    | Charts2SetHighlights
    | Charts2RequestMetadata
    | Charts2HoverElement
    | Charts2ResetHighlight;
