import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import {
    DataActionTypes, DataAllMainDataForOneUnitRequested,
    DataByUnitRequested,
    DataByUrlRequested,
    DataLoaded, DataRescaledByUnitRequested, MainDataByUnitRequested,
} from './data.actions';
import {
    selectDataHubUnitAllMainDataLoaded,
    selectDataMainUnitIsLoaded,
    selectDataUrlIsLoaded
} from './data.selectors';
import { DataService } from './data.service';

@Injectable()
export class DataEffects {

    loadDataByUrl$ = createEffect(() =>this.actions$
        .pipe(
            ofType<DataByUrlRequested>(DataActionTypes.DataByUrlRequested),
            map((request) => request.payload.url),
            mergeMap((url) => of(url)
                .pipe(
                    withLatestFrom(this.store.select(selectDataUrlIsLoaded(url))),
                    filter(([furl, loaded]) => !loaded),
                    mergeMap(([furl, loaded]) => this.dataService.getDataByUrl(url)),
                    map( (data) => {
                        if (!!data.nextPage) {
                            this.store.dispatch(new DataByUrlRequested({ url: data.nextPage }));
                        }
                        return new DataLoaded( { url, data: data.data, end: !data.nextPage });
                    }),
                ),
            ),
        ));

    loadDataByUnit$ = createEffect(() =>this.actions$
        .pipe(
            ofType<DataByUnitRequested>(DataActionTypes.DataByUnitRequested),
            map((request) => request.payload),
            mergeMap((request) => of(request)
                .pipe(
                    withLatestFrom(this.store.select(selectDataMainUnitIsLoaded(request.indice, request.edition, request.unit_id))),
                    filter(([request, loaded]) => !loaded),
                    mergeMap(([request, loaded]) => this.dataService.getDataForOneIndiceAndUnit(request.indice, request.edition, request.unit_id)),
                    map( (data) => {
                        if (!!data.nextPage) {
                            this.store.dispatch(new DataByUrlRequested({ url: data.nextPage }));
                        }
                        return new DataLoaded( { url: data.url, data: data.data, end: !data.nextPage });
                    }),
                ),
            ),
        ));

    loadMainDataByUnit$ = createEffect(() =>this.actions$
        .pipe(
            ofType<MainDataByUnitRequested>(DataActionTypes.MainDataByUnitRequested),
            map((request) => request.payload),
            mergeMap((request) => of(request)
                .pipe(
                    withLatestFrom(this.store.select(selectDataMainUnitIsLoaded(request.indice, request.edition, request.unit_id))),
                    filter(([request, loaded]) => !loaded),
                    mergeMap(([request, loaded]) => this.dataService.getMainDataForOneIndiceAndUnit(request.indice, request.edition, request.unit_id)),
                    map( (data) => {
                        if (!!data.nextPage) {
                            this.store.dispatch(new DataByUrlRequested({ url: data.nextPage }));
                        }
                        return new DataLoaded( { url: data.url, data: data.data, end: !data.nextPage });
                    }),
                ),
            ),
        ));

    loadAllMainDataByUnit$ = createEffect(() =>this.actions$
        .pipe(
            ofType<DataAllMainDataForOneUnitRequested>(DataActionTypes.DataAllMainDataForOneUnitRequested),
            map((request) => request.payload.code),
            mergeMap((code) => of(code)
                .pipe(
                    withLatestFrom(this.store.select(selectDataHubUnitAllMainDataLoaded(code))),
                    filter(([request, loaded]) => !loaded),
                    mergeMap(([request, loaded]) => this.dataService.getMainDataForOneUnitAllIndices(code)),
                    map( (data) => {
                        if (!!data.nextPage) {
                            this.store.dispatch(new DataByUrlRequested({ url: data.nextPage }));
                        }
                        return new DataLoaded( { url: data.url, data: data.data, end: !data.nextPage });
                    }),
                ),
            ),
        ));

    rescaledDataByUnitRequested$ = createEffect(() =>this.actions$
        .pipe(
            ofType<DataRescaledByUnitRequested>(DataActionTypes.DataRescaledByUnitRequested),
            map(request => request.payload),
            map(request => this.dataService.makeRescaledUnitUrl(request.unit_id, request.environment)),
            map(url => new DataByUrlRequested({ url }))
        ));

    constructor(private actions$: Actions, private dataService: DataService,
                private store: Store<any>) {}
}
