import { Injectable } from '@angular/core';
import { IComponentVariable } from '@shared/interfaces';
import { SharedService } from '@shared/shared.service';
import { Observable } from 'rxjs';

@Injectable()
export class ComponentVariablesService extends SharedService {
    private componentVariablesUrl = 'component-variable/';

    getByIds(ids: number[]): Observable<IComponentVariable[]> {
        return this.getByUrl<IComponentVariable[]>(this.getApiUrl() + this.componentVariablesUrl + ids[0]);
    }
}
