import { createSelector } from '@ngrx/store';
import * as fromCmsCharts from '@features/general/charts/state/cms-charts.reducer';

import { CmsChartsSeriesHelper } from '@features/general/charts/state/series/cms-chart-series.entity';
import { selectGeneralState } from '@features/general/general.selectors';
import {ArrayHelper} from "@shared/helpers";

export const selectCmsChartsSeriesState = createSelector(
    selectGeneralState,
    (state) => state.charts.series
);

export const selectAllCmsChartsSeries = createSelector(
    selectCmsChartsSeriesState,
    fromCmsCharts.selectAlLChartsSeries
);

export const selectAllCmsChartsEntitiesSeries = createSelector(
    selectCmsChartsSeriesState,
    fromCmsCharts.selectEntitiesChartsSeries
);

export const selectCmsChartsGetChartSeriesElems = (chart_id: string) => createSelector(
    selectAllCmsChartsSeries,
    (series) => CmsChartsSeriesHelper.getSeriesByChartId(series, chart_id)
);

export const selectCmsChartsGetChartsSeriesElems = (chart_ids: string[]) => createSelector(
    selectAllCmsChartsSeries,
    (series) => CmsChartsSeriesHelper.getSeriesByChartIds(series, chart_ids)
);

export const selectCmsChartsSeriesElemsWithSomeParams = (chart_id: string, params: string[]) => createSelector(
    selectCmsChartsGetChartSeriesElems(chart_id),
    (series) => {
        return CmsChartsSeriesHelper.getSeriesWithSomeParams(series, params);
    }
);

export const selectCmsChartsMultipleSeriesElemsWithSomeParams = (args: {chart_id: string, params: string[] | string}[]) => createSelector(
    selectCmsChartsGetChartsSeriesElems(ArrayHelper.unique(args, 'chart_id')),
    (series) => {
        return CmsChartsSeriesHelper.getSeriesWithSomeParamsMultiple(series, args);
    }
);

export const selectCmsChartsGetChartSeriesUrls = (chart_id: string) => createSelector(
    selectCmsChartsGetChartSeriesElems(chart_id),
    (series) => series.map((elem) => elem.url).filter((url) => !!url)
);

export const selectCmsChartsGetMultipleChartsSeriesUrls = (chart_ids: string[]) => createSelector(
    selectAllCmsChartsSeries,
    (series) => series.filter((s) => chart_ids.includes(s.chart_id))
);

export const selectCmsChartsSeriesGetChartIdsFromSeriesId = (series_id: string) => createSelector(
    selectAllCmsChartsSeries,
    (series) => {
        const ids = series.filter((s) => CmsChartsSeriesHelper.getIdFromChartAndName(s.chart_id, s.name) === series_id)
            .map((s) => s.chart_id);

        return Array.from(new Set(ids));
    }
);

export const selectCmsChartsSeriesGetChartIdsFromSeriesUrl = (url: string) => createSelector(
    selectAllCmsChartsSeries,
    (series) => {
        const ids = series.filter((s) => s.url === url).map((s) => s.chart_id);
        return Array.from(new Set(ids));
    }
);

export const selectCmsChartsSeriesGetChartIdsFromSeriesUrls = (urls: string[]) => createSelector(
    selectAllCmsChartsSeries,
    (series) => {
        const ids = series.filter((s) => urls.includes(s.url)).map((s) => s.chart_id);
        return Array.from(new Set(ids));
    }
);

export const selectCmsChartsSeriesGetSeriesFromUrls = (urls: string[]) => createSelector(
    selectAllCmsChartsSeries,
    (series) => series.filter((s) => urls.includes(s.url) && s.url != null)
);

export const selectCmsChartsSeriesForMultipleCharts = (chart_ids: string[]) => createSelector(
    selectAllCmsChartsSeries,
    (series) => series.filter((s) => chart_ids.includes(s.chart_id))
);

export const selectCmsChartsSeriesFromSomeParams = (args: {chart_id: string, names: string}[]) => createSelector(
    selectCmsChartsSeriesForMultipleCharts(ArrayHelper.uniquePluck(args, 'chart_id')),
    (series) => {
        return series.filter((s) => {
            const arg = args.find((d) => d.chart_id === s.chart_id);
            const s_params = [...s.params, ...s.optional_params];
            return ArrayHelper.intersects(s_params, arg.names);
        })
    }
);
