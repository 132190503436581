import { NgModule } from '@angular/core';
import { ColumnChartComponent } from './components/columns/column-chart/column-chart.component';
import { ColumnRangeComponent } from './components/columns/column-range/column-range.component';
import { LegendMultiBarChartComponent } from './components/legend-multi-bar-chart/legend-multi-bar-chart.component';
import { MapChartComponent } from './components/map-chart/map-chart.component';

@NgModule({
    declarations: [
        ColumnChartComponent,
        ColumnRangeComponent,
        MapChartComponent,
        LegendMultiBarChartComponent
    ],
    imports: [
  ],
    exports: [
        ColumnChartComponent,
        ColumnRangeComponent,
        MapChartComponent,
        LegendMultiBarChartComponent,
    ],
})
export class ChartsModule { }
