import { Action } from '@ngrx/store';
import { IVariable } from '@shared/interfaces';

export enum VariablesActionTypes {
    EditionVariablesRequested = '[Component] Variables requested for one edition',
    VariablesByUrlRequested = '[Component] Variables by url requested',
    VariablesLoaded = '[Component] Variables Loaded',
}

export class EditionVariablesRequested implements Action {
    readonly type = VariablesActionTypes.EditionVariablesRequested;

    constructor(public payload: { indice: string, edition: number }) {}
}

export class VariablesByUrlRequested implements Action {
    readonly type = VariablesActionTypes.VariablesByUrlRequested;

    constructor(public payload: { url: string }) {}
}

export class VariablesLoaded implements Action {
    readonly type = VariablesActionTypes.VariablesLoaded;

    constructor(public payload: { variables: IVariable[] }) {}
}

export type VariablesActions =
    | EditionVariablesRequested
    | VariablesByUrlRequested
    | VariablesLoaded;
