import { Injectable } from '@angular/core';
import { environment } from 'app/../environments/environment';
import { GeneralService } from '@features/general/general.service';

@Injectable()
export class CustomI18nService extends GeneralService {

    /**
     * @param locale
     */
    public getI18nUrl(locale?: string): string {
        return this.translationsService.ensureLocaleInUrl(this.envService.i18nUrl, locale);
    }

    /**
     * API Calls
     */

    /**
     * @param {string} locale
     * @param callback
     */
    public getExternalTranslations(locale: string, callback): void {
        this.getExternalData(this.getI18nUrl(locale) + '/all').subscribe((result) => callback(result));
    }
}
