import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IComponentVariable } from '@shared/interfaces';
import { ComponentVariablesActions, ComponentVariablesActionTypes } from './component-variables.actions';

export interface ComponentVariablesState extends EntityState<IComponentVariable> {}

export const adapter: EntityAdapter<IComponentVariable> = createEntityAdapter<IComponentVariable>();

export const initialComponentVariablesState: ComponentVariablesState = adapter.getInitialState();

export function componentVariablesReducer(
    state = initialComponentVariablesState ,
    action: ComponentVariablesActions): ComponentVariablesState {

    switch (action.type) {
        case ComponentVariablesActionTypes.ComponentVariablesLoaded:
            return adapter.addMany(action.payload.componentVariables, state);
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
