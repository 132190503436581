import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { selectScalesUrlIsLoaded } from '@features/composite-indicator/state/scales/scales.selectors';
import { ScalesActionTypes, ScalesByEditionRequested, ScalesByUrlRequested, ScalesLoaded } from './scales.actions';
import { ScalesService } from './scales.service';

@Injectable()
export class ScalesEffects {

    loadLevelsByUrl$ = createEffect(() =>this.actions$
        .pipe(
            ofType<ScalesByUrlRequested>(ScalesActionTypes.ScalesByUrlRequested),
            map((request) => request.payload.url),
            mergeMap((url) => of(url)
                .pipe(
                    withLatestFrom(this.store.select(selectScalesUrlIsLoaded(url))),
                    filter(([action, loaded]) => !loaded),
                    switchMap(() => this.scalesService.getScalesByUrl(url)),
                    map( (scales) => new ScalesLoaded( { url, scales })),
                ),
            ),
        ));

    requestColorsByEdition$ = createEffect(() =>this.actions$
        .pipe(
            ofType<ScalesByEditionRequested>(ScalesActionTypes.ScalesByEditionRequested),
            map((action) => action.payload),
            mergeMap((action) => of(action)
                .pipe(
                    map(() => this.scalesService.getScalesByEditionUrl(action.indice, action.edition)),
                    map((url) => new ScalesByUrlRequested({ url })),
                )),
        ));

    constructor(private actions$: Actions, private scalesService: ScalesService,
                private store: Store<any>) {}
}
