import {
    ICmsChartHighlight,
    selectCmsChartHighlightId
} from '@features/general/charts/state/highlights/cms-chart-highlights.entity';
import {
    CmsChartParamConversionType,
    ICmsChartParameter,
    selectCmsChartId
} from '@features/general/charts/state/main/entities';
import { CmsChartParametersHelper } from '@features/general/charts/state/params/cms-chart-parameter-helper';
import { CmsChartsFiltersDisplay, CmsChartsLoadingState, ICmsChart } from '@features/general/charts/structure';
import { IList, ListsCmsHelper, ListsCmsSlugs } from '@features/general/lists/structure';

export class CmsChartsHighlightsHelper {
    static highlightConversor = (chart_id: string, highlight: ICmsChartHighlight) => {
        return {
            chart_id,
            label: highlight.label,
            description: highlight.description,
            list_id: null,
            param: highlight.param,
            relations: highlight.relations,
            slug: highlight.slug,
            type: highlight.type,
            values: highlight.values,
            options: highlight.options,
            display: (highlight.options && highlight.options.display) || CmsChartsFiltersDisplay.enabled,
            active_values: [],
            status: highlight.status
        };
    }
    static chartHighlightConversor = (chart_id: string, highlights: ICmsChartHighlight[]): ICmsChartHighlight[] => {
        return highlights.map((highlight) => CmsChartsHighlightsHelper.highlightConversor(chart_id, highlight));
    }

    static chartHighlightsConversor = (charts: ICmsChart[]): ICmsChartHighlight[] => {
        return charts.reduce((highlights, chart) => {
            const chart_id = selectCmsChartId(chart);
            const chart_highlights = CmsChartsHighlightsHelper.chartHighlightConversor(chart_id, chart.highlights);
            highlights.push(...chart_highlights);
            return highlights;
        }, []);
    }

    static getIdFromChartAndName = (chart_id: string, param: string): string => {
        return selectCmsChartHighlightId({ chart_id, param });
    }

    static getUrlFromParams = (chart_id: string, slug: ListsCmsSlugs, chart_params: ICmsChartParameter[], conversionRules = null): string => {
        const params = CmsChartParametersHelper.convertToCmsParams(chart_params, CmsChartParamConversionType.converted);
        const url = ListsCmsHelper.getPartialListCmsUrlBySlug(slug, params, conversionRules);

        return url;
    }

    static convertToHighlights = (highlight: ICmsChartHighlight, values: IList, param: ICmsChartParameter): ICmsChartHighlight => {
        if (!highlight) { return null; }

        const display = (highlight.options && highlight.options.display) || CmsChartsFiltersDisplay.enabled;

        return {
            ...highlight,
            values: highlight.values || values && values.values || [],
            active: param ? param.value : null,
            options: { display, ...highlight.options },
            time_stamp: 0,
            status: highlight.status
        };
    }

    static multipleConvertToHighlights = (highlights: ICmsChartHighlight[], values: IList[], params: ICmsChartParameter[] = []): ICmsChartHighlight[] => {
        return highlights.map((highlight_elem) => {
            const highlight_data = values.find((d) => d.id === highlight_elem.list_id);
            const highlight_param = params.find((param) => param.name === highlight_elem.param);
            return CmsChartsHighlightsHelper.convertToHighlights(highlight_elem, highlight_data, highlight_param);
        });
    }

    static cloneHighlight = (highlight: ICmsChartHighlight, newChartId: string): ICmsChartHighlight => {
        return { ...highlight, chart_id: newChartId };
    }

    static cloneHighlights = (highlights: ICmsChartHighlight[], newChartId: string): ICmsChartHighlight[] => {
        return highlights.map((h) => CmsChartsHighlightsHelper.cloneHighlight(h, newChartId));
    }

}
