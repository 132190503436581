import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CategoriesState } from './categories.reducers';

import * as fromCategories from './categories.reducers';

import { CoinState } from '../index';
import {ArrayHelper} from "@shared/helpers";

export const selectCoinState = createFeatureSelector<CoinState>('composite');

export const selectCategoriesState = createSelector(
    selectCoinState,
    (state) => state.categories,
);

export const selectAllCategories = createSelector(
    selectCategoriesState,
    fromCategories.selectAll,
);

export const selectAllCategoriesFlattened = createSelector(
    selectCategoriesState,
    fromCategories.selectEntities
);

export const selectCategoryById = (id: number) => createSelector(
    selectAllCategoriesFlattened,
    (categories) => categories.hasOwnProperty(id) ? categories[id] : null,
);

export const selectAllCategoriesLoaded = createSelector(
    selectCategoriesState,
    (categoriesState) => categoriesState.allCategoriesLoaded,
);
