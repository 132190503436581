import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IQueueItem } from '@features/general/queue/structure/queue.interfaces';

export function selectListId(item: IQueueItem): string {
  //  return item.id + ''
    return JSON.stringify({item: item.action, relation: item.relation});
}

export const queueAdapter: EntityAdapter<IQueueItem> =
    createEntityAdapter<IQueueItem>({
        selectId: selectListId
    });

export interface QueueState extends EntityState<IQueueItem>{}
