import { Injectable } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { effect } from 'signal';
import { Store } from '@ngrx/store';
import { concatMapTo, filter, first, map, mapTo, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import {
    AllCategoryGroupsLoaded,
    AllCategoryGroupsRequested, CategoryGroupLoaded, CategoryGroupRequested, CategoryGroupsActionTypes,
} from './category-groups.actions';
import { selectAllCategoryGroupsLoaded, selectCategoryGroupById } from './category-groups.selectors';
import { CategoryGroupsService } from './category-groups.service';
import { of } from 'rxjs';

@Injectable()
export class CategoryGroupsEffects {

    loadCategoryGroup$ = this.actions$
        .pipe(
            ofType<CategoryGroupRequested>(CategoryGroupsActionTypes.CategoryGroupRequested),
            map(request => request.payload.id),
            mergeMap(id => of(id)
                .pipe(
                    withLatestFrom(this.store.select(selectCategoryGroupById(id))),
                    filter(([id, categoryGroup]) => !categoryGroup),
                    mergeMap(([id, categoryGroup]) => this.categoryGroupsService.loadCategoryGroup(id)),
                    map(group => new CategoryGroupLoaded({ group }))
                )
            )
        );

    loadAllCategoryGroups$ = this.actions$
        .pipe(
            ofType<AllCategoryGroupsRequested>(CategoryGroupsActionTypes.AllCategoryGroupsRequested),
            withLatestFrom(this.store.select(selectAllCategoryGroupsLoaded)),
            filter(([action, allCatLoaded]) => !allCatLoaded),
            mergeMap(() => this.categoryGroupsService.loadCategoryGroups()),
            map((groups) => new AllCategoryGroupsLoaded({ groups })),
        );

    constructor(private actions$: Actions,
                private categoryGroupsService: CategoryGroupsService,
                private store: Store<any>) {

    }
}
