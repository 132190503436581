import {ListsRequestedByUrl} from '@features/general/lists/state/lists.actions';
import {
    IList,
    IListElem,
    ListLoadingStates,
    ListsCmsHelper,
    ListsCmsSlugs,
    ListsScopes
} from '@features/general/lists/structure';
import {CmsChartsSetParams} from '@features/general/charts/state/params/cms-charts-params.actions';
import {ArrayHelper} from "@shared/helpers";
import {
    CmsChartsFiltersDisplay,
    CmsChartsLoadingState,
    ICmsChart,
    ICmsChartFilter
} from "@features/general/charts/structure";
import {
    CmsChartParamConversionType,
    ICmsChartFilterElem,
    ICmsChartParameter,
    selectCmsChartFilterId,
    selectCmsChartId
} from "@features/general/charts/state/main/entities";
import {CmsChartParametersHelper} from "@features/general/charts/state/params/cms-chart-parameter-helper";

class CmsChartsFiltersHelper {

    static convertListIntoSetParams = (lists, requests) => {
        const set_params = lists.map((list) => {
            const params = requests.filter((f) => f.url === list.id)
                .map((f) => ({ chart_id: f.chart_id, name: f.param }));

            return {
                params,
                values: ArrayHelper.flattenMultiLevelArray(list.values).filter((elem) => !elem.hasOwnProperty('hasData') || elem.hasData)
            };
        })
            .filter((list) => !!list.params.length)
            .reduce((params, current) => {
                try {
                    const new_params = current.params.map((param, i) => ({ ...param, value: current.values[i].key }));
                    params.push(...new_params);
                } catch (e) {
                    console.log(current);
                }

                return params;
            }, []);

//        const param = CmsChartsHelper.setParamsFromList(current.params, values);
        return !!set_params.length ? [new CmsChartsSetParams(set_params)] : [];
    }

    static convertListNotLoadedIntoListRquestedByUrl = (type: ListsScopes, slug: string, url: string) => {
        return (new ListsRequestedByUrl({ type: ListsScopes.page, slug, url }));
    }

    static filterConversor = (chart_id: string, filter: ICmsChartFilter, paramsOptions?): ICmsChartFilterElem => {
        const f_options = filter?.options || {};
        const f_paramsOptions = (paramsOptions || {})[filter.param] || {};

        const options = { ...f_options, ...f_paramsOptions };
        return {
            chart_id,
            label: filter.label,
            description: filter.description,
            list_id: null,
            param: filter.param,
            relations: filter.relations,
            slug: filter.slug,
            type: filter.type,
            values: filter.values,
            options,
            display: (options?.display) || CmsChartsFiltersDisplay.hidden,
            status: filter.status
        };
    }

    static chartFilterConversor = (chart_id: string, filters: ICmsChartFilter[], paramsOptions?): ICmsChartFilterElem[] => {
        return filters.map((filter) => CmsChartsFiltersHelper.filterConversor(chart_id, filter, paramsOptions));
    }

    static chartFiltersConversor = (charts: ICmsChart[]): ICmsChartFilterElem[] => {
        return charts.reduce((filters, chart) => {
            const chart_id = selectCmsChartId(chart);
            const chart_filters = CmsChartsFiltersHelper.chartFilterConversor(chart_id, chart.filters, chart?.options?.params || {});
            filters.push(...chart_filters);
            return filters;
        }, []);
    }

    static getIdFromChartAndName = (chart_id: string, param: string): string => {
        return selectCmsChartFilterId({ chart_id, param });
    }

    static getUrlFromParams = (chart_id: string, slug: ListsCmsSlugs, chart_params: ICmsChartParameter[], conversionRules = null): string => {
        const params = CmsChartParametersHelper.convertToCmsParams(chart_params, CmsChartParamConversionType.converted);
        return ListsCmsHelper.getPartialListCmsUrlBySlug(slug, params, conversionRules);
    }

    static convertToFilters = (filter: ICmsChartFilterElem, list: IList, param: ICmsChartParameter): ICmsChartFilter => {
        if (!filter) { return null; }
        let values;

        if (filter?.values?.length > 0) {
            values = filter.values;
        } else {
            values = CmsChartsFiltersHelper.FilterValuesFromOptions(list?.values || [], filter.options);
        }

        const listStatus = CmsChartsFiltersHelper.convertListToCmsChartLoadingStatus(list?.status || ListLoadingStates.loading);

        const status = () => {
            if (filter?.values?.length > 0) {
                return CmsChartsLoadingState.loaded;
            } /*else if ((listStatus === CmsChartsLoadingState.loaded) && (param?.strategy === CmsChartLoadStrategies.none)) {
                return CmsChartsLoadingState.awaiting;
            }*/ else {
                return listStatus;
            }
        }

        const display = filter?.display || filter?.options?.display || CmsChartsFiltersDisplay.hidden;
        return {
            slug: filter.slug,
            param: filter.param,
            label: filter.label,
            description: filter.description,
            type: filter.type,
            relations: filter.relations,
            values,
            active: param ? param.value : null,
            options: { display, ...filter.options },
            display,
            status: status() // filter?.values?.length > 0 ? CmsChartsLoadingState.loaded : CmsChartsFiltersHelper.convertListToCmsChartLoadingStatus(list?.status || ListLoadingStates.loading)
        } as ICmsChartFilter;
    }

    static multipleConvertToFilters = (filters: ICmsChartFilterElem[], values: IList[], params: ICmsChartParameter[] = []): ICmsChartFilter[] => {
        return filters.map((filter_elem) => {
            const filter_data = values.find((d) => d.id === filter_elem.list_id);
            const filter_param = params.find((param) => param.name === filter_elem.param);
            return CmsChartsFiltersHelper.convertToFilters(filter_elem, filter_data, filter_param);
        });
    }

    static cloneFilter = (filter: ICmsChartFilterElem, newChartId: string): ICmsChartFilterElem => {
        return { ...filter, chart_id: newChartId };
    }

    static cloneFilters = (filters: ICmsChartFilterElem[], newChartId: string): ICmsChartFilterElem[] => {
        return filters.map((filter) => CmsChartsFiltersHelper.cloneFilter(filter, newChartId));
    }

    static convertListToCmsChartLoadingStatus = (status: ListLoadingStates): CmsChartsLoadingState => {
        switch (status) {
            case ListLoadingStates.loading:
                return CmsChartsLoadingState.loading;
            case ListLoadingStates.error:
                return CmsChartsLoadingState.error;
            case ListLoadingStates.requested:
                return CmsChartsLoadingState.loading;
            case ListLoadingStates.nodata:
                return CmsChartsLoadingState.nodata;
            case ListLoadingStates.loaded:
                return CmsChartsLoadingState.loaded;
            default:
                return CmsChartsLoadingState.loaded;
        }
    }

    // todo: implement other strategies
    static FilterValuesFromOptions = (values: IListElem[], options) => {
        const filter = options?.actions?.filter;
        const attrs = [];
        const vals = [];

        // Strategy: filter values
        if (filter) {
            for (const param of Object.entries(filter)) {
                attrs.push(param[0]);
                vals.push(param[1]);
            }
            return ArrayHelper.filterNestedArray<IListElem>(values, attrs, vals);
        } else {
            return values;
        }
    }
}

export default CmsChartsFiltersHelper;
