import { Inject, Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { CONFIG_TOKEN, EuiAppConfig } from '@eui/core';

@Injectable()
export class ToolService {

    constructor(
        @Inject(CONFIG_TOKEN) private config: EuiAppConfig,
        protected router: Router
    ) {
        // ...
    }

    /**
     * @param {string} url
     */
    public getToolIdentifierFromUrl(url: string): string {
        const parts = url.split('/');
        return parts.length >= 2 ? parts[1] : null;
    }

    /**
     * @param {string} url
     */
    public getToolLocalesFromUrl(url: string): string[] {
        return this.getToolLocales(this.getToolIdentifierFromUrl(url));
    }

    /**
     * @param {string} toolId
     */
    public getToolLocales(toolId: string): string[] {
        const defaultLang = this.config.global.i18n.i18nService.defaultLanguage;
        let toolLangs = [defaultLang];

        switch (toolId) {
            case 'multidimensional-inequality': // EuMimfRoutes.MAIN_URL, can't use here because of circular dependency so just hardcoded
                toolLangs = ['en', 'fr', 'de', 'es'];
                break;

            default:
                break;
        }
        return toolLangs;
    }

    /**
     * @param {string} toolId
     */
    public getRouteByToolId(toolId: string): Route {
        let route = null;
        for (const r of this.router.config) {
            if (r.path !== toolId) {
                continue;
            }
            route = r;
            break;
        }
        // Get routes for empty path if possible
        if (!route) {
            for (const r of this.router.config) {
                if (r.path !== '') {
                    continue;
                }
                route = r;
                break;
            }
        }
        return route;
    }

    /**
     * @param {string} url
     */
    public getToolRouteByUrl(url: string): Route|null {
        return this.getRouteByToolId(this.getToolIdentifierFromUrl(url));
    }
}
