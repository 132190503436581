import { ScaleReferenceTypes } from '@shared/enums/app.enums';
import { IScale, IScenarioUnit, IStatusAnswer } from '@shared/interfaces';

export enum ScaleErrorTypes {
    OK = 'OK',
    ERROR = 'Error',
}

export class Scale implements IScale {
    public id: number;
    public indice: string;
    edition: number;
    component_id: number;
    input_min: number;
    input_max: number;
    output_min: number;
    output_max: number;
    denomination_value: number;
    denomination_label: string;
    unit: string;
    reference: ScaleReferenceTypes;
    description: string;

    private m: number;
    private b: number;

    constructor(args: IScale) {
        if (!args) {
            return;
        }
        Object.assign(this, args);
        try {
            this.m = (this.output_max - this.output_min) / (this.input_max - this.input_min);
            this.b = this.output_min - this.m * this.input_min;
        } catch (e) {
            this.m = 0;
        }
    }

    valueToScore(unit: IScenarioUnit, value: number): IStatusAnswer {
        let score: number;
        let result: IStatusAnswer;

        if (!this.infoIsValid(unit, value)) {
            result = {
                status: ScaleErrorTypes.ERROR,
                value: null,
                message: 'The information available is not enough to calculate the score',
            };
        } else {
            score = this.calcScore(value, unit);

            if (score == null) {
                result = {
                    status: ScaleErrorTypes.ERROR,
                    value: null,
                    message: 'Something wrong happened. Please check your data',
                };
            } else {
                result = {
                    status: ScaleErrorTypes.OK,
                    value: score,
                };
            }
        }

        return result;
    }

    scoreToValue(unit: IScenarioUnit, score: number): IStatusAnswer {
        let value: number;
        let result: IStatusAnswer;

        if (!this.infoIsValid(unit, score)) {
            result = {
                status: ScaleErrorTypes.ERROR,
                value: null,
                message: 'The information available is not enough to calculate the score',
            };
        } else {
            value = this.calcValue(score, unit);

            if (value == null) {
                result = {
                    status: ScaleErrorTypes.ERROR,
                    value: null,
                    message: 'Something wrong happened. Please check your data',
                };
            } else {
                result = {
                    status: ScaleErrorTypes.OK,
                    value,
                    message: 'Due to the nature of the statistical estimations, these values may differ from the actual city values',
                };
            }
        }

        return result;
    }

    private getReferenceValue(unit: IScenarioUnit): number {
        let reference_value: number;
        try {
            reference_value = (this.reference === ScaleReferenceTypes.NONE) ? 1 : unit.references.find((ref) => ref.reference === this.reference).value;
        } catch (e) {
            reference_value = null;
        }

        return reference_value;
    }

    private infoIsValid(unit: IScenarioUnit, value: number): boolean {
        let is_valid: boolean;
        if (value == null || isNaN(value)) {
            is_valid = false;
        } else if (!this.getReferenceValue(unit)) {
            is_valid = false;
        } else {
            is_valid = true;
        }

        return is_valid;
    }

    private denominated_value(value: number, unit: IScenarioUnit): number {
        const ref_value = this.getReferenceValue(unit);
        return value * this.denomination_value / ref_value;
    }

    private value_from_denominated(denominated_value: number, unit: IScenarioUnit): number {
        const ref_value = this.getReferenceValue(unit);

        return denominated_value * ref_value / this.denomination_value;
    }

    private calcScore(value: number, unit: IScenarioUnit): number {
        let denominated_value: number;
        let score: number;

        denominated_value = this.denominated_value(value, unit);

        score = this.b + this.m * denominated_value;

        return this.checkScoreLimits(score);
    }

    private checkScoreLimits(score: number): number {
        let limited_score: number;

        if (score < this.output_min) {
            limited_score = this.output_min;
        } else if (score > this.output_max) {
            limited_score = this.output_max;
        } else {
            limited_score = score;
        }

        return limited_score;
    }

    private checkValueLimits(value: number): number {
        let limited_value: number;

        if (value < this.output_min) {
            limited_value = this.output_min;
        } else if (value > this.output_max) {
            limited_value = this.output_max;
        } else {
            limited_value = value;
        }

        return limited_value;
    }

    private calcValue(score: number, unit: IScenarioUnit): number {
        let denominated_value: number;
        let value: number;

        denominated_value = (score - this.b ) / this.m;

        value = this.value_from_denominated(denominated_value, unit);

        return value; //this.checkValueLimits(value);
    }

}
