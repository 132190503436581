import { Action } from '@ngrx/store';

export enum CmsActionTypes {
    CmsPagesLoaded = '[CMS] Load CMS page',
    CmsPageRequested = '[CMS] Request CMS page',
}

export class CmsPagesLoaded implements Action {
    readonly type = CmsActionTypes.CmsPagesLoaded;
    constructor(public payload: {pages: any[]}) {}
}

export class CmsPageRequested implements Action {
    readonly type = CmsActionTypes.CmsPageRequested;
    constructor(public payload: {page: string, params: {[_: string]: any}}) {}
}

export type CmsActions = CmsPagesLoaded
    | CmsPageRequested;
