import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {CmsChartsFiltersDisplay, ICmsChartFilter} from '@features/general/charts/structure';

export interface ICmsChartHighlight extends ICmsChartFilter {
    chart_id: string;
    list_id: string;
    display: CmsChartsFiltersDisplay;
    active_values: any[];
    time_stamp?: number;
}

export function selectCmsChartHighlightId(highlight: Partial<ICmsChartHighlight>): string {
    return highlight.chart_id + '_' + highlight.param;
}

export const cmsChartHighlightAdapter: EntityAdapter<ICmsChartHighlight> =
    createEntityAdapter<ICmsChartHighlight>({
        selectId: selectCmsChartHighlightId
    });

export interface CmsChartHighlightState extends EntityState<ICmsChartHighlight> {}
