import { Injectable } from '@angular/core';
import { GeneralService } from '@features/general/general.service';
import { IFile, IResource } from '@shared/interfaces';

@Injectable()
export class CmsModuleService extends GeneralService {

    static prepareResourcesByTypeAsIFileArray(resources: IResource[]): any[] {
        // TODO filter files only like PDF, word, excel by file extension or mime type
        // Now no file type filtering for demo purposes because we don't have much files for different item types
        // !!['png', 'pdf', ...].includes(r.fileType.file_extension)
        return resources ? resources.filter((r) => !!r.links.length)
            .map((r) => {
                return {
                    title: r.name,
                    meta: '(' + r.size + ' - ' + r.fileType.file_extension.toUpperCase() + ')',
                    label: 'Download',
                    href: r.links[0].href,
                } as any; // IFile
            }) : [];
    }
}
