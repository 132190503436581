import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IUnitGroup } from '@shared/interfaces';
import { UnitGroupsActions, UnitGroupsActionTypes } from './unit-groups.actions';

export interface UnitGroupsState extends EntityState<IUnitGroup> {}

export const adapter: EntityAdapter<IUnitGroup> =
    createEntityAdapter<IUnitGroup>({
        selectId: (group) => `${group.indice}_${group.edition}_${group.unit_id}_${group.group_id}`,
    });

export const initialUnitGroupsState: UnitGroupsState = adapter.getInitialState();

export function unitGroupsReducer(state = initialUnitGroupsState , action: UnitGroupsActions): UnitGroupsState {

    switch (action.type) {
    case UnitGroupsActionTypes.UnitGroupsLoaded:
        // adapter.removeAll(state);
        return adapter.setAll(action.payload.groups, state);
    default: {
            return state;
        }
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal,

} = adapter.getSelectors();
