import { IChartGeoLayer, ICmsChartGeoLayer } from '@features/general/charts/state/geo/cms-chart-geo.entity';
import { ICmsChart } from '@features/general/charts/structure';
import { selectCmsChartId } from '@features/general/charts/state/main/entities';
import {
    GeoBasePathType,
    GeoHelper,
    GeoLayer, GeoLayerOrder, LayerTypes,
} from '@features/geo/structure/geo.structure';

export class CmsChartGeoHelper {

    static layerConvertor = (chart_id: string, layer: ICmsChartGeoLayer): IChartGeoLayer => {

        return {
            ...layer,
            chart_id,
            url: null,
            urls: [],
            level: layer.level || null,
            resolution: layer.resolution || null,
            version: layer.version || null,
            params: layer.params || null,
            order: CmsChartGeoHelper.GetOrder(layer),
            keys: {
                id: null,
                name: null,
                parent: null
            }
        };
    }

    static layersConvertor = (chart_id: string, layers: ICmsChartGeoLayer[]): IChartGeoLayer[] => {
        if (!layers) { return null; }

        return layers.map((layer) => CmsChartGeoHelper.layerConvertor(chart_id, layer));
    }

    static chartLayersConversor = (charts: ICmsChart[]): IChartGeoLayer[] => {
        return charts.reduce((layers: IChartGeoLayer[], chart) => {
            const chart_id = selectCmsChartId(chart);
            const chart_layers = CmsChartGeoHelper.layersConvertor(chart_id, chart.data.layers || []);
            layers.push(...chart_layers);
            return layers;
        }, []);
    }


    static cloneLayer = (layer: IChartGeoLayer, newChartId: string): IChartGeoLayer => {
        return { ...layer, chart_id: newChartId };
    }

    static cloneLayers = (layers: IChartGeoLayer[], newChartId: string): IChartGeoLayer[] => {
        return layers.map((layer) => CmsChartGeoHelper.cloneLayer(layer, newChartId));
    }

    static calcUrl = (layer: IChartGeoLayer, params): string => {
        const meta = GeoLayer.get(layer.source);
        return meta && meta.url(layer, params) || null;
    }

    static updateLayerUrls = (layer: IChartGeoLayer, params = null, baseUrls?: Map<GeoBasePathType, string>): IChartGeoLayer => {
        const meta = GeoLayer.get(layer.source);

        if (!meta || !CmsChartGeoHelper.validateGeoRules(meta.rules, layer, params)) {
            return {
                ...layer,
                url: null,
                keys: {
                    id: null,
                    name: null,
                    parent: null
                }
            };
        }

        const url = baseUrls ? baseUrls.get(meta.base) + meta.url(layer, params) : meta.url(layer, params);

        return {
            ...layer,
            url,
            keys: {
                id: meta.id,
                name: meta.name,
                parent: meta.parent
            }
        };
    }

    static updateLayersUrls = (layers: IChartGeoLayer[], params = null, baseUrls?: Map<GeoBasePathType, string>): IChartGeoLayer[] => {
        return layers.map((layer) => CmsChartGeoHelper.updateLayerUrls(layer, params, baseUrls));
    }

    // todo: needs refactoring as its considering params as an object instead of an array
    static validateGeoRules = (rules: string[], layer: IChartGeoLayer, params): boolean => {
        if (!rules) { return true; }
        return rules.reduce((result, rule) => {
            const current_validation = CmsChartGeoHelper.validateGeoRule(rule, layer) || CmsChartGeoHelper.validateGeoRule(rule, params);
            return rules && current_validation;
        }, true);
    }

    static validateGeoRule = (rule: string, obj: any): boolean => {
        if (!rule) { return true; }

        if (!obj) { return false; }

        if (!obj.hasOwnProperty(rule) || obj[rule] == null) { return false; }

        return true;
    }

    static GetOrder = (layer: ICmsChartGeoLayer): number => {
        let id: string;
        switch (layer.source) {
            case LayerTypes.nuts:
                id = LayerTypes.nuts + layer.level;
                break;
            default:
                id = layer.source;
                break;
        }
        return GeoLayerOrder.get(id) || null;
    }
}
