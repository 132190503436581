import { Action } from '@ngrx/store';

export enum BreadcrumbsStateActionTypes {
    SET_CUSTOM_LAST_TITLE = '[BREADCRUMBS] Set custom last title',
    RESET_CUSTOM_LAST_TITLE = '[BREADCRUMBS] Reset custom last title',
}

export class BreadcrumbsSetCustomLastTitle implements Action {
    readonly type = BreadcrumbsStateActionTypes.SET_CUSTOM_LAST_TITLE;

    constructor(public payload: { title: string }) {}
}

export class BreadcrumbsResetCustomLastTitle implements Action {
    readonly type = BreadcrumbsStateActionTypes.RESET_CUSTOM_LAST_TITLE;

    constructor() {}
}

export type BreadcrumbsStateActions = BreadcrumbsSetCustomLastTitle
    | BreadcrumbsResetCustomLastTitle;
