import { Action } from '@ngrx/store';
import { IStatistic } from '@shared/interfaces';
import {EnvironmentType, StatisticsType} from "@shared/enums/app.enums";

export enum StatisticsActionTypes {
    StatisticsByEditionRequested = '[STATISTICS] Requested all statistics for an edition',
    StatisticsLoaded = '[STATISTICS]  Statistics loaded',
    StatisticsByTypeRequested = '[STATISTICS] Request all statistics by type',
    StatisticsRescaledRequestedByType = '[STATISTICS] Request all rescaled statistics by type',
}

export class StatisticsByEditionRequested implements Action {
    readonly type = StatisticsActionTypes.StatisticsByEditionRequested;
}

export class StatisticsLoaded implements Action {
    readonly type = StatisticsActionTypes.StatisticsLoaded;

    constructor(public payload: { statistics: IStatistic[], url: string }) {}
}

export class StatisticsByTypeRequested implements Action {
    readonly type = StatisticsActionTypes.StatisticsByTypeRequested;

    constructor(public payload: {type: StatisticsType, environment: EnvironmentType}) {}
}

export class StatisticsRescaledRequestedByType implements Action {
    readonly type = StatisticsActionTypes.StatisticsRescaledRequestedByType;

    constructor(public payload: {type: StatisticsType, environment: EnvironmentType, min?: number, max?: number}) {}
}

export type StatisticsActions =
    StatisticsByEditionRequested
    | StatisticsLoaded
    | StatisticsByTypeRequested
    | StatisticsRescaledRequestedByType;
