import {selectGeneralState} from '@features/general/general.selectors';
import {createSelector} from '@ngrx/store';
import * as fromAsyncRequests from '@features/general/async_requests/async-requests.reducer';
import {AsyncRequestStatus} from '@features/general/async_requests/async-requests.entity';
import {selectRouterUrl} from 'app/core/selectors';

export const selectARRequestsState = createSelector(
    selectGeneralState,
    state => state.asyncRequests.requests
);
export const selectARRequestItemsState = createSelector(
    selectGeneralState,
    state => state.asyncRequests.items
);

export const selectAllARRequests = createSelector(
    selectARRequestsState,
    fromAsyncRequests.selectAllRequests
);

export const selectAllARRequestsEntities = createSelector(
    selectARRequestsState,
    fromAsyncRequests.selectAllRequestsEntities
);
export const selectAllARRequestsItems = createSelector(
    selectARRequestItemsState,
    fromAsyncRequests.selectAllRequestsItems
);

export const selectAllARRequestItemsEntities = createSelector(
    selectARRequestItemsState,
    fromAsyncRequests.selectAllRequestsItemsEntities
);


export const selectARRequestById = (request_id: string) => createSelector(
    selectAllARRequestsEntities,
    (entities) => entities[request_id] || null
);

export const selectARRequestItems = (request_id: string) => createSelector(
    selectAllARRequestsItems,
    (items) => items.filter((item) => item.request_id === request_id)
);

export const selectARRequestAllItemsComplete = (request_id: string) => createSelector(
    selectARRequestItems(request_id),
    (items) => (!items.find((item) => item.result == null))
);

export const selectARRequestStatusIsComplete = (request_id: string) => createSelector(
    selectARRequestById(request_id),
    (request) => request && request.status === AsyncRequestStatus.complete || false
);

export const selectARequestCurrentRequest = createSelector(
    selectRouterUrl,
    selectAllARRequests,
    (url, requests) => requests ? requests.find((request) => request.id === url) : null
);

export const selectARequestCurrentCompletedRequest = createSelector(
    selectARequestCurrentRequest,
    (request) => request && (request.status === AsyncRequestStatus.complete) ? request : null
);

export const selectARequestCurrentCompletedItems = createSelector(
    selectARequestCurrentCompletedRequest,
    selectAllARRequestsItems,
    (request, items) => request ? items.filter((item) => item.request_id === request.id) : []
);
