<div class="icon-panel">
    <div class="icon-panel__title"
         (click)="togglePanel()"
         [ngStyle]="{
            'background-color': data.node.color,
            'color': config.title.color
         }"
    >
        <div class="icon-panel__icon"><img [src]="data.node.icon"></div>
        <div class="icon-panel__label icon-panel__label--title">{{data.node.name}}</div>
    </div>
    <div class="icon-panel__body">
        <div class="icon-panel__wrapper" *ngIf="isOpen">
            <div class="icon-panel__elem" *ngFor="let elem of data.children">
                <div class="icon-panel__slider">
                    <app-mat-slider [data]="elem" (changeEvent)="changeSlider($event)"></app-mat-slider>
                </div>
                <div class="icon-panel__label icon-panel__label--elem">
                    {{elem.node.name}}
                </div>
            </div>
        </div>
    </div>
</div>
