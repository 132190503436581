import { Component } from '@angular/core';
import {BaseComponent} from "@shared/base-classes/base-component";
import {IComponent, IWeightedNode} from "@shared/interfaces";

export enum IConPanelActionTypes {
    togglePanel = '[ICON PANEL] Toggle Panel',
    ChangeSlide = '[ICON PANEL] Change Slider'
}

@Component({
  selector: 'app-icon-panels',
  templateUrl: './icon-panels.component.html',
  styleUrls: ['./icon-panels.component.scss']
})
export class IconPanelsComponent extends BaseComponent<IWeightedNode<IComponent>[], any, any> {

    isOpen = 0;

    configUpdate() {
    }

    dataUpdate() {
        this.isOpen = 0;
    }

    initConfig() {
    }

    changeSlider(event, i: number) {
        switch (event.type) {
            case IConPanelActionTypes.ChangeSlide:
                this.changed(event.payload);
                return;
            case IConPanelActionTypes.togglePanel:
                this.isOpen = event.payload ? i : null;
                return;
            default:
        }

    }




}
