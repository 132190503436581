
export enum Charts2ScalesTypes {
    linear = 'linear',
    power = 'power',
    log = 'logarithmic',
    identity = 'identity',
    time = 'time',
    sequential = 'sequential',
    diverging = 'diverging',
    quantize = 'quantize',
    quantile = 'quantile',
    threshold = 'threshold',
    ordinal = 'ordinal',
    band = 'band',
    point = 'point',
    sqrt = 'sqrt'
}

export enum Charts2ScalesDirections {
    normal = 'normal',
    inverted = 'inverted'
}

export enum Chart2ScalesRangeTypes {
    horizontal = 'horizontal',
    midHorizontal = 'middle horizontal',
    vertical = 'vertical',
    midVertical = 'middle vertical',
    min = 'min',
    midMin = 'middle min',
    max = 'max',
    midMax = 'middle max'
}
