import { Injectable } from '@angular/core';
import {SharedService} from "@shared/shared.service";
import {IColor} from "@shared/interfaces";
import {Observable} from "rxjs";

@Injectable()
export class ColorsService extends SharedService{

    getColorsByUrl(url: string): Observable<IColor[]> {
        return this.getByUrl<IColor[]>(url);
    }

    getColorsByEditionUrl(indice: string, edition: number): string {
        return this.getApiUrl() + `/indices/${indice}/${edition}/colors`;
    }

    getColorsByEdition(indice: string, edition: number): Observable<IColor[]> {
        const url = this.getColorsByEditionUrl(indice, edition);
        return this.getColorsByUrl(url);
    }
}
